import React, {useReducer, useContext} from 'react';
import {useAuth} from '../../services/useAuth';
import AppReducer from './appReducer';
import AppContext, {AppContextType} from './appContext';
import types from '../types';
import {Toast} from '../../utils/Toast';
import {IGetUserTenantsResponse, IUser, IUserTenant} from '../../model/user/User';
import useStorage from '../../hooks/useStorage';
import {
  MarketplaceConnectionStatus,
  ModuleTypes,
  PaymentProviderName,
  StorageKeys,
} from '../../model/constants/Constants';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {ITenantModuleDetails, ParamentersToSelectMenu} from '../../model/tenant/TenantModuleDetails';
import MenuService from '../../services/MenuService';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';
import useAccountAPI from '../../services/useAccountAPI';
import useConnectedSupplierAPI from '../../services/useConnectedSuppliersAPI';
import useTenantAPI from '../../services/useTenantAPI';
import {ITenant, ITenantInfo} from '../../model/Tenant';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import {IPaymentMethodTypes, ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import {useFeatureFlagsAPI} from '../../services/useFeatureFlagsAPI';
import {IFeatureFlagsState, ILDKeyValue} from '../../model/feature-flags/FeatureFlags';
import {useMenu} from '../../hooks/useMenu';
import {IMenuModule} from '../../model/menu/Menu';
import useSpendaPaymentServicesAPI from '../../services/useSpendaPaymentServicesAPI';
import useQuotesAPI from '../../services/useQuotesAPI';
import {ZohoContext} from '../../context/zoho-context/ZohoContext';
import {IServiceTenantConfig} from '../../model/service-management/serviceJob';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {defaultMarketPlacePath, isWebsiteId17} from '../../config';
import {useHistory} from 'react-router-dom';

const AppContextProvider = (props: any) => {
  const initialState = {
    user: undefined,
    suppliers: undefined,
    loading: false,
    selectedModule: undefined,
    isShow2SASetup: false,
    menu: undefined,
    marketplaceSupplier: undefined,
    selectedAdaptor: undefined,
    paymentMethods: [],
    featureFlags: [],
    statementInvitationId: undefined,
    invoiceInvitationId: undefined,
    modules: [],
    userTenants: [],
    userRole: undefined,
    serviceTenantConfigs: {} as IServiceTenantConfig,
  };

  // State
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // API
  const marketPlaceAPI = useMarketPlaceAPI();
  const {changePassword: changePasswordAPI, listUserTenants, switchUserTenant} = useAccountAPI();
  const {getCompanyDetails, getTenantInfo} = useTenantAPI();
  const {getConnectedSupplier, connectedSuppliersSearch} = useConnectedSupplierAPI();
  const {getQuotesSettings} = useQuotesAPI();
  const {getServiceTenantConfigs} = useServiceJobAPI();

  // Hooks
  const {getFeatureFlagsByLinkedSupplier} = useFeatureFlagsAPI();
  const {setToken, logout: authLogout} = useAuth();
  const {formatModulesData} = useMenu();
  const storage = useStorage();
  const zohoContext = useContext(ZohoContext)!;
  const history = useHistory();

  const {getAvailablePaymentMethodAPI} = useSpendaPaymentServicesAPI();

  const loadInitialData = async () => {
    const [userTenants, tenant, tenantInfo, suppliers] = await Promise.allSettled([
      listUserTenants(),
      getCompanyDetails(),
      getTenantInfo(),
      connectedSuppliersSearch({}),
    ]).then(results => results.map(res => (res.status === 'fulfilled' ? res.value : undefined)));

    return {
      userTenants: userTenants as IGetUserTenantsResponse,
      tenant,
      tenantInfo: (tenantInfo || {}) as ITenantInfo,
      suppliers: (suppliers || []) as IConnectedSupplier[],
    };
  };

  const handleUnauthorizedUser = async (userTenants?: IGetUserTenantsResponse, tenantInfo?: ITenantInfo) => {
    if (!userTenants?.Value?.length) {
      history.push('/user-unauthorized');
      return Promise.resolve(false);
    }

    if (userTenants?.Value?.length && !tenantInfo?.TenantUserDetails.UserID) {
      await switchUserTenant(userTenants?.Value?.[0]?.UserGlobalID);
      sessionStorage.setItem('isSwitchTenant', 'true');
      history.go(0);
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  };

  const populateUserData = (user: IUser, tenantInfo: ITenantInfo) => {
    if (!tenantInfo?.TenantUserDetails) return;

    Object.assign(user, {
      FirstName: tenantInfo.TenantUserDetails.UserFirstName,
      LastName: tenantInfo.TenantUserDetails.UserLastName,
      TenantID: tenantInfo.TenantUserDetails.TenantID!,
      TenantName: tenantInfo.TenantUserDetails.TenantName,
      Modules: tenantInfo.TenantUserDetails.WebAppDisplayableModules?.toString() || '',
      UserName: tenantInfo.TenantUserDetails.UserName,
      UserID: tenantInfo.TenantUserDetails.UserID,
      email: tenantInfo.TenantUserDetails.EmailAddress,
    });

    user.Modules = getUserModulesFromTenant(tenantInfo, user.Modules);
  };

  const loadFeatureFlags = async (suppliers: IConnectedSupplier[], marketplaceSupplier?: any) => {
    const defaultSupplier = suppliers.find(
      supplier =>
        !supplier.IsLendingProvider &&
        !supplier.IsPaymentProvider &&
        supplier.Status === MarketplaceConnectionStatus.Connected,
    );

    const capricornSupplier = suppliers.find(
      s => s?.MarketplacePath === defaultMarketPlacePath && s.Status === MarketplaceConnectionStatus.Connected,
    );

    const sId =
      marketplaceSupplier?.SupplierID || isWebsiteId17 ? capricornSupplier?.SupplierID : defaultSupplier?.SupplierID;

    if (!sId) return {};

    const response = await getFeatureFlagsByLinkedSupplier(sId);
    return {linkedSupplierFeatureFlags: response?.flags || []};
  };

  const loadAdditionalData = async (user: IUser, suppliers: IConnectedSupplier[]) => {
    let marketplaceSupplier = state.marketplaceSupplier;

    if (!marketplaceSupplier && user.DefaultSupplierMarketplace) {
      marketplaceSupplier = await loadMarketplace(user.DefaultSupplierMarketplace);
    }

    if (marketplaceSupplier?.SupplierID > 0) {
      const connectedSupplier = await getConnectedSupplier(marketplaceSupplier.SupplierID);
      marketplaceSupplier = {...marketplaceSupplier, ...connectedSupplier};
    }

    let availablePaymentMethodTypes: IPaymentMethodTypes[] = [];
    if (marketplaceSupplier?.GlobalID) {
      availablePaymentMethodTypes = await getAvailablePaymentMethodAPI(PaymentProviderName.Spenda_Payment_Services, {
        MerchantGlobalID: marketplaceSupplier.GlobalID,
      });
    }

    const featureFlags = await loadFeatureFlags(suppliers, marketplaceSupplier);

    const isQuotesSettingsSaved = user.Modules.includes(ModuleTypes.Quotes.toString())
      ? !!(await getQuotesSettings())
      : false;

    const serviceConfigs = user.Modules.includes(ModuleTypes.Service.toString()) ? await getServiceTenantConfigs() : {};

    return {marketplaceSupplier, availablePaymentMethodTypes, featureFlags, isQuotesSettingsSaved, serviceConfigs};
  };

  const loadUserSession = async (user: IUser): Promise<boolean> => {
    if (!user) return Promise.resolve(false);

    try {
      const {userTenants, tenant, tenantInfo, suppliers} = await loadInitialData();

      const isUserAuthorized = await handleUnauthorizedUser(userTenants, tenantInfo);
      if (!isUserAuthorized) return Promise.resolve(false);

      populateUserData(user, tenantInfo);

      const {marketplaceSupplier, availablePaymentMethodTypes, featureFlags, isQuotesSettingsSaved, serviceConfigs} =
        await loadAdditionalData(user, suppliers);

      dispatch({
        type: types.LOAD_SESSION,
        payload: {
          suppliers,
          marketplaceSupplier,
          user,
          tenant,
          tenantInfo,
          featureFlags,
          modules: [],
          availablePaymentMethodTypes,
          isQuotesSettingsSaved,
          serviceTenantConfigs: serviceConfigs,
          userTenants: userTenants?.Value,
        },
      });
    } catch (ex) {
      Toast.error((ex as any)?.message);
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  };

  const getUserModulesFromTenant = (tenantInfo: ITenantInfo, currentModules: string) => {
    let userModules = currentModules;

    const SCARmodule =
      Array.isArray(tenantInfo.Modules) &&
      tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.SpendaCollectAR);

    // 6. Get menu options to display based on the tenant modules
    if (SCARmodule && SCARmodule.IsModuleSetup && !userModules.includes(ModuleTypes.SpendaCollectAR.toString())) {
      userModules = userModules + ',' + ModuleTypes.SpendaCollectAR;
    }

    const SPAYmodule =
      Array.isArray(tenantInfo.Modules) && tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.SpendaPay);

    const SupplierManagementModule =
      Array.isArray(tenantInfo.Modules) && tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.Suppliers);

    if (
      SPAYmodule &&
      SPAYmodule.IsModuleSetup &&
      SPAYmodule.IsActive &&
      !userModules.includes(ModuleTypes.SpendaPay.toString())
    ) {
      userModules = userModules + ',' + ModuleTypes.SpendaPay;
      if (
        SupplierManagementModule &&
        SupplierManagementModule.IsModuleSetup &&
        SupplierManagementModule.IsActive &&
        !userModules.includes(ModuleTypes.Suppliers.toString())
      ) {
        userModules = userModules + ',' + ModuleTypes.Suppliers;
      }
    }

    const SalesOrdermodule =
      Array.isArray(tenantInfo.Modules) &&
      tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.SalesOrderManagement);

    if (
      SalesOrdermodule &&
      SalesOrdermodule.IsModuleSetup &&
      SalesOrdermodule.IsActive &&
      !userModules.includes(ModuleTypes.SalesOrderManagement.toString())
    ) {
      userModules = userModules + ',' + ModuleTypes.SalesOrderManagement;
    }

    const ProductManagementModule =
      Array.isArray(tenantInfo.Modules) &&
      tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.ProductManagement);

    if (
      ProductManagementModule &&
      ProductManagementModule.IsModuleSetup &&
      ProductManagementModule.IsActive &&
      !userModules.includes(ModuleTypes.ProductManagement.toString())
    ) {
      userModules = userModules + ',' + ModuleTypes.ProductManagement;
    }

    const CustomerManagementModule =
      Array.isArray(tenantInfo.Modules) &&
      tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.CustomerManagement);

    if (
      CustomerManagementModule &&
      CustomerManagementModule.IsModuleSetup &&
      CustomerManagementModule.IsActive &&
      !userModules.includes(ModuleTypes.CustomerManagement.toString())
    ) {
      userModules = userModules + ',' + ModuleTypes.CustomerManagement;
    }

    const PurchaseInvoiceModule =
      Array.isArray(tenantInfo.Modules) && tenantInfo.Modules.find(module => module.ModuleID === ModuleTypes.SpendaBuy);
    if (
      PurchaseInvoiceModule &&
      PurchaseInvoiceModule.IsModuleSetup &&
      PurchaseInvoiceModule.IsActive &&
      !userModules.split(',').find(m => m === ModuleTypes.SpendaBuy.toString())
    ) {
      userModules = userModules + ',' + ModuleTypes.SpendaBuy + ',' + ModuleTypes.SpendaPOS;
    }

    return userModules;
  };

  const switchSupplier = async (modules: number[], path: string) => {
    const SCARmodule =
      Array.isArray(state.tenantInfo?.Modules) &&
      state.tenantInfo?.Modules.find((module: any) => module.ModuleID === ModuleTypes.SpendaCollectAR);

    if (SCARmodule && SCARmodule.IsModuleSetup && !modules?.includes(ModuleTypes.SpendaCollectAR)) {
      modules && modules.push(ModuleTypes.SpendaCollectAR);
    }

    const SPAYmodule =
      Array.isArray(state?.tenantInfo?.Modules) &&
      state.tenantInfo.Modules.find((module: any) => module.ModuleID === ModuleTypes.SpendaPay);

    const SupplierManagementModule =
      Array.isArray(state?.tenantInfo.Modules) &&
      state?.tenantInfo.Modules.find((module: any) => module.ModuleID === ModuleTypes.Suppliers);

    if (SPAYmodule && SPAYmodule.IsModuleSetup && SPAYmodule.IsActive && !modules?.includes(ModuleTypes.SpendaPay)) {
      modules && modules.push(ModuleTypes.SpendaPay);
      if (
        SupplierManagementModule &&
        SupplierManagementModule.IsModuleSetup &&
        SupplierManagementModule.IsActive &&
        !modules?.includes(ModuleTypes.Suppliers)
      ) {
        modules && modules.push(ModuleTypes.Suppliers);
      }
    }
    const user = {...state.user};
    user.Modules = modules?.join(',');
    // Set Marketplace supplier and ARR
    let marketplaceSupplier = await loadMarketplace(path);

    let availablePaymentMethodTypes;

    // Extend marketplace object with props from connected Supplier object
    if (marketplaceSupplier?.SupplierID && marketplaceSupplier?.SupplierID > 0) {
      const connectedSupplier = await getConnectedSupplier(marketplaceSupplier.SupplierID.toString());
      availablePaymentMethodTypes = await getAvailablePaymentMethodAPI(PaymentProviderName.Spenda_Payment_Services, {
        MerchantGlobalID: marketplaceSupplier.GlobalID,
      });
      marketplaceSupplier = {...marketplaceSupplier, ...connectedSupplier};
    }

    // 7. Get feature flags
    const featureFlags: {linkedSupplierFeatureFlags?: ILDKeyValue[]} = {};

    if (marketplaceSupplier?.SupplierID) {
      const sf = await getFeatureFlagsByLinkedSupplier(marketplaceSupplier?.SupplierID);
      featureFlags.linkedSupplierFeatureFlags = sf?.flags || [];
    }

    dispatch({
      type: types.LOAD_SESSION,
      payload: {
        suppliers: state.suppliers,
        marketplaceSupplier,
        user: user,
        tenant: state.tenant,
        tenantInfo: state.tenantInfo,
        featureFlags: featureFlags,
        modules: state.modules,
        availablePaymentMethodTypes,
      },
    });
  };

  const setSupplierToLink = async (marketplacePath?: string) => {
    let supplierToLink: ISupplierMarket | undefined = undefined;
    if (marketplacePath) {
      supplierToLink = await loadMarketplace(marketplacePath, true);
    }

    dispatch({type: types.SET_SUPPLIER_TO_LINK, payload: supplierToLink});
  };

  const setMenu = (filters: ParamentersToSelectMenu) => {
    const menu = MenuService.GetUserMenu(state.user.Modules, filters);
    dispatch({type: types.SET_MENU, payload: menu});
  };

  const loadMarketplace = async (marketplacePath?: string, hideMessages: boolean = false) => {
    let mpSupplier: ISupplierMarket | undefined = undefined;

    if (marketplacePath) {
      mpSupplier = await marketPlaceAPI.validatePath(marketplacePath, hideMessages);
    }

    if (marketplacePath && !mpSupplier) {
      mpSupplier = {
        MarketPlacePath: marketplacePath!,
        Status: MarketplaceConnectionStatus.NonExistent,
      };
    }

    return mpSupplier;
  };

  const setUser = (user: IUser) => {
    setToken(user).then(() => {
      dispatch({type: types.SET_USER, payload: user});
    });
  };

  const setTenant = (tenant: ITenant) => {
    dispatch({type: types.SET_TENANT, payload: tenant});
  };

  const setUserTenant = (payload: {tenant: ITenant; user: IUser}) => {
    dispatch({type: types.SET_USER_TENANT, payload});
  };

  const setUserTenantList = (userTenants: IUserTenant[]) => {
    dispatch({type: types.SET_USER_TENANT_LIST, payload: userTenants});
  };

  const setTenantInfo = (tenantInfo: ITenantInfo) => {
    dispatch({type: types.SET_TENANT_INFO, payload: tenantInfo});
  };

  const changePassword = async (newPassword: string, websiteId: number, token: string) => {
    setLoading();

    let response = await changePasswordAPI(newPassword, websiteId, token);
    setLoading(false);
    return response;
  };

  const logout = async () => {
    await authLogout();

    await Promise.all([
      storage.removeItem(StorageKeys.AutoLogin),
      storage.removeItem(StorageKeys.User),
      storage.removeItem(StorageKeys.MarketplaceSupplier),
      storage.removeItem(StorageKeys.SelectedAccount),
    ]);

    if (zohoContext) {
      zohoContext.resetChatWindow();
    }

    dispatch({type: types.LOGOUT});
  };

  const setLoading = (flag: boolean = true) => {
    dispatch({type: types.SET_LOADING, payload: flag});
  };

  const setMarketplaceSupplier = (supplier?: ISupplierMarket) => {
    dispatch({
      type: types.SET_MARKETPLACE_SUPPLIER,
      payload: supplier,
    });
  };

  const setSelectedModule = (mod: Partial<ITenantModuleDetails>) => {
    dispatch({
      type: types.SET_SELECTED_MODULE,
      payload: mod,
    });
  };

  const setPaymentMethods = (paymentMethods: ITenantSupplierPaymentMethod[]) => {
    dispatch({
      type: types.SET_PAYMENT_METHODS,
      payload: paymentMethods,
    });
  };

  const setStatementInvitation = (invitationId?: string) => {
    dispatch({
      type: types.SET_STATEMENT_INVITATION,
      payload: invitationId,
    });
  };

  const setInvoiceInvitation = (invoiceInvitationId?: string) => {
    dispatch({
      type: types.SET_INVOICE_PAYMENT_INVITATION,
      payload: invoiceInvitationId,
    });
  };

  const setConnectedSuppliers = (suppliers: IConnectedSupplier[]) => {
    dispatch({
      type: types.SET_CONNECTED_SUPPLIERS,
      payload: suppliers,
    });
  };

  const setSelectedAdaptor = (adaptor: IMasterAdaptor) => {
    if (adaptor) {
      storage.setItem(StorageKeys.SelectedAccount, adaptor);
    } else {
      storage.removeItem(StorageKeys.SelectedAccount);
    }
    dispatch({
      type: types.SET_SELECTED_ADAPTOR,
      payload: adaptor,
    });
  };

  const setWorkFlowId = (workFlowId: number | undefined) => {
    dispatch({
      type: types.SET_WORKFLOW_ID,
      payload: workFlowId,
    });
  };

  const setFeatureFlags = (flags: Partial<IFeatureFlagsState>) => {
    dispatch({
      type: types.SET_FEATURE_FLAGS,
      payload: flags,
    });
  };

  const setModules = (modules: IMenuModule[]) => {
    const payload = formatModulesData(modules);
    dispatch({
      type: types.SET_MODULES,
      payload: payload,
    });
  };

  const setIsPSBLPIBLEnable = (condition: boolean) => {
    dispatch({
      type: types.SET_IS_PSBL_PIBL_ENABLE,
      payload: condition,
    });
  };

  const setIsMobileDisclaimerShown = (condition: boolean) => {
    dispatch({
      type: types.SET_IS_MOBILE_DISCLAIMER_SHOWN,
      payload: condition,
    });
  };

  const setIsQuotesSettingsSaved = (condition: boolean) => {
    dispatch({
      type: types.SET_IS_QUOTES_SETTINGS_SAVED,
      payload: condition,
    });
  };

  const setIsShowConfigureSettingsDialog = (condition: boolean) => {
    dispatch({
      type: types.SET_IS_SHOW_CONFIGURE_SETTINGS_DIALOG,
      payload: condition,
    });
  };

  const setAvailablePaymentMethodTypes = (availablePaymentMethodTypes: IPaymentMethodTypes[]) => {
    dispatch({
      type: types.SET_AVAILABLE_PAYMENT_METHOD_TYPES,
      payload: availablePaymentMethodTypes,
    });
  };

  const setServiceTenantConfigs = (serviceTenantConfigs: IServiceTenantConfig) => {
    dispatch({
      type: types.SET_SERVICE_TENANT_CONFIGS,
      payload: serviceTenantConfigs,
    });
  };

  const appContext: AppContextType = {
    user: state.user,
    tenant: state.tenant,
    suppliers: state.suppliers,
    loading: state.loading,
    selectedModule: state.selectedModule,
    selectedAdaptor: state.selectedAdaptor,
    isShow2SASettings: state.isShow2SASettings,
    menu: state.menu,
    marketplaceSupplier: state.marketplaceSupplier,
    supplierToLink: state.supplierToLink,
    paymentMethods: state.paymentMethods,
    tenantInfo: state.tenantInfo,
    workFlowId: state.workFlowId,
    statementInvitationId: state.statementInvitationId,
    serviceTenantConfigs: state.serviceTenantConfigs,
    invoiceInvitationId: state.invoiceInvitationId,
    featureFlags: state.featureFlags,
    modules: state.modules,
    isPSBLPIBLEnable: state.isPSBLPIBLEnable,
    isShowConfigureSettingsDialog: state.isShowConfigureSettingsDialog,
    availablePaymentMethodTypes: state.availablePaymentMethodTypes,
    userTenants: state.userTenants,
    isMobileDisclaimerShown: state.isMobileDisclaimerShown,
    isQuotesSettingsSaved: state.isQuotesSettingsSaved,
    userRole: state.userRole,
    logout,
    changePassword,
    loadUserSession,
    setFeatureFlags,
    setUser,
    setMenu,
    setTenant,
    setUserTenant,
    setUserTenantList,
    setMarketplaceSupplier,
    setSelectedModule,
    setStatementInvitation,
    setInvoiceInvitation,
    switchSupplier,
    setSupplierToLink,
    setPaymentMethods,
    setTenantInfo,
    setSelectedAdaptor,
    loadMarketplace,
    setWorkFlowId,
    setConnectedSuppliers,
    setModules,
    setIsPSBLPIBLEnable,
    setIsShowConfigureSettingsDialog,
    setAvailablePaymentMethodTypes,
    setIsMobileDisclaimerShown,
    setIsQuotesSettingsSaved,
    setServiceTenantConfigs,
  };

  return <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;
