import {IActionRes} from '../model/ActionResults';
import {ISupplierMarket} from '../model/SupplierMarket';
import {IActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';

export const routesWhitelist = [
  //UnauthenticatedApp routes
  'register',
  'public',
  'home',
  'statement',
  'invoices',
  'paybylink',
  'invite',
  'quotes',
  //AuthenticatedApp routes
  'purchasing',
  'sales-orders',
  'transactions',
  'selling',
  'supplier',
  'settings',
  'onboarding',
  'tenant',
  'pay',
  'payment-authorisation',
  'finance',
  'manage-quotes',
  'accounts-payable',
  'manage-supplier',
  'service',
  'merchant-portal',
  'trade-account',
  'inventory',
  // Common for both
  '401',
  '404',
  'menu',
  'module-info',
];

const useMarketPlaceAPI = () => {
  const {GET, PUT, POST, isLoading} = useHttp();

  const validatePath = (
    marketPlacePath: string,
    hideMessages: boolean = false,
  ): Promise<ISupplierMarket | undefined> => {
    if (routesWhitelist.indexOf(marketPlacePath) >= 0) {
      return new Promise(res => res(undefined));
    }

    return GET(`suppliermarket/${marketPlacePath}`, undefined, hideMessages).then(
      (data: IActionResults<ISupplierMarket>) => data.Value,
    );
  };

  const getSuppliers = (): Promise<ISupplierMarket[]> => {
    return GET('suppliermarket').then((data: IActionResults<ISupplierMarket[]>) => data.Value);
  };

  const joinSupplier = (marketPlacePath: string): Promise<IActionRes> => {
    return POST(`suppliermarket/join?marketPlacePath=${marketPlacePath}`, undefined).then(data => data);
  };

  const marketPlacePathUnique = (marketPlacePath: string | undefined): Promise<boolean> => {
    return PUT(`tenant/ismarketplacepathunique?marketPlacePath=${marketPlacePath}`, {}).then(data => {
      return data.IsSuccess;
    });
  };

  const isRouteWhiteListed = (route: string): boolean => {
    return routesWhitelist.indexOf(route) >= 0;
  };

  return {
    validatePath,
    getSuppliers,
    joinSupplier,
    marketPlacePathUnique,
    isRouteWhiteListed,
    isLoading,
  };
};

export default useMarketPlaceAPI;
