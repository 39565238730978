import React, {SetStateAction} from 'react';
import {Upload} from 'spenda-ui-react';

import {IQuoteAttachment} from '../../model/quotes/quotes';
import {AttachmentType} from '../../model/FileDetails';
import PreviewAttachment from './PreviewAttachment';
import {FileType} from '../../components/dialog/QuoteAttachmentDialog';
import AttachmentDefaultView from '../../components/quote-management/AttachmentDefaultView';
import {Toast} from '../../utils/Toast';

interface IQuoteAttachmentProps {
  addedFilesToUpload?: FileType[];
  setAddedFilesToUpload?: React.Dispatch<SetStateAction<FileType[]>>;
  deleteFile?: (index: number) => void;
  uploadedAttachments?: IQuoteAttachment[];
  deleteQuoteAttachment?: (guid: string) => void;
  isServiceJobView?: boolean;
  disabled?: boolean;
  isEditAble?: boolean;
}

const QuoteAttachment = ({
  addedFilesToUpload,
  setAddedFilesToUpload,
  deleteFile,
  uploadedAttachments,
  deleteQuoteAttachment,
  isServiceJobView,
  disabled,
  isEditAble,
}: IQuoteAttachmentProps) => {
  const allowedTypes = Object.values(AttachmentType);

  const [previewImage, setPreviewImage] = React.useState({} as {uri: string; show: boolean; type: string});

  const handleOnChange = (files: FileList) => {
    if (files && files.length > 0) {
      let invalidFilename: string = '';
      let duplicateFilename: string = '';

      Promise.all(
        Array.from(files).map(file => {
          const isValidType = allowedTypes.includes(file.type as AttachmentType);
          const isValidFileName =
            addedFilesToUpload?.findIndex(item => item.file.name === file.name) === -1 &&
            uploadedAttachments?.findIndex(item => item.caption === file.name) === -1;
          if (isValidType && isValidFileName) {
            return new Promise(resolve => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  file,
                  uri: reader.result as string,
                });
              };

              reader.readAsDataURL(file);
            });
          } else {
            if (!isValidType) {
              invalidFilename = invalidFilename.concat(file.name + ',');
            } else if (!isValidFileName) {
              duplicateFilename = duplicateFilename.concat(file.name + ',');
            }
            return Promise.resolve(undefined);
          }
        }),
      ).then(data => {
        data = data.filter(Boolean);
        setAddedFilesToUpload?.(prevState => [...prevState, ...(data as FileType[])]);
      });

      if (duplicateFilename) {
        Toast.error(`Duplicate file: ${duplicateFilename.slice(0, -1)}`);
      }
      if (invalidFilename) {
        Toast.error(`Invalid file type: ${invalidFilename.slice(0, -1)}`);
      }
    }
  };

  return (
    <>
      {isEditAble && (
        <Upload
          data-autoid="uploadAttachment"
          label="Drop any additional documents here or click to upload"
          className={`${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} h-full min-h-[114px] text-sm font-normal text-black-800`}
          onChange={handleOnChange}
          disabled={disabled}
          multiple
          accept={allowedTypes.join(',')}
        />
      )}

      <div className={`flex w-full ${isServiceJobView ? '' : 'mt-2'}`}>
        <div
          className={`flex w-full flex-wrap ${isServiceJobView ? 'justify-start gap-x-5 gap-y-5' : 'justify-center gap-x-3 gap-y-3'}  `}
        >
          {uploadedAttachments &&
            uploadedAttachments.map((item, index) => {
              return (
                <AttachmentDefaultView
                  key={index}
                  uri={item.uri}
                  name={item.caption}
                  caption={item.caption}
                  containerClassName={isServiceJobView ? 'w-[15%] flex-wrap justify-start' : ''}
                  handleVisibility={fileUrl => setPreviewImage({uri: fileUrl || item.uri, show: true, type: item.type})}
                  onDelete={() => deleteQuoteAttachment?.(item.attachmentGuid)}
                  isEditAble={isEditAble}
                  disabled={disabled}
                  type={item.type}
                />
              );
            })}

          {addedFilesToUpload &&
            addedFilesToUpload.map((data, index) => (
              <AttachmentDefaultView
                isEditAble
                key={`added-${index}`}
                caption={data.file.name}
                name={data.file.name}
                uri={data.uri}
                containerClassName={isServiceJobView ? 'w-[15%] flex-wrap justify-start' : ''}
                onDelete={() => deleteFile?.(index)}
              />
            ))}
          {previewImage.show && (
            <PreviewAttachment
              type={previewImage.type}
              imageUrl={previewImage.uri}
              setShow={value =>
                setPreviewImage({
                  uri: '',
                  show: value,
                  type: '',
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default QuoteAttachment;
