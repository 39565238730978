import React, {SetStateAction, useEffect, useState} from 'react';
import {Badge, Chip, IconButton, Tooltip, Typography} from 'spenda-ui-react';

import RequiresAttentionIcon from '../../assets/svg/service-management/RequiresAttentionIcon';
import ListViewIcon from '../../assets/svg/service-management/ListViewIcon';
import ExpandIcon from '../../assets/svg/service-management/ExpandIcon';
import {
  IRequiresAttentionServiceJob,
  IServiceJob,
  RequiresAttentionStatus,
  ServiceJobStatus,
} from '../../model/service-management/serviceJob';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import {RightPanelView} from '../../screens/service-management/ServiceDashboard';
import moment from 'moment';
import {useServiceDashboardContext} from '../../screens/service-management/context/ServiceDashboardContext';

interface IBookingDiaryList {
  activeLeftPanelView?: string;
  handleCollapse?: () => void;
  setOpenBookingDialog: React.Dispatch<SetStateAction<boolean>>;
  setServiceJobId: React.Dispatch<SetStateAction<number>>;
  activeRightPanelView?: RightPanelView;
  setActiveRightPanelView: React.Dispatch<SetStateAction<RightPanelView>>;
  selectedDate: Date;
}

function isServiceJobInstance(object: any): object is IServiceJob {
  return true;
}

function isRequiresAttentionInstance(object: any): object is IRequiresAttentionServiceJob {
  if (object.errors && Array.isArray(object.errors)) return true;
  else return false;
}
type ServiceJob = IServiceJob | IRequiresAttentionServiceJob;

export const ServiceStatus = ({status}: {status?: ServiceJobStatus}) => {
  switch (status) {
    case ServiceJobStatus.ScheduledTentative:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'warning'}
          data-autoid={`txtScheduledTentative`}
          value={RequiresAttentionStatus.ScheduledTentative}
        />
      );
    case ServiceJobStatus.AwaitingStock:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'warm-purple'}
          data-autoid={`txtAwaitingStock`}
          value={RequiresAttentionStatus.AwaitingStock}
        />
      );
    case ServiceJobStatus.Cancelled:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'error'}
          data-autoid={`txtCancelled`}
          value={RequiresAttentionStatus.Cancelled}
        />
      );
    case ServiceJobStatus.Scheduled:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'cool-purple'}
          data-autoid={`txtScheduled`}
          value={RequiresAttentionStatus.Scheduled}
        />
      );
    case ServiceJobStatus.Reschedule:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'warning'}
          data-autoid={`txtReschedule`}
          value={RequiresAttentionStatus.Reschedule}
        />
      );
    case ServiceJobStatus.Paused:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'gray'}
          data-autoid={`txtPaused`}
          value={RequiresAttentionStatus.Paused}
        />
      );
    case ServiceJobStatus.InProgress:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'light-green'}
          data-autoid={`txtInProgress`}
          value={RequiresAttentionStatus.InProgress}
        />
      );
    case ServiceJobStatus.Completed:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'success'}
          data-autoid={`txtCompleted`}
          value={RequiresAttentionStatus.Completed}
        />
      );
    case ServiceJobStatus.Closed:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'warning'}
          data-autoid={`txtClosed`}
          value={RequiresAttentionStatus.Closed}
        />
      );
    default:
      return (
        <Chip
          className="flex h-6 w-[120px] justify-center"
          color={'primary'}
          data-autoid={`txtOpen`}
          value={RequiresAttentionStatus.Open}
        />
      );
  }
};

export const BookingDiaryList = (props: IBookingDiaryList) => {
  // Props
  const {
    activeLeftPanelView,
    handleCollapse,
    setOpenBookingDialog,
    setServiceJobId,
    activeRightPanelView,
    setActiveRightPanelView,
    selectedDate,
  } = props;
  // State
  const [jobs, setJobs] = useState<IServiceJob[] | IRequiresAttentionServiceJob[]>();
  const [activeList, setActiveList] = useState<ServiceJob[]>();
  const [inactiveList, setInactiveList] = useState<ServiceJob[]>();

  const {serviceJobList, requiresAttentionList} = useServiceDashboardContext();

  const renderJobStatus = (job: IRequiresAttentionServiceJob | IServiceJob) => {
    const countBookings = (isRequiresAttentionInstance(job) ? job.countBookings : job.bookings?.length) || 0;
    if (job.isWaitingForStock) {
      return <ServiceStatus status={ServiceJobStatus.AwaitingStock} />;
    } else if (!job.isWaitingForStock && countBookings > 0 && !job.isSent) {
      return <ServiceStatus status={ServiceJobStatus.ScheduledTentative} />;
    } else {
      return <ServiceStatus status={job.status} />;
    }
  };

  useEffect(() => {
    if (activeRightPanelView === 'ListView') {
      setJobs(serviceJobList);
    } else {
      setJobs(requiresAttentionList?.items);
    }
  }, [activeRightPanelView, JSON.stringify(serviceJobList)]);

  useEffect(() => {
    if (jobs && jobs.length && selectedDate) {
      const selectedDateTime = new Date(selectedDate);

      // Partition the list into active and inactive based on the selectedDate
      const [activeList, inactiveList] = jobs.reduce<[ServiceJob[], ServiceJob[]]>(
        ([active, inactive], item) => {
          const containsSelectedDate = item?.bookings?.some(
            booking =>
              booking.startDateTime_utc &&
              moment(moment.utc(booking.startDateTime_utc).local()).isSame(selectedDateTime, 'day'),
          );
          if (containsSelectedDate) {
            active.push(item);
          } else {
            inactive.push({...item, isCurrentlyActive: false});
          }
          return [active, inactive];
        },
        [[], []],
      );
      setActiveList(activeList);
      setInactiveList(inactiveList);
    } else {
      setActiveList([]);
      setInactiveList([]);
    }
  }, [jobs, selectedDate]);

  const renderListItems = (job: ServiceJob, index: number) => {
    const showJobDesconHover = (job?.description?.length || 0) > 35;
    const assignedTo = isServiceJobInstance(job)
      ? job?.primaryTechnicianUserName ||
        (job?.primaryTechnicianFirstName && `${job?.primaryTechnicianFirstName} ${job?.primaryTechnicianLastName}`)
      : '';
    const timeEstimate =
      job?.estimatedLabourHours && job?.estimatedLabourHours > 0
        ? `${job?.estimatedLabourHours} ${job?.estimatedLabourHours === 1 ? 'hr' : 'hrs'}`
        : '';
    return (
      <div className={`flex flex-col border-b border-[#D8D8D8] px-3 pb-1 pt-3`} key={index}>
        <div className={`flex w-full flex-row justify-between`}>
          <div className={`flex flex-col`}>
            <Typography className="mb-1 text-primary" variant="h3">
              <span
                className="inline-block max-w-[280px] cursor-pointer truncate"
                onClick={() => {
                  if (activeRightPanelView === 'RequiresAttentionView' && isRequiresAttentionInstance(job)) {
                    //show warning error toast
                    if (job?.errors?.length) {
                      const errors = job?.errors?.map(err => `${err.description}`);
                      Toast.multiLineWarning(errors);
                    } else if (job?.isWaitingForStock) {
                      Toast.multiLineWarning(['Turn off awaiting stock, to remove from require attention']);
                    }
                  }
                  setServiceJobId(job?.serviceJobID!);
                  setOpenBookingDialog(true);
                }}
                data-autoid={`txtCustomerName-${job?.refNumber}`}
              >
                {job?.customerName}
              </span>
            </Typography>
            <Typography
              className={`mb-1 text-black-800`}
              variant="xsmall"
              data-autoid={`txtRefNumber-${job?.refNumber}`}
            >
              {job?.refNumber}
            </Typography>
            {showJobDesconHover ? (
              <Tooltip
                placement="top-start"
                offset="0"
                className="rounded-[4px] bg-white px-3 py-2 text-black-800 shadow-md"
                content={<div className="w-full max-w-[255px]">Job Desc - ${job.description}</div>}
              >
                <Typography className="mb-1 cursor-pointer text-black-800" variant="small">
                  {`Job Desc - ${job.description?.substring(0, 35)}...`}
                </Typography>
              </Tooltip>
            ) : (
              job?.description && (
                <Typography className="mb-1 text-black-800" variant="small" data-autoid="txtJobDesc">
                  Job Desc : {job?.description}
                </Typography>
              )
            )}
            {assignedTo && (
              <Typography className="mb-1 text-black-800" variant="small" data-autoid="txtAssignedTo">
                Assigned to : {assignedTo}
              </Typography>
            )}
            {timeEstimate && (
              <Typography className="mb-1 text-black-800" variant="small" data-autoid="txtTimeEstimate">
                Time estimate : {timeEstimate}
              </Typography>
            )}
          </div>
          <div className="flex flex-col items-center justify-between">
            <Typography
              className="font-semibold text-[#444343]"
              variant="h3"
              data-autoid={`txtTotalInc-${job.serviceJobID}`}
            >
              {PriceFormat(job?.totalInc)}
            </Typography>
            {renderJobStatus(job)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col font-poppins">
      <div className="flex w-full flex-row items-center justify-between  py-2">
        <Typography
          className="whitespace-nowrap pl-2 font-light text-black-800"
          variant="h2"
          data-autoid={`txt-${activeRightPanelView === 'ListView' ? 'listView' : 'requiresAttention'}`}
        >
          {activeRightPanelView === 'ListView' ? 'List view' : 'Requires attention'}
        </Typography>
        <div className="flex flex-row gap-2 pr-2">
          <IconButton
            name="calendar"
            onClick={() => setActiveRightPanelView('ListView')}
            variant={activeRightPanelView === 'ListView' ? 'filled' : 'outlined'}
            data-autoid={'btnListView'}
          >
            <ListViewIcon />
          </IconButton>
          <Badge
            className="error h-[22px] min-h-[20px] w-[22px] min-w-[20px]"
            color="error"
            invisible={requiresAttentionList?.items?.length === 0}
            content={requiresAttentionList?.totalRecordCount}
          >
            <IconButton
              name="calendar"
              onClick={() => setActiveRightPanelView('RequiresAttentionView')}
              variant={activeRightPanelView === 'RequiresAttentionView' ? 'filled' : 'outlined'}
              data-autoid={'btnRequiresAttentionView'}
            >
              <RequiresAttentionIcon />
            </IconButton>
          </Badge>
          {activeLeftPanelView === 'scheduler' && (
            <IconButton data-autoid="btnExpand" variant={'outlined'} onClick={handleCollapse}>
              <ExpandIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className="h-[calc(100vh-259px)] overflow-y-auto">
        {activeList && activeList.length ? (
          <>
            <div className="flex h-[26px] w-full items-center justify-center border-b border-[#D8D8D8] bg-[#F6F6F6]">
              <Typography variant="paragraph" className="font-bold text-spenda-labeltext">
                {moment(selectedDate).format('dddd, Do MMMM')}
              </Typography>
            </div>
            {activeList?.map((job, index) => {
              return renderListItems(job, index);
            })}
          </>
        ) : null}

        {inactiveList && inactiveList.length ? (
          <>
            {activeList && activeList.length ? (
              <div className="flex h-[26px] w-full items-center justify-center border-b border-[#D8D8D8] bg-[#F6F6F6]">
                <Typography variant="paragraph" className="font-bold text-spenda-labeltext">
                  Other jobs
                </Typography>
              </div>
            ) : null}
            {inactiveList?.map((job, index) => {
              return renderListItems(job, index);
            })}
          </>
        ) : null}
        {inactiveList?.length === 0 && activeList?.length === 0 && (
          <div className="flex h-full items-center justify-center">No jobs found</div>
        )}
      </div>
    </div>
  );
};
