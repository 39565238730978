import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {CloseCross} from '../../assets/svg/CloseCross';
import IconSearch from '../../assets/svg/IconSearch';
import {Spinner, Typography} from 'spenda-ui-react';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {SelectVariantDialog} from '../inventory/SelectVariantDialog';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {Inventory} from '../../model/inventory/Inventory';
import {useFormikContext} from 'formik';
import {IQuoteLines, IQuotes} from '../../model/quotes/quotes';
import {IServiceJobLines} from '../../model/service-management/serviceJob';
import {DISCOUNT_MODE} from '../../model/constants/Constants';

interface QuoteSmartSearchProps<T> {
  results?: T[];
  renderItem(item: T): JSX.Element;
  placeholder?: string;
  onChange?: ChangeEventHandler;
  onSelect?: (item: T) => boolean;
  value?: string;
  isLoading?: boolean;
  getInventoryList?: (query?: string) => void;
  byCode?: boolean;
  index: number;
  disabled?: boolean;
  calculateLineTotal: (
    Lines: Partial<IQuoteLines | IServiceJobLines>[],
    _values: T,
    shipping?: number | string,
    rowIndex?: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
  ) => void;
  setShowAddMissingProductInfo?: (state: {show: boolean; inventory: Inventory}) => void;
  isSelectVariantDialog?: boolean;
  setIsSelectVariantDialog?: (state: boolean) => void;
  setShowQuantityUpdateDialog?: (state: {show: boolean; inventory: Inventory}) => void;
}

const QuoteSmartSearch: FC<QuoteSmartSearchProps<any>> = (props): JSX.Element => {
  const {
    results = [],
    renderItem,
    onChange,
    onSelect,
    value,
    isLoading,
    getInventoryList,
    byCode,
    disabled,
    isSelectVariantDialog,
    setIsSelectVariantDialog,
    setShowQuantityUpdateDialog,
  } = props;
  const {getInventoryById, isLoading: inventoryLoading} = useInventoryAPI();

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showResults, setShowResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Inventory | undefined>(undefined);
  const {values, setFieldValue} = useFormikContext<IQuotes>();

  const handleAdd = async (selectedVariant: {
    ID: number;
    ShortDescription: string;
    StandardSellPriceEx: number;
    SKU: string;
    CostPriceEx: number;
  }) => {
    if (selectedIndex !== null && selectedProduct) {
      const selectedItem = results[selectedIndex];
      const isVariantAlreadyAdded = values.lines.some(line => line.inventoryID === selectedVariant.ID);
      if (isVariantAlreadyAdded) {
        setIsSelectVariantDialog?.(false);
        setShowQuantityUpdateDialog?.({show: true, inventory: selectedVariant});
        return;
      }
      const updatedLines = values.lines.map((line, index) => {
        if (index === props.index) {
          return {
            ...line,
            inventoryID: selectedVariant.ID,
            code: selectedVariant.SKU,
            shortDescription: selectedVariant.ShortDescription,
            quantity: 1,
            uoM: selectedItem.UoM,
            description: selectedVariant.ShortDescription,
            sellPriceEx: +selectedVariant.StandardSellPriceEx?.toFixed(2)!,
            lineTotalEx: +selectedVariant.StandardSellPriceEx?.toFixed(2)!,
            lineTotalExString: selectedVariant.StandardSellPriceEx?.toFixed(2),
            costPriceEx: +selectedVariant.CostPriceEx?.toFixed(2)!,
          };
        }
        return line;
      });
      setFieldValue('lines', updatedLines);
      props.calculateLineTotal(
        updatedLines,
        values,
        values.shipping,
        props.index,
        values.discountString,
        values.discountMode,
      );
    }
    setIsSelectVariantDialog?.(false);
  };

  const handleSelection = (selectedIndex: number) => {
    const selectedItem = results[selectedIndex];
    if (!selectedItem) return resetSearchComplete();
    if (selectedItem.IsVariantMaster && QuoteV289367) {
      handleItemClick(selectedIndex);
    } else {
      const res = onSelect && onSelect(selectedItem);
      if (!res && (value === selectedItem.InventoryCode || value === selectedItem.ShortDescription)) {
        setDefaultValue(byCode ? selectedItem.InventoryCode : selectedItem.ShortDescription);
      }
      resetSearchComplete();
    }
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    const {key} = e;
    let nextIndexCount = 0;

    // move down
    if (key === 'ArrowDown') nextIndexCount = (focusedIndex + 1) % results.length;

    // move up
    if (key === 'ArrowUp') nextIndexCount = (focusedIndex + results.length - 1) % results.length;

    // hide search results
    if (key === 'Escape') {
      resetSearchComplete();
    }

    // select the current item
    if (key === 'Enter') {
      e.preventDefault();
      handleSelection(focusedIndex);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleChange: ChangeEventHandler = e => {
    setDefaultValue(e.target.value);
    if (e.target.value.length > 2) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    onChange?.(e);
  };

  useEffect(() => {
    if (!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  useEffect(() => {
    setDefaultValue(value || '');
  }, [value]);

  const handleItemClick = async (index: number) => {
    const selectedItem = results[index];
    if (selectedItem) {
      setSelectedIndex(index);
      try {
        const productDetails = await getInventoryById(selectedItem.ID);
        setSelectedProduct(productDetails);
        if (
          QuoteV289367 &&
          selectedItem.ClassPostingBehaviour !== 'Generic' &&
          (!selectedItem?.InventoryClassID || !selectedItem?.RevenueAccount || !selectedItem?.ExpenseAccount)
        ) {
          props.setShowAddMissingProductInfo?.({
            show: true,
            inventory: productDetails,
          });
        } else {
          setIsSelectVariantDialog?.(true);
        }
      } catch (error) {
        console.error('Error fetching inventory details:', error);
      }
    }
  };

  const handleResultClick = (index: number) => {
    const selectedItem = results[index];
    if (QuoteV289367 && selectedItem.IsVariantMaster) {
      handleItemClick(index);
    } else {
      handleSelection(index);
    }
  };

  return (
    <div tabIndex={1} onBlur={resetSearchComplete} onKeyDown={handleKeyDown} className="relative">
      <div
        className={`flex items-center gap-x-2 border p-3 text-lg focus-within:border-primary 
    ${showResults ? 'border-primary' : QuoteV289367 ? 'border-[#707070]' : 'border-transparent'} 
    ${defaultValue === '' ? 'focus-within:border-primary' : 'outline-none transition'} 
    ${defaultValue && !QuoteV289367 ? 'focus-within:border-primary' : ' border-[#707070]'}`}
      >
        <input
          value={defaultValue}
          onChange={handleChange}
          ref={inputRef}
          autoFocus={defaultValue === '' && byCode ? true : false}
          type="text"
          data-autoid={`txtSearchBy${byCode ? 'Code' : 'Product'}-${props.index}`}
          className="peer relative w-full rounded-full bg-transparent pl-1 text-sm caret-primary outline-none"
          placeholder={props?.placeholder || 'Search'}
          disabled={disabled}
        />
        <div className="hidden active:flex active:gap-x-2 peer-focus-within:flex peer-focus-within:items-center peer-focus-within:justify-center peer-focus-within:gap-x-2">
          {defaultValue && (
            <CloseCross
              name="Clear"
              height={14}
              width={14}
              className="min-w-[14px] cursor-pointer"
              onClick={() => {
                setDefaultValue('');
                inputRef.current?.focus();
                resetSearchComplete();
              }}
            />
          )}
          <hr className="h-[18px] border-l border-t-0 border-black" />
          <IconSearch
            width="18px"
            height="18px"
            className={`min-w-[18px] cursor-pointer`}
            onClick={() => {
              getInventoryList?.(defaultValue);
              setShowResults(true);
            }}
          />
        </div>
      </div>
      {/* Search Results Container */}
      {showResults && (
        <div className="absolute z-[999] max-h-40 w-full overflow-y-auto rounded-bl rounded-br border-x border-b border-primary bg-white shadow-lg">
          {isLoading ? (
            <div className="flex h-10 w-full items-center justify-center ">
              <Spinner color="primary" className="text-primary/40" />
            </div>
          ) : results.length > 0 ? (
            results.map((item, index) => {
              return (
                <div
                  onMouseDown={() => handleResultClick(index)}
                  key={index}
                  ref={index === focusedIndex ? resultContainer : null}
                  style={{
                    backgroundColor: index === focusedIndex ? 'rgba(211,229,239,0.5)' : '',
                  }}
                  className="cursor-pointer border-b border-gray-300 p-1.5 last:border-b-0 hover:bg-primary/10"
                >
                  {renderItem(item)}
                </div>
              );
            })
          ) : (
            <Typography className="border-b border-b-gray-500 py-2.5 text-center last:border-b-transparent hover:bg-primary/10">
              No results found
            </Typography>
          )}
        </div>
      )}
      {isSelectVariantDialog && (
        <SelectVariantDialog
          handleCancel={() => setIsSelectVariantDialog?.(false)}
          handleAdd={handleAdd}
          item={selectedProduct}
          isLoading={inventoryLoading}
        />
      )}
    </div>
  );
};

export default QuoteSmartSearch;
