import React, {useEffect, useState} from 'react';
import {Chip, Typography} from 'spenda-ui-react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {ARTable} from '../../components/AccountsReceivable/ARTable';
import {IARColumns} from '../../components/AccountsReceivable/ARTable';
import {SPagination} from '../../components/tables/SPagination';
import {IQuotesList} from '../../model/quotes/quotes';
import {QuoteStatus, QuoteStatusLabel} from '../../model/constants/Constants';
import {PriceFormat} from '../../utils/formatter';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AUTH_SELLING_QUOTES_CREATE, AUTH_SELLING_QUOTES_DETAILS} from '../../routes/QuoteManagementRoutes';
import {ServiceJobStatus} from '../../model/service-management/serviceJob';

interface IQuoteListProps {
  quotes: IQuotesList[];
  pageSize: number;
  totalCount: number;
  currentPage: number;
  columnOptions?: string[];
  handlePageChange: (newPage: number) => void;
  handleRowsPerPageChange: (rows: number) => void;
}

const getChipColor = (status: QuoteStatus) => {
  switch (status) {
    case QuoteStatus.Completed:
      return 'success';
    case QuoteStatus.ServiceInitiated:
    case QuoteStatus.Sent:
      return 'cool-purple';
    case QuoteStatus.Rejected:
    case QuoteStatus.Expired:
      return 'error';
    default:
      return 'primary';
  }
};

export const getQuoteStatus = (status: QuoteStatus) => {
  return (
    <Chip
      className="m-auto h-6 min-w-[95px] py-1 leading-3"
      data-autoid={`txt${QuoteStatusLabel[status as keyof typeof QuoteStatusLabel]}`}
      color={getChipColor(status)}
      value={QuoteStatusLabel[status as keyof typeof QuoteStatusLabel]}
    />
  );
};

const QuotesList = (props: IQuoteListProps) => {
  const {pageSize, totalCount, currentPage, handleRowsPerPageChange, handlePageChange, quotes, columnOptions} = props;
  const [quoteColumns, setQuoteColumns] = useState<IARColumns[]>();
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const history = useHistory();

  useEffect(() => {
    if (
      quoteColumns
        ?.map(item => item.key)
        .sort()
        .join('.') != columnOptions?.sort().join(',')
    ) {
      const columns = (quoteColumns && [...quoteColumns]) || [];
      columns && _.remove(columns);
      if (columnOptions?.includes('Customer')) {
        columns?.push({
          title: 'Customer',
          key: 'customer',
          width: '13%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography variant="small" data-autoid={`txtCustomerName-${row.quotePackageID}`}>
                {row.isIndividual ? row.companyName : `${row?.contactFirstName} ${row?.contactLastName}`}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Company')) {
        columns?.push({
          title: 'Company',
          key: 'company',
          width: '11%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography variant="small" data-autoid={`txtCustomerName-${row.quotePackageID}`}>
                {!row.isIndividual ? row?.companyName : '-'}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Variants')) {
        columns?.push({
          title: 'Variants',
          key: 'variants',
          width: '8%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography variant="small" data-autoid={`txtCustomerName-${row.quotePackageID}`}>
                {row.countPackages > 0 && ` ${row.countPackages} variant${row.countPackages > 1 ? 's' : ''}`}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Customer ref')) {
        columns?.push({
          title: 'Ref',
          key: 'ref',
          width: '8%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography data-autoid={`txtRef-${row.refNumber}`} variant="small" className="text-black-800">
                {row.refNumber}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Created date')) {
        columns?.push({
          title: 'Created date',
          key: 'createdDate',
          width: '10%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography
                variant="small"
                className="text-black-800"
                data-autoid={`txtCreatedDate-${row.quotePackageID}`}
              >
                {moment(row.quoteDate).format('Do MMM YYYY')}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Created by')) {
        columns?.push({
          title: 'Created by',
          key: 'createdBy',
          width: '12%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography variant="small" className="text-black-800" data-autoid={`txtCreatedBy-${row.quotePackageID}`}>
                {row.createdByUserName}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Assigned to')) {
        columns?.push({
          title: 'Assigned to',
          key: 'assignedTo',
          width: '12%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography
                variant="small"
                className="text-black-800"
                data-autoid={`txtAssignedTo-${row.quotePackageID}`}
              >
                {row.assignedToUserName}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Est. cost (ex)')) {
        columns?.push({
          title: 'Est. cost (ex) range',
          key: 'estCost',
          width: '11%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography variant="small" className="text-black-800" data-autoid={`txtEstCostES-${row.quotePackageID}`}>
                {row.minTotalEx !== row.maxTotalEx
                  ? `${PriceFormat(row.minTotalEx || 0)} - ${PriceFormat(row.maxTotalEx || 0)}`
                  : PriceFormat(row.minTotalEx || 0)}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Est. cost (inc)')) {
        columns?.push({
          title: 'Est. cost (inc) range',
          key: 'estCostinc',
          width: '11%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography
                variant="small"
                className="text-black-800"
                data-autoid={`txtEstCostInc-${row.quotePackageID}`}
              >
                {row.minTotalInc !== row.maxTotalInc
                  ? `${PriceFormat(row.minTotalInc || 0)} - ${PriceFormat(row.maxTotalInc || 0)}`
                  : PriceFormat(row.minTotalInc || 0)}
              </Typography>
            );
          },
        });
      }
      {
        columns?.push({
          title: 'Expiry date/interval',
          key: 'expiryDate',
          width: '15%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            return (
              <Typography
                variant="small"
                className="text-black-800"
                data-autoid={`txtExpiryDate-${row.quotePackageID}`}
              >
                {row.expiresInDays && row.status === QuoteStatus.Draft && !row.isSent
                  ? `${row.expiresInDays} days`
                  : moment(row.expiryDate).format('Do MMM YYYY')}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Quote status')) {
        columns?.push({
          title: 'Quote status',
          key: 'quoteStatus',
          width: '10%',
          align: 'center',
          rowClassName: 'p-2.5',
          rowRenderer: (row: IQuotesList) => {
            if (row.isSent && row.status === QuoteStatus.Draft) {
              if (moment(row.expiryDate).isSameOrAfter(new Date(), 'day')) return getQuoteStatus(QuoteStatus.Sent);
              else return getQuoteStatus(QuoteStatus.Expired);
            }
            if (QuoteV289367 && row.isSent && row.status === QuoteStatus.Approved) {
              if (row.serviceStatus === ServiceJobStatus.Closed) return getQuoteStatus(QuoteStatus.Completed);
              if (row.serviceJobID) return getQuoteStatus(QuoteStatus.ServiceInitiated);
              if (row?.paidDepositAmount && row?.paidDepositAmount > 0)
                return getQuoteStatus(QuoteStatus.DepositReceived);
            }
            return getQuoteStatus(row.status);
          },
        });
      }
      setQuoteColumns(columns);
    }
  }, [columnOptions, QuoteV289367]);

  const handleRowClick = (row: IQuotesList) => {
    if (row.status === QuoteStatus.Draft && !row.isSent) {
      history.push(`${AUTH_SELLING_QUOTES_CREATE}${row?.quotePackageID}`);
    } else history.push(`${AUTH_SELLING_QUOTES_DETAILS}${row?.quotePackageID}`);
  };

  return (
    <div className="h-full">
      <div className="my-2 h-1 w-full border-b border-b-[#ECECEC]" />
      <div className={`h-[calc(100vh-330px)]`}>
        {quoteColumns && (
          <ARTable
            isHighlightRowOnHover
            columns={quoteColumns}
            scope="AR"
            rows={quotes}
            onRowClick={handleRowClick}
            isLoading={false}
            tableClass="!-ml-2.5 !mr-0 !w-[calc(100%+10px)]"
            dataAutoId="Item"
          />
        )}
      </div>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 15, 25]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};

export default QuotesList;
