import React, {PropsWithChildren, useState} from 'react';

import {IServiceJob, ServiceJobScope} from '../../model/service-management/serviceJob';
import {ServiceContext} from './ServiceContext';
import {ICustomer} from '../../model/customer/Customer';
import {FileType} from '../../components/dialog/QuoteAttachmentDialog';

const ServiceContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [serviceJob, setServiceJob] = useState<IServiceJob>();
  const [customerDetails, setCustomer] = useState<ICustomer>();
  const [scope, setScope] = useState<ServiceJobScope>(ServiceJobScope.QuoteDetails);
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<FileType[]>([]);
  const [deleteAttachmentGUIDs, setDeleteAttachmentGUIDs] = useState<string[]>([]);

  return (
    <ServiceContext.Provider
      value={{
        addedFilesToUpload: addedFilesToUpload,
        customerDetails: customerDetails,
        deleteAttachmentGUIDs: deleteAttachmentGUIDs,
        scope: scope,
        serviceJob: serviceJob,
        setAddedFilesToUpload: setAddedFilesToUpload,
        setCustomerDetails: setCustomer,
        setDeleteAttachmentGUIDs: setDeleteAttachmentGUIDs,
        setScope: setScope,
        setServiceJob: setServiceJob,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
export {ServiceContextProvider};
