import {Tooltip} from '@material-ui/core';
import {Done, Edit} from '@material-ui/icons';
import clsx from 'clsx';
import {Field, FieldArray, Form, Formik} from 'formik';
import {isEmpty} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import {Badge, Button, IconButton, Typography} from 'spenda-ui-react';

import ErrorIcon from '../../../assets/svg/ErrorIcon';
import SelectAPInvoice from '../../../assets/svg/accounts-payable/select-ap-invoice.svg';
import {useClaimsAndReturnInputHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {IClaimRequestDetailsResponse} from '../../../model/claims/ClaimsAndReturns';
import {
  ClaimActions,
  ClaimAndReturnRowAction,
  ClaimReasons,
  ClaimsAndReturnsStatus,
} from '../../../model/constants/Constants';
import {IInvoice} from '../../../model/invoice/Invoice';
import {IClaimRequestLineItemsFormValues} from '../../../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import useClaimsAndReturnsAPI, {
  ICreateEarlyCreditNotePayload,
  IDisputeRejectionPayload,
  IUpdateClaimRequestPayload,
} from '../../../services/useClaimsAndReturnsAPI';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {Toast} from '../../../utils/Toast';
import {PriceFormat} from '../../../utils/formatter';
import {CurrencyTextField, STextField} from '../../inputs/STextField';
import LoadingIndicator from '../../ui/LoadingIndicator';
import EditEvidenceDialog from '../EditEvidenceDialog';
import {CancelClaimModal} from '../modals/CancelClaimModal';
import {DeleteClaimModal} from '../modals/DeleteClaimModal';
import {SendClaimModal} from '../modals/SendClaimModal';
import appContext from '../../../context/app/appContext';
import {ClaimsAndReturnActionReasonDropdown} from '../../../screens/AccountsReceivable/claims-and-returns/ClaimsAndReturnInputs';
import IconCamera from '../../../assets/svg/IconCamera';
import {ClaimsWidgetHeader} from './ClaimsWidgetHeader';

export interface IPSBLClaimsWidgetProps {
  claimRequestId?: number;
  supplierId: number;
  refecthClaimList: () => void;
}

export const PSBLClaimsWidget = (props: IPSBLClaimsWidgetProps) => {
  // Props
  const {claimRequestId, supplierId, refecthClaimList} = props;

  // Hooks
  const history = useHistory();
  const {search} = useLocation();

  // APIs
  const {getClaimRquestDetailBuyer} = useClaimsAndReturnsAPI();
  const {getInvoice} = useSupplierTransactionsAPI();

  // States
  const [claimDetails, setClaimDetails] = useState<IClaimRequestDetailsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDtl, setInvoiceDtl] = useState<IInvoice>();

  // UseEffect
  useEffect(() => {
    fetchClaimDetails();
  }, [claimRequestId]);

  const fetchClaimDetails = async () => {
    try {
      if (!claimRequestId) return;
      setIsLoading(true);

      const claimDetailsResponse = await getClaimRquestDetailBuyer(supplierId, claimRequestId);
      setClaimDetails(claimDetailsResponse);

      if (claimDetailsResponse?.invoiceGUID) {
        const request = {
          LinkedSupplierID: supplierId || 0,
          TransactionGuid: claimDetailsResponse?.invoiceGUID,
        };

        const invoiceResponse = await getInvoice(request);

        setInvoiceDtl(invoiceResponse);
      } else {
        setInvoiceDtl(undefined);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const {errors} = e as any;
      Toast.error(errors?.[0]?.message);
    }
  };

  const handleRefetchData = async (status: ClaimsAndReturnsStatus) => {
    const query = new URLSearchParams(search);
    refecthClaimList();
    if (![ClaimsAndReturnsStatus?.Deleted, ClaimsAndReturnsStatus?.Cancelled]?.includes(status)) {
      await fetchClaimDetails();
      return;
    }
    setClaimDetails(undefined);
    query.delete('claimRequestId');
    history.replace({search: query?.toString()});
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator size="sm" isLoading={isLoading} />
      ) : claimRequestId ? (
        claimDetails && (
          <ClaimDetails
            refetchClaimsDetails={handleRefetchData}
            fetchClaimDetails={fetchClaimDetails}
            supplierId={supplierId}
            claimDetails={claimDetails}
            claimRequestId={claimRequestId}
            invoiceDtl={invoiceDtl}
            onUpdateClaimRequest={c => setClaimDetails(c)}
          />
        )
      ) : (
        <div className="pb-15 relative flex h-full w-full flex-col">
          <div className="flex h-full w-full flex-col items-center justify-center text-center font-poppins text-lg font-medium text-black-800">
            <div className="mx-5 rounded-[10px] bg-[#eff6f9] px-7 py-8 text-center">
              <Typography className="font-light text-black-800" variant="h2">
                Please select a claim
              </Typography>
              <img src={SelectAPInvoice} className="mx-auto mt-8" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface IClaimDetailProps {
  claimDetails: IClaimRequestDetailsResponse;
  supplierId: number;
  claimRequestId: number | undefined;
  refetchClaimsDetails: (status: string) => void;
  invoiceDtl: IInvoice | undefined;
  onUpdateClaimRequest: (c: IClaimRequestDetailsResponse) => void;
  fetchClaimDetails: () => Promise<void>;
}

const ClaimDetails = (props: IClaimDetailProps) => {
  // Props
  const {
    claimDetails,
    supplierId,
    claimRequestId,
    invoiceDtl,
    refetchClaimsDetails,
    onUpdateClaimRequest,
    fetchClaimDetails,
  } = props;

  // APIs
  const {
    updateClaimRequest,
    updateClaimAndReturnStatus,
    disputeClaimRequest,
    createEarlyCreditNote,
    isLoading: isSubmitting,
  } = useClaimsAndReturnsAPI();

  // States
  const [editClaimLineId, setEditClaimLineId] = useState<number>();
  const [formValues, setFormValues] = useState<IClaimRequestLineItemsFormValues[]>([]);
  const [isShowSendClaimDialog, setIsShowSendClaimDialog] = useState(false);
  const [isShowDeleteClaimDialog, setIsShowDeleteClaimDialog] = useState(false);
  const [isShowCancelClaimDialog, setIsShowCancelClaimDialog] = useState(false);
  const [iSShowUploadAttachmentModal, setISShowUploadAttachmentModal] = useState(false);
  const [isShowInternalNotesDialog, setIsShowInternalNotesDialog] = useState(false);
  const [claimUploadAttachmentData, setClaimUploadAttachmentsData] = useState<IClaimRequestLineItemsFormValues>();

  // Context
  const {marketplaceSupplier} = useContext(appContext);

  // Hooks
  const history = useHistory();
  const {getClaimRquestDetailBuyer} = useClaimsAndReturnsAPI();

  const {reasonDropdownOptions} = useClaimsAndReturnInputHook({linkedSupplierID: supplierId});

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2TenantOwned} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2SuppDriven} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = claimAndReturnsV2SuppDriven && claimAndReturnsV2TenantOwned;

  // UseEffect
  useEffect(() => {
    if (claimDetails) handleSetClaimsValues(claimDetails);
  }, [claimDetails, invoiceDtl]);

  useEffect(() => {
    setIsShowSendClaimDialog(false);
    setIsShowDeleteClaimDialog(false);
    setIsShowCancelClaimDialog(false);
  }, [claimRequestId]);

  // Inputs
  function quantityInput({field, form, indx}: {field: any; form: any; indx: number}) {
    return (
      <STextField
        v2
        title={
          form?.values?.lineItems?.[indx]?.availableQty !== null
            ? `Available Quantity: ${form?.values?.lineItems?.[indx]?.availableQty}`
            : ''
        }
        id="Qty"
        type="number"
        label="Qty"
        className="!mx-2 !mt-1"
        name={field?.name}
        value={field?.value}
        onChange={field?.onChange}
        onBlur={field?.onBlur}
        error={Boolean(form?.touched?.lineItems?.[indx]?.quantity && form?.errors?.lineItems?.[indx]?.quantity)}
        InputProps={{
          endAdornment: form?.values?.lineItems?.[indx]?.availableQty !== null && (
            <Tooltip
              arrow
              classes={{
                tooltip: '!bg-white !shadow-lg !p-4 !border-[1px] !border-solid !border-gray-400',
                arrow: 'before:!border-[1px] before:!border-solid before:!border-gray-400 !text-white',
              }}
              title={
                <React.Fragment>
                  <div className="z-40 text-start font-poppins text-gray-900">
                    <Typography variant="xsmall" data-autoid="txtClaimLineErrormsg" className="mb-2 text-red-700">
                      {form?.errors?.lineItems?.[indx]?.quantity}
                    </Typography>
                    <Typography variant="xsmall" data-autoid={`txtClaimQuantity`}>
                      {`Available Quantity: ${form?.values?.lineItems?.[indx]?.availableQty}`}
                    </Typography>
                  </div>
                </React.Fragment>
              }
            >
              <div data-autoid="iconErrorClaimLineQty">
                <ErrorIcon />
              </div>
            </Tooltip>
          ),
        }}
      />
    );
  }

  function currencyInput({field, form, indx}: {field: any; form: any; indx: number}) {
    return (
      <CurrencyTextField
        id="Amount"
        type="text"
        label="Amount"
        className="!ml-2 !mt-1"
        data-autoid={`txtAmount`}
        name={field?.name}
        value={field?.value}
        onChange={field?.onChange}
        onBlur={field?.onBlur}
        helperText={form?.touched?.lineItems?.[indx]?.price && form?.errors?.lineItems?.[indx]?.price}
        error={Boolean(form?.touched?.lineItems?.[indx]?.price && Boolean(form?.errors?.lineItems?.[indx]?.price))}
      />
    );
  }

  function selectReasonInput({field}: {field: any}) {
    return (
      <div className=" !mt-2">
        <ClaimsAndReturnActionReasonDropdown
          dataAutoId={`ddlSelectBatchPeriodCustomerReason`}
          label="Reason"
          className="!h-[45px] !min-w-[145px]"
          name={field?.name}
          value={field?.value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          reasonDropdownOptions={reasonDropdownOptions}
          showSubReason={true}
          claimAndReturnsV272602={claimAndReturnsV272602}
          type="reason"
        />
      </div>
    );
  }

  function selectActionInput({field}: {field: any}) {
    return (
      <div className="!ml-3 !mt-2">
        <ClaimsAndReturnActionReasonDropdown
          dataAutoId={`ddlSelectBatchPeriodCustomerAction`}
          label="Action"
          className="!h-[45px] !min-w-[145px]"
          name={field?.name}
          value={field?.value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          type="action"
        />
      </div>
    );
  }

  const handleSetClaimsValues = (claimDetails: IClaimRequestDetailsResponse) => {
    const lineItems: IClaimRequestLineItemsFormValues[] = claimDetails?.lines?.map(l => ({
      claimRequestID: l?.claimRequestID,
      claimRequestLineID: l?.claimRequestLineID,
      inventoryID: l?.inventoryID,
      invoiceLineID: l?.invoiceLineID,
      code: l?.code,
      shortDescription: l?.shortDescription,
      description: l?.description,
      uoM: l?.uoM,
      quantity: l?.quantity,
      price: l?.sellPriceEx,
      lineTotalEx: l?.lineTotalEx,
      lineTotalInc: l?.lineTotalInc,
      lineTotalTax: l?.lineTotalTax,
      reason: l?.reason || ClaimReasons.Damaged,
      action: l?.action || ClaimActions.Credit,
      isTaxExempt: l?.isTaxExempt,
      taxPercent: l?.taxRate,
      availableQty: l.claimableQuantity,
      notes: l?.notes || '',
      rowAction: ClaimAndReturnRowAction.Update,
      isAttachmentUpload: Boolean(l?.claimRequestAttachments?.length),
      claimRequestAttachments: l?.claimRequestAttachments,
    }));

    setFormValues(lineItems);
  };

  const handleSubmit = async (values: {lineItems: IClaimRequestLineItemsFormValues[]}) => {
    try {
      if (!claimRequestId) return;

      const filteredLineItems = values?.lineItems?.find(l => l?.claimRequestLineID === editClaimLineId);

      if (!filteredLineItems || !filteredLineItems?.claimRequestLineID || !claimRequestId) return;

      const payload: IUpdateClaimRequestPayload = {
        linkedSupplierID: supplierId,
        vendorID: claimDetails?.vendorID || undefined,
        invoiceID: claimDetails?.invoiceID || undefined,
        defaultClaimReason: claimDetails?.reason,
        defaultReturnType: claimDetails?.action,
        lines: [
          {
            claimRequestLineID: filteredLineItems?.claimRequestLineID,
            invoiceLineID: filteredLineItems?.invoiceLineID,
            quantity: filteredLineItems?.quantity,
            amountEx: filteredLineItems?.price,
            claimReason: filteredLineItems?.reason,
            returnType: filteredLineItems?.action,
            action: ClaimAndReturnRowAction.Update,
            isTaxExempt: filteredLineItems?.isTaxExempt,
            notes: filteredLineItems?.notes || undefined,
            description: filteredLineItems?.description,
            inventoryID: filteredLineItems?.inventoryID,
          },
        ],
      };

      const ucr = await updateClaimRequest(claimRequestId, payload);
      setEditClaimLineId(undefined);
      onUpdateClaimRequest(ucr);
    } catch (e) {
      const {errors} = e as any;
      Toast.error(errors?.[0]?.message);
    }
  };

  const handleUpdateClaimRequestStatus = async (status: ClaimsAndReturnsStatus) => {
    try {
      if (!claimRequestId) return;
      const ucr = await updateClaimAndReturnStatus(claimRequestId, {status, linkedSupplierID: supplierId});
      setEditClaimLineId(undefined);
      refetchClaimsDetails(ucr?.claimRequestStatus);
    } catch (e) {
      const {errors} = e as any;
      Toast.error(errors?.[0]?.message);
    }
  };

  const handleDisputeClaimRequest = async () => {
    try {
      if (!claimRequestId || !supplierId) return;
      const payload: IDisputeRejectionPayload = {
        claimRequestID: claimRequestId,
        linkedSupplierID: supplierId,
      };
      const response = await disputeClaimRequest(payload);
      if (response?.claimRequestID) {
        history.push(`/supplier/${supplierId}/claim-request/edit/${response?.claimRequestID}`);
      }
    } catch {}
  };

  const handleCreateEarlyCreditNote = async () => {
    try {
      if (!claimRequestId || !supplierId) return;
      const payload: ICreateEarlyCreditNotePayload = {
        claimRequestID: claimRequestId,
        linkedSupplierID: supplierId,
      };
      await createEarlyCreditNote(payload);
      await fetchClaimDetails();
    } catch {}
  };

  const PsblClaimsWidgetFormValidation = Yup.object({
    lineItems: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .nullable(true)
          .test('avl-quantity-check', 'Quantity should not be greater than available quantity', (v, c) => {
            if (c?.parent?.rowAction === ClaimAndReturnRowAction.Delete) return true;
            if (!v) return true;
            const availQty = c?.parent?.availableQty;
            return typeof availQty === 'number' ? v <= availQty : true;
          })
          .test('quantity-check', 'Qty should be greater than 0', (v, c) => {
            if (c?.parent?.rowAction === ClaimAndReturnRowAction.Delete) return true;
            if (!v) return false;
            return v > 0;
          }),
        action: Yup.string().required('Action is required'),
        reason: Yup.string().required('Reason is required'),
        price: Yup.number().min(1, 'Amount Should be greater than zero').required('Amount is required'),
      }),
    ),
  });

  const isShowActionButton = ![
    ClaimsAndReturnsStatus?.Rejected,
    ClaimsAndReturnsStatus?.Cancelled,
    ClaimsAndReturnsStatus?.Approved,
    ClaimsAndReturnsStatus?.Finalised,
  ]?.includes(claimDetails.status);

  const handleRefetchClaimDetails = async (_claimID: number) => {
    const claim = await getClaimRquestDetailBuyer(supplierId, _claimID);
    onUpdateClaimRequest(claim);

    setClaimUploadAttachmentsData(prev => {
      if (prev) {
        const claimRequestLine = claim.lines?.find(l => l?.claimRequestLineID === prev?.claimRequestLineID);
        return {
          ...prev,
          claimRequestId: claimRequestLine?.claimRequestID,
          claimRequestLineID: claimRequestLine?.claimRequestLineID,
          claimRequestAttachments: claimRequestLine?.claimRequestAttachments,
        };
      }
      return prev;
    });
  };

  return (
    <div
      className={clsx(
        'relative flex h-full w-full flex-col',
        {'pb-15': isShowActionButton},
        {'pb-15': claimDetails?.rejectReason && claimDetails?.status === ClaimsAndReturnsStatus.Rejected},
      )}
    >
      <Formik
        initialValues={{lineItems: formValues}}
        onSubmit={handleSubmit}
        validationSchema={PsblClaimsWidgetFormValidation}
        enableReinitialize
        validateOnChange={false}
        className="h-[inherit]"
      >
        {({values, errors, touched, handleSubmit, setFieldValue, validateForm}) => (
          <Form className="h-[inherit]">
            <div className="flex h-full w-full flex-col justify-between pb-20">
              <ClaimsWidgetHeader
                claimDetails={claimDetails}
                supplierId={supplierId}
                claimRequestId={claimRequestId}
                isShowInternalNotesDialog={isShowInternalNotesDialog}
                iSShowUploadAttachmentModal={iSShowUploadAttachmentModal}
                setIsShowInternalNotesDialog={setIsShowInternalNotesDialog}
                setISShowUploadAttachmentModal={setISShowUploadAttachmentModal}
                errors={errors}
              />
              <div className="flex h-full w-full flex-col items-center overflow-y-auto">
                <div className="flex h-full w-full flex-col items-center gap-2.5 px-2.5 pb-[80px] pt-3">
                  <FieldArray
                    name="lineItems"
                    render={() => (
                      <div className="w-full">
                        {values?.lineItems?.map((l, indx) => (
                          <div
                            data-autoid={`rowClaimWidgetLine${l?.claimRequestLineID}`}
                            key={indx}
                            className={clsx('mb-1', {
                              'border-[1px] border-red-700':
                                touched?.lineItems?.[indx] &&
                                errors?.lineItems?.[indx] &&
                                editClaimLineId !== l?.claimRequestLineID,
                            })}
                          >
                            <div className="relative flex w-full flex-col bg-[#F4F8FB] px-2.5 py-3">
                              <div className="mb-[3px] flex flex-row items-center justify-between">
                                <Typography className="font-medium leading-normal text-black-800" variant="paragraph">
                                  {l?.shortDescription}
                                </Typography>

                                {claimDetails?.status === ClaimsAndReturnsStatus?.Draft &&
                                  (editClaimLineId === l?.claimRequestLineID ? (
                                    <button
                                      type="button"
                                      data-autoid={`btnDone`}
                                      className="cursor-pointer select-none align-middle font-poppins text-xs font-semibold leading-normal text-primary"
                                      onClick={() => handleSubmit()}
                                    >
                                      {isSubmitting ? (
                                        'Updating...'
                                      ) : (
                                        <Typography
                                          variant="xsmall"
                                          className="font-poppins font-semibold leading-normal"
                                        >
                                          <Done className="inline !h-4" /> Done
                                        </Typography>
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      data-autoid="btnEdit"
                                      className="cursor-pointer select-none align-middle font-poppins text-xs font-semibold leading-normal text-primary"
                                      onClick={() => {
                                        setFieldValue(`lineItems[${indx}].rowAction`, ClaimAndReturnRowAction.Update);
                                        setEditClaimLineId(l?.claimRequestLineID);
                                      }}
                                    >
                                      <Edit className="!h-4" /> Edit
                                    </button>
                                  ))}
                                {claimDetails?.status === ClaimsAndReturnsStatus?.Sent && (
                                  <Badge
                                    color="error"
                                    content={l?.claimRequestAttachments?.length || 0}
                                    className="min-h-[18px] min-w-[18px]"
                                    invisible={!l.isAttachmentUpload}
                                  >
                                    <IconButton
                                      variant="text"
                                      size="sm"
                                      data-autoid={`btnUploadAttachment-${l?.claimRequestLineID}`}
                                      className="active:bg-transparent"
                                      onClick={() => setClaimUploadAttachmentsData(l)}
                                    >
                                      <IconCamera color={l.isAttachmentUpload ? 'currentColor' : ''} className="!h-4" />
                                    </IconButton>
                                  </Badge>
                                )}
                              </div>
                              <div className="mb-2.5 flex flex-row items-center font-poppins text-xs font-normal leading-normal text-black-800">
                                <Typography variant="xsmall" className="mr-2 inline">
                                  Code: {l?.code}
                                </Typography>
                                |
                                {editClaimLineId === l?.claimRequestLineID ? (
                                  <div className="!w-[100px]">
                                    <Field name={`lineItems[${indx}].quantity`} indx={indx} component={quantityInput} />
                                  </div>
                                ) : (
                                  <Typography className="mx-2 inline" variant="xsmall" data-autoid={`lblQty`}>
                                    Qty: {l?.quantity}
                                  </Typography>
                                )}
                                |
                                {editClaimLineId === l?.claimRequestLineID ? (
                                  <div className="w-[100px]">
                                    <Field name={`lineItems[${indx}].price`} indx={indx} component={currencyInput} />
                                  </div>
                                ) : (
                                  <Typography className="mx-2 inline" variant="xsmall" data-autoid={`lblSellPriceEx`}>
                                    {' '}
                                    Amount: {PriceFormat(l?.price)}
                                  </Typography>
                                )}
                              </div>
                              <div className="flex flex-row">
                                {editClaimLineId === l?.claimRequestLineID ? (
                                  <Field name={`lineItems[${indx}].reason`} component={selectReasonInput} />
                                ) : (
                                  <Typography
                                    className="mr-2 font-semibold leading-normal text-black-800"
                                    variant="xsmall"
                                  >
                                    Reason:{' '}
                                    {claimAndReturnsV272602 ? (
                                      <Typography
                                        variant="xsmall"
                                        data-autoid={`lblReason`}
                                        className="inline font-normal"
                                      >
                                        {l?.reason}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        className="inline font-normal"
                                        variant="xsmall"
                                        data-autoid={`lblReason`}
                                      >
                                        {(Object.keys(ClaimReasons) as Array<keyof typeof ClaimReasons>)
                                          .find(key => ClaimReasons[key] === l?.reason)
                                          ?.replace(/([A-Z])/g, ' $1')
                                          .trim()}
                                      </Typography>
                                    )}
                                  </Typography>
                                )}
                                {editClaimLineId === l?.claimRequestLineID ? (
                                  <Field name={`lineItems[${indx}].action`} component={selectActionInput} />
                                ) : (
                                  <Typography className=" font-semibold leading-normal text-black-800" variant="xsmall">
                                    Action:{' '}
                                    <Typography
                                      variant="xsmall"
                                      data-autoid={`lblAction`}
                                      className="inline font-normal"
                                    >
                                      {l?.action}
                                    </Typography>
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>

                <div className="absolute bottom-0 left-2.5 mb-[70px] flex w-[394px] flex-col gap-2.5 ">
                  {claimDetails?.rejectReason && claimDetails?.status === ClaimsAndReturnsStatus.Rejected && (
                    <>
                      <div className="flex flex-col items-start justify-between rounded-[8px] bg-[#F3DFDA] p-2.5">
                        <Typography className=" mb-[6px] font-semibold text-black-800" variant="xsmall">
                          Claim rejected
                        </Typography>
                        <Typography
                          className="font-normal text-black-800"
                          variant="xsmall"
                          data-autoid={`lblRejectReason`}
                        >
                          {claimDetails?.rejectReason}
                        </Typography>
                      </div>
                    </>
                  )}

                  {false && (
                    <div className="flex flex-col items-start justify-between rounded-[8px] bg-[#D8ECE4] p-2.5">
                      <Typography className="  mb-[6px] font-semibold text-black-800" variant="xsmall">
                        Claim approved
                      </Typography>
                      <Typography className="  font-normal text-black-800" variant="xsmall">
                        The credit note CR-000112 will be added to the statement view and can be used when making a
                        payment.
                      </Typography>
                    </div>
                  )}

                  {isShowActionButton && (
                    <div className="h-15 flex flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                      {/* Delete Button */}
                      {[ClaimsAndReturnsStatus?.Draft]?.includes(claimDetails?.status) && (
                        <Button
                          variant="outlined"
                          color="error"
                          className="bg-white"
                          data-autoid={`btnDelete`}
                          onClick={() => setIsShowDeleteClaimDialog(true)}
                        >
                          Delete
                        </Button>
                      )}

                      {/* Cancel Button */}
                      {[ClaimsAndReturnsStatus?.Sent, ClaimsAndReturnsStatus?.Reviewed]?.includes(
                        claimDetails?.status,
                      ) && (
                        <Button
                          variant="outlined"
                          color="error"
                          className="bg-white"
                          data-autoid={`btnCancel`}
                          onClick={() => setIsShowCancelClaimDialog(true)}
                        >
                          Cancel
                        </Button>
                      )}

                      {/* Save And Send Button */}
                      <div className="flex flex-row items-center gap-3">
                        {claimDetails?.status === ClaimsAndReturnsStatus?.Draft && (
                          <>
                            <Button
                              className="bg-white"
                              variant="outlined"
                              color="primary"
                              data-autoid={`btnEdit`}
                              onClick={() =>
                                history.push(
                                  `/supplier/${supplierId}/claim-request/edit/${claimDetails?.claimRequestID}`,
                                )
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              variant="filled"
                              color="primary"
                              data-autoid={`btnSend`}
                              onClick={async () => {
                                const errs = await validateForm();
                                if (!isEmpty(errs)) handleSubmit();
                                else setIsShowSendClaimDialog(true);
                              }}
                            >
                              Send
                            </Button>
                          </>
                        )}

                        {/* Early Credit Note Button */}
                        {[ClaimsAndReturnsStatus.Reviewed]?.includes(claimDetails?.status) &&
                          marketplaceSupplier?.CanCreateEarlyClaimPaymentShortTransactions &&
                          claimAndReturnsV272602 && (
                            <Button
                              variant="filled"
                              color="primary"
                              disabled={claimDetails?.isEarlyCreditNoteCreated || isSubmitting}
                              onClick={() => handleCreateEarlyCreditNote()}
                              data-autoid={`btnEarlyCreditNote`}
                            >
                              Early credit note
                            </Button>
                          )}
                      </div>
                    </div>
                  )}
                  {claimDetails?.status === ClaimsAndReturnsStatus.Rejected && claimAndReturnsV272602 && (
                    <div className="h-15 flex flex-row items-center justify-center rounded-[8px] bg-[#f0f0f0] p-2.5">
                      <Button
                        variant="filled"
                        color="primary"
                        data-autoid="btnDisputeRejection"
                        disabled={isSubmitting || claimDetails.isDisputed}
                        onClick={handleDisputeClaimRequest}
                      >
                        Dispute Rejection
                      </Button>
                    </div>
                  )}
                  {false && (
                    <div className="h-15 flex flex-row items-center justify-center rounded-[8px] bg-[#f0f0f0] p-2.5">
                      <Button variant="filled" color="primary" data-autoid={`btnSaveChanges`}>
                        Save Changes
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              {/* Modals */}
              {isShowSendClaimDialog && (
                <SendClaimModal
                  isSubmitting={isSubmitting}
                  isOpen={isShowSendClaimDialog}
                  onConfirm={() => handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus.Sent)}
                  onClose={() => setIsShowSendClaimDialog(false)}
                />
              )}
              {isShowDeleteClaimDialog && (
                <DeleteClaimModal
                  isOpen={isShowDeleteClaimDialog}
                  onConfirm={async () => handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus.Deleted)}
                  onClose={() => setIsShowDeleteClaimDialog(false)}
                  isSubmitting={isSubmitting}
                />
              )}
              {isShowCancelClaimDialog && (
                <CancelClaimModal
                  isOpen={isShowCancelClaimDialog}
                  onConfirm={async () => handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus.Cancelled)}
                  onClose={() => setIsShowCancelClaimDialog(false)}
                  isSubmitting={isSubmitting}
                />
              )}
              {claimUploadAttachmentData && (
                <EditEvidenceDialog
                  canUploadAttachment
                  key={claimUploadAttachmentData?.claimRequestLineID}
                  claimRequestDetails={claimDetails}
                  linkedSupplierId={supplierId}
                  accountCustomerId={claimDetails?.accountCustomerID ?? undefined}
                  lineItemData={claimUploadAttachmentData}
                  showModal={claimUploadAttachmentData ? true : false}
                  handleClose={() => {
                    setClaimUploadAttachmentsData(undefined);
                  }}
                  handleRefetchClaimDetails={handleRefetchClaimDetails}
                  claimRequestId={claimRequestId}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
