import React from 'react';
import {useFormikContext} from 'formik';
import {Textarea, Typography} from 'spenda-ui-react';

import {IServiceJob, ServiceJobStatus} from '../../../model/service-management/serviceJob';

export default function ServiceNotes() {
  const {values, setFieldValue} = useFormikContext<IServiceJob>();
  const disabled = values?.status === ServiceJobStatus.Closed || values?.status === ServiceJobStatus.Cancelled;

  return (
    <div className="mx-auto flex w-3/5 flex-col gap-y-8 lg:px-4 xl:px-12">
      <div className="flex flex-col gap-y-2">
        {disabled && (
          <Typography variant="small" className="text-black-800">
            Internal note
          </Typography>
        )}
        <Textarea
          label="Internal note"
          displayLength
          maxLength={200}
          className="min-h-[140px]"
          value={values?.internalNote}
          onChange={e => setFieldValue('internalNote', e.target.value)}
          data-autoid={'txtInternalNode'}
          disabled={disabled}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        {disabled && (
          <Typography variant="small" className="text-black-800">
            Notes for the customer
          </Typography>
        )}
        <Textarea
          label="Notes for the customer"
          displayLength
          maxLength={200}
          className="min-h-[140px]"
          value={values?.externalNotes}
          onChange={e => setFieldValue('externalNotes', e.target.value)}
          data-autoid={'txtNotesForCustomer'}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
