import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Typography, Menu, MenuHandler, MenuList, SearchInput, Button, Checkbox} from 'spenda-ui-react';
import {camelCase, debounce} from 'lodash';
import {useUninvoicedContext} from './context/UninvoicedContext';
import {UninvoicedServiceTableColumn} from '../../../model/constants/Constants';

const ServiceUninvoiceHeader = () => {
  const [query, setQuery] = useState('');

  const {setSearchFilter, searchFilter, selectedFilterOptions, setSelectedFilterOptions} = useUninvoicedContext();

  const handleColumnOptions = (event: React.ChangeEvent<HTMLInputElement>, key: UninvoicedServiceTableColumn) => {
    setSelectedFilterOptions(prevState => {
      if (prevState.includes(key)) {
        return prevState.filter(item => item !== key);
      } else {
        return [...prevState, key];
      }
    });
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (typeof value != 'undefined') {
        setSearchFilter({
          ...searchFilter,
          SearchString: value,
        });
      }
    }, 750),
    [],
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const columnOptions = useMemo(
    () => [
      'Completion date',
      'Customer name',
      'Primary tech',
      'Asset details',
      'Customer email',
      'Phone no.',
      'No. of lines',
      'Total inc',
      'Billable hours',
    ],
    [],
  );

  useEffect(() => {
    setSelectedFilterOptions(columnOptions.map(item => camelCase(item)));
  }, [columnOptions]);

  const onClickClearSearch = () => {
    setQuery('');
    setSearchFilter({
      ...searchFilter,
      SearchString: '',
    });
  };

  return (
    <div className={`flex w-full items-center justify-center`}>
      <Typography className="whitespace-nowrap text-xl font-light text-black-800">
        Jobs to invoice service jobs
      </Typography>
      <div className="flex w-full justify-end gap-x-2">
        <div className="w-52">
          <SearchInput
            reverse={true}
            clearIcon
            value={query}
            clearIconProps={{
              onClick: onClickClearSearch,
            }}
            iconButtonProps={{
              name: 'Search',
            }}
            data-autoid="txtSearchInput"
            autoFocus
            placeholder="Search..."
            onChange={onSearchChange}
          />
        </div>
        <Menu
          placement="bottom-end"
          dismiss={{
            itemPress: false,
          }}
        >
          <MenuHandler>
            <Button variant="outlined">Column Options</Button>
          </MenuHandler>
          <MenuList className="flex flex-col border border-primary p-0">
            {columnOptions.map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
              >
                <Checkbox
                  id={camelCase(item)}
                  ripple={false}
                  label={item}
                  color="primary"
                  containerProps={{className: 'p-0'}}
                  checked={selectedFilterOptions.includes(camelCase(item))}
                  onChange={e =>
                    handleColumnOptions(
                      e,
                      UninvoicedServiceTableColumn[camelCase(item) as UninvoicedServiceTableColumn],
                    )
                  }
                  className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                  labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
                />
              </div>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default ServiceUninvoiceHeader;
