import {createContext, Dispatch, SetStateAction, useContext} from 'react';

import {IServiceJob, ServiceJobScope} from '../../model/service-management/serviceJob';
import {ICustomer} from '../../model/customer/Customer';
import {FileType} from '../../components/dialog/QuoteAttachmentDialog';

interface ServiceContextType {
  addedFilesToUpload: FileType[];
  customerDetails?: ICustomer;
  deleteAttachmentGUIDs: string[];
  scope: ServiceJobScope;
  serviceJob?: IServiceJob;
  setAddedFilesToUpload: Dispatch<SetStateAction<FileType[]>>;
  setCustomerDetails: Dispatch<SetStateAction<ICustomer>>;
  setDeleteAttachmentGUIDs: Dispatch<SetStateAction<string[]>>;
  setScope: Dispatch<SetStateAction<ServiceJobScope>>;
  setServiceJob: Dispatch<SetStateAction<IServiceJob>>;
}

const ServiceContext = createContext<ServiceContextType | null>(null);

const useServiceContext = () => {
  const context = useContext(ServiceContext);
  if (!context) {
    throw new Error('useServiceContext must be used within a ServiceContextProvider');
  }
  return context;
};

export {ServiceContext, useServiceContext};
