import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ChangePassword, NotFound, Auth0Login} from './screens/public';
import {CustomerInvitation} from './screens/public/CustomerInvitation';
import {PayByLink} from './screens/pay-by-link/PayByLink';
import {Callback} from './components/layout/Callback';
import {PayByLinkInvoice} from './screens/pay-by-link/PayByLinkInvoice';
import {PayByLinkProvider} from './screens/pay-by-link/PayByLinkContext';
import {CustomerQuotesPreview} from './screens/quote-management/customer-flow/CustomerQuotesPreview';
import UserUnauthorized from './components/layout/UserUnauthorized';

const UnauthenticatedApp: React.FC = () => {
  return (
    <div className="h-full">
      <PayByLinkProvider>
        <Switch>
          <Route path="/public/change-password" render={() => <ChangePassword />}></Route>
          <Route path="/quotes/:inviteCode" exact component={CustomerQuotesPreview} />
          <Route path="/401" exact render={() => <Redirect to="/" />} />
          <Route path="/404" exact render={() => <NotFound />} />
          <Route path="/user-unauthorized" exact render={() => <UserUnauthorized />} />
          <Route path="/statement" exact component={CustomerInvitation} />
          <Route path="/invoices" exact component={PayByLink} />
          <Route path="/invoices/pibl/:magicLink" exact component={PayByLinkInvoice} />
          <Route path="/callback/:servicename" exact render={() => <Callback />} />
          <Route path="/:marketPlacePath?" render={props => <Auth0Login {...props} />}></Route>
        </Switch>
      </PayByLinkProvider>
    </div>
  );
};

export default UnauthenticatedApp;
