import React, {useEffect, useMemo, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {IconButtonProps, makeStyles} from '@material-ui/core';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import moment from 'moment';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {Button, Input, Typography} from 'spenda-ui-react';
import {VisibleContent} from '../../ui/VisibleContent';

interface IApproveClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (referenceNo?: string, paymentDate?: string) => void;
  isSubmitting: boolean;
}

type DatePickerKeyboardProps = IconButtonProps & {
  [key: string]: string | undefined;
};

const useDatepickerStyles = makeStyles({
  payInvoiceText: {
    width: '350px',
    height: '45px',
    marginTop: '6px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiInputAdornment-root': {
        display: 'flex',
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        paddingLeft: '4px',
        '&.Mui-focused': {
          '& .MuiInputAdornment-root': {
            display: 'flex',
            paddingRight: '0px',
          },
        },
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        height: '46px',
        borderRadius: '6px',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
        borderWidth: '1px!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '4px',
        '& img': {
          width: '13px!important',
        },
      },
      '& .MuiInputBase-adornedStart': {
        paddingRight: '0px',
        paddingLeft: '7px',
      },
    },
    '&.editableInvoiceText': {
      '& .MuiOutlinedInput-root': {
        border: '1px solid transparent !important',
        '&.Mui-focused': {
          borderRadius: '0px',
          border: '1px solid rgb(30 121 173) !important',
        },
      },
    },
  },
  paymentDate: {
    '& legend': {
      width: '110px',
    },
  },
  pickerDialog: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#1C78AD',
        color: '#fff',
      },
    },
  },
});

export const ApproveClaimModal = (props: IApproveClaimModalProps) => {
  //Props
  const {isOpen, isSubmitting, onClose, onConfirm} = props;

  const classes = useDatepickerStyles();

  // Hooks
  const {isInBuyerContext} = useTenantInfo();

  // State
  const [referenceNo, setReferenceNo] = useState('');
  const [paymentDate, setPaymentDate] = useState<MaterialUiPickersDate>(null);

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  useEffect(() => {
    setPaymentDate(new Date());
  }, []);

  const handleApprove = () => {
    if (claimAndReturnsV272602) {
      const _paymentDate = paymentDate ? moment(paymentDate).format('MM-DD-YYYY') : undefined;
      onConfirm(referenceNo, _paymentDate);
    } else onConfirm();
  };

  const isValidDate = useMemo((): boolean => {
    return paymentDate ? moment(paymentDate).isSameOrBefore(moment(), 'day') : false;
  }, [paymentDate]);

  return (
    <ARDialogTemplate
      dialogProps={{size: 'sm', open: isOpen, className: '!min-w-[611px] !max-w-[611px]'}}
      isFullScreen
      header={
        <Typography className="font-light text-[#333333]" variant="h2">
          Approve claim
        </Typography>
      }
      body={
        <div className="my-12 flex flex-col items-center justify-center">
          <Typography className="text-center font-light text-black-800" variant="paragraph">
            Are you sure you want to approve the claim?
            <br />A credit note will be automatically generated.
          </Typography>
          <VisibleContent keyPath="accountsReceivable.claimRequests.approveModal.refNoAndDateInputs">
            {claimAndReturnsV272602 && (
              <div className="flex w-[80%] flex-row items-center justify-center gap-3 py-10">
                <div className="min-w-[220px]">
                  <div className="relative w-full min-w-[220px] font-poppins">
                    <Input
                      data-autoid={`txtReferenceNo`}
                      label="Reference no."
                      placeholder="Reference no."
                      value={referenceNo}
                      onChange={e => setReferenceNo(e?.target?.value)}
                    />
                  </div>
                </div>
                <div className={`${classes.payInvoiceText} mx-1`}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      maxDate={new Date()}
                      maxDateMessage="Credit note issue date can't be future date"
                      minDateMessage="Credit note issue date should be greater than today."
                      variant="inline"
                      id="dueDate"
                      name="dueDate"
                      inputVariant="outlined"
                      placeholder="Credit note issue date"
                      format="dd/MM/yyyy"
                      value={paymentDate}
                      InputAdornmentProps={{position: 'end'}}
                      PopoverProps={{className: `${classes.pickerDialog} !z-[9999]`}}
                      className={`${classes.paymentDate}`}
                      onChange={date => setPaymentDate(date)}
                      inputProps={{
                        'data-autoid': 'dtpBatchPaymentDate',
                      }}
                      label="Credit note issue date"
                      KeyboardButtonProps={
                        {
                          'aria-label': 'Credit note issue date',
                          'data-autoid': 'dtpPaymentDateIcon',
                        } as DatePickerKeyboardProps
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            )}
          </VisibleContent>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!isValidDate || isSubmitting}
            loading={isSubmitting}
            variant="filled"
            color="primary"
            data-autoid={`btnApproveClaim`}
            onClick={handleApprove}
          >
            Approve Claim
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
