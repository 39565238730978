import React from 'react';
import {Tooltip, Typography} from 'spenda-ui-react';
import EyeIcon from '../../assets/svg/quotes-management/EyeIcon';
import {PriceFormat} from '../../utils/formatter';

type ProfitMarginTooltipProps = {
  profitMargin: number;
  profitMarginPercentage: number;
};

export const ProfitMarginTooltip = (props: ProfitMarginTooltipProps) => {
  return (
    <Tooltip
      placement="left"
      className="w-40 rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-[0_0px_6px_0px_rgba(144,142,142,0.5)]"
      animate={{
        mount: {scale: 1, x: 0},
        unmount: {scale: 0, x: 25},
      }}
      content={
        <div>
          <div className="flex items-center justify-between">
            <Typography className="min-w-[80px]" variant="xsmall">
              Profit Margin (Ex):
            </Typography>
            <Typography variant="xsmall">{props.profitMarginPercentage.toFixed(2)}%</Typography>
          </div>
          <div className="flex justify-end">
            <Typography variant="xsmall">{PriceFormat(parseFloat(props.profitMargin.toFixed(2)))}</Typography>
          </div>

          <div className="flex items-center justify-between">
            <Typography className="min-w-[80px]" variant="xsmall">
              Profit Margin (Inc):
            </Typography>
            <Typography variant="xsmall">
              {props.profitMarginPercentage !== 0
                ? (props.profitMarginPercentage * 1.1).toFixed(2)
                : Number(0).toFixed(2)}
              %
            </Typography>
          </div>
          <div className="flex justify-end">
            <Typography variant="xsmall">
              {props.profitMargin !== 0
                ? PriceFormat(parseFloat((props.profitMargin * 1.1).toFixed(2)))
                : PriceFormat(0)}
            </Typography>
          </div>
        </div>
      }
    >
      <span>
        <EyeIcon className="cursor-pointer" />
      </span>
    </Tooltip>
  );
};

interface UnitMarginTooltipProps {
  costPrice: number;
  unitMargin: number;
  lineMargin: number;
}

export const UnitMarginTooltip = (props: UnitMarginTooltipProps) => {
  return (
    <Tooltip
      placement="bottom"
      className="rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-md"
      animate={{
        mount: {scale: 1, x: 0},
        unmount: {scale: 0, x: 25},
      }}
      content={
        <div>
          <div className="flex items-center justify-between">
            <Typography className="min-w-[80px]" variant="xsmall">
              Cost price (Exc):
            </Typography>
            <Typography variant="xsmall">{PriceFormat(parseFloat(props.costPrice.toFixed(2)))}</Typography>
          </div>
          <div className="flex items-center justify-between">
            <Typography className="min-w-[80px]" variant="xsmall">
              Unit margin:
            </Typography>
            <Typography variant="xsmall">{PriceFormat(parseFloat(props.unitMargin.toFixed(2)))}</Typography>
          </div>
          <div className="flex items-center justify-between">
            <Typography className="min-w-[80px]" variant="xsmall">
              Line margin:
            </Typography>
            <Typography variant="xsmall">{PriceFormat(parseFloat(props.lineMargin.toFixed(2)))}</Typography>
          </div>
        </div>
      }
    >
      <span>
        <EyeIcon className="cursor-pointer" />
      </span>
    </Tooltip>
  );
};
