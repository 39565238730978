import React, {useContext, useEffect, useState} from 'react';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';
import {Layout} from '../../components/layout/Layout';
import {BrushIcon} from '../../assets/svg';
import {CustomisationSettingsForm} from './CustomisationSettings';
import {APPaymentSetup} from '../../components/accountsPayableOnboarding/APPaymentSetup';
import {IntegrationContext} from '../../context/IntegrationContext';

export const QuoteSettings = () => {
  const {isAPFinancialAdaptorSetup, financialAdaptor} = useContext(IntegrationContext);
  const [currentActive, setCurrentActive] = useState<string>('customisation');
  const [tabData, setTabData] = useState([
    {
      label: 'Customisation',
      value: 'customisation',
      icon: <BrushIcon />,
      desc: <CustomisationSettingsForm />,
      heading: 'Customisation',
    },
  ]);

  const handleBucketChange = (val: string) => {
    setCurrentActive(val);
  };

  useEffect(() => {
    if (financialAdaptor?.Name && tabData.length === 1 && isAPFinancialAdaptorSetup()) {
      setTabData([
        ...tabData,
        {
          label: 'Clearing Accounts',
          value: 'clearingAccounts',
          icon: <BrushIcon />,
          desc: <APPaymentSetup isAPSettings={true} isQuotes />,
          heading: 'Setup your payment methods:',
        },
      ]);
    }
  }, [financialAdaptor]);

  const _serviceDetails = (
    <Tabs value={currentActive} orientation="vertical" className="h-full gap-2">
      <div className="h-[calc(100vh-120px)] min-w-[180px] bg-white">
        <TabsHeader
          className="w-full bg-transparent px-0 pt-4"
          indicatorProps={{
            className: 'bg-primary/10 rounded-none',
          }}
        >
          {tabData.map(({label, value, icon}) => (
            <Tab
              key={value}
              value={value}
              className="justify-start p-4 text-left font-medium"
              data-autoid={`tab${value}`}
              onClick={() => handleBucketChange(value)}
            >
              <div className="flex items-center justify-start gap-4" data-autoid={`tabContent${value}`}>
                {icon}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody data-autoid="tabsBody" className="h-full w-full rounded-md bg-white py-6 font-poppins shadow-md">
        {tabData.map(({value, desc, heading}) => (
          <TabPanel key={value} value={value} data-autoid={`tabPanel${value}`}>
            <h2
              className={`-mt-4 text-[22px] font-light text-spenda-primarytext`}
              data-autoid={`tabPanelHeader${value}`}
            >
              {heading}
            </h2>
            <div className="mt-2 w-full border border-[#ECECEC]" />
            <div
              className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2.5"
              data-autoid={`tabPanelContent${value}`}
            >
              {desc}
            </div>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <Layout leftPanel={_serviceDetails} splitWidthType={4} />
    </div>
  );
};
