import React, {useState} from 'react';
import {Button, IconButton, Typography, Upload} from 'spenda-ui-react';

import {ExcelSheetIcon, UploadCatalogueIcon} from '../../assets/svg';
import {AlertDialog, AlertDialogSlideV2} from './AlertDialogSlideV2';
import {FileType} from './QuoteAttachmentDialog';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {useInventoryContext} from '../../context/inventory-context/InventoryContext';
import {Toast} from '../../utils/Toast';
import {UploadType} from '../../model/constants/Constants';
import {createDownloadLink} from '../../utils/CommonUtils';
import {IStagingCatalogue} from '../../model/inventory/Inventory';

interface IDownloadAndUploadSheetProps {
  handleCancel: () => void;
  handleContinue: () => void;
}

export const UploadProductsDialog = (props: IDownloadAndUploadSheetProps) => {
  const [onDeleteSheet, setOnDeleteSheet] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addSheetToUpload, setAddSheetToUpload] = useState<FileType | null>(null);
  const {handleCancel, handleContinue} = props;
  const {bulkInventoryUpload, bulkVariantsUpload, checkCSVType, downloadTemplate, isLoading} = useInventoryAPI();
  const {setStagingInventory, setUploadTypeId} = useInventoryContext();

  const deleteFile = () => {
    setAddSheetToUpload(null);
    setOnDeleteSheet(false);
  };

  const handleFileChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newFile: FileType = {
        file: files[0],
        uri: URL.createObjectURL(files[0]),
      };
      setAddSheetToUpload(newFile);
    }
  };

  const UploadedSheetShow = ({name, onDelete}: {name: string; onDelete?: () => void}) => {
    return (
      <div className="upload-img-field flex !h-full w-[521px] items-center justify-center">
        <div className="flex !h-[133px] !w-auto items-center justify-center">
          <ExcelSheetIcon />,
        </div>
        <div className="overlay-img">
          <IconButton variant="text" className="ml-5" onClick={onDelete} data-autoid={`btnDelete${name}`}>
            <WidgetDeleteLogo />
          </IconButton>
        </div>
        <Typography
          variant="xsmall"
          className="absolute bottom-0 w-full truncate rounded-b-lg bg-[#212121] bg-opacity-30 px-2.5 text-center text-sm text-white"
        >
          {name}
        </Typography>
      </div>
    );
  };

  const handleUpload = async () => {
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      if (addSheetToUpload) formData.append('inventory', addSheetToUpload?.file, addSheetToUpload?.file?.name);
      const checkCSVTypeResponse = await checkCSVType(formData, {'content-type': 'multipart/form-data'});
      if (checkCSVTypeResponse.fileMetadata === 'None') {
        Toast.error('Invalid CSV Format');
      } else if (checkCSVTypeResponse.fileMetadata === 'Inventory') {
        const response = await bulkInventoryUpload(formData, {'content-type': 'multipart/form-data'});
        const inventoryItems: IStagingCatalogue[] = response?.inventoryItems?.map(item => ({
          ...item,
          isActive: true,
          type: 'catalogue',
        }));
        setUploadTypeId(UploadType.Inventory);
        setStagingInventory(inventoryItems);
        handleContinue();
      } else if (checkCSVTypeResponse.fileMetadata === 'Variants') {
        const response = await bulkVariantsUpload(formData, {'content-type': 'multipart/form-data'});
        const variantItems = response?.variantItems?.map(item => ({...item, isActive: true, type: 'variant'}));
        setUploadTypeId(UploadType.Variants);
        setStagingInventory(variantItems);
        handleContinue();
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const downloadExcel = (byteArray: BlobPart) => {
    const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url = window.URL.createObjectURL(blob);
    createDownloadLink(url, 'template.xlsx');
  };

  const handleDownloadTemplate = async () => {
    try {
      const base64String = await downloadTemplate();
      // Decode base64 to binary string
      let binaryString = atob(base64String);

      // Convert the binary string to a byte array (Uint8Array)
      let byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      downloadExcel(byteArray);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  return (
    <>
      <AlertDialogSlideV2
        dialogActionsAlignment="justify-between"
        dialogClassess="!w-full !min-w-[20%] !max-w-[800px]"
        dialogBodyClassess="flex flex-col items-center justify-start gap-y-8 p-10 text-black-800"
        headingTextSize="h1"
        title="Download & upload spreadsheet"
        actions={[
          {
            action: handleCancel,
            label: 'Cancel',
            variant: 'outlined',
          },
          {
            action: handleUpload,
            label: 'Continue',
            disabled: !addSheetToUpload || isSubmitting,
            loading: isSubmitting,
          },
        ]}
      >
        <Typography variant="small" className="!w-[450px] text-center">
          Please do not modify the columns or column headers, as this will lead to an unsuccessful input. Ensure that
          the product class and sales account match the existing classes/accounts in Spenda and are spelt correctly.
        </Typography>
        <Button color="success" data-autoid="btnDownload" onClick={handleDownloadTemplate} disabled={isLoading}>
          <div className="flex items-center gap-x-2">
            <UploadCatalogueIcon data-autoid="imgUpload" />
            Download Template
          </div>
        </Button>
        {!addSheetToUpload ? (
          <Upload
            className="h-[133px] !w-full !max-w-[521px] text-sm"
            label="Drop your spreadsheet here or click to upload"
            accept=".csv"
            onChange={handleFileChange}
            data-autoid="uploadSheet"
          />
        ) : (
          <>
            <UploadedSheetShow name={addSheetToUpload.file.name} onDelete={() => setOnDeleteSheet(true)} />
          </>
        )}
      </AlertDialogSlideV2>
      {onDeleteSheet && (
        <AlertDialog
          id="discardPopup"
          title="Remove Sheet"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setOnDeleteSheet(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => deleteFile(),
            },
          ]}
          content="Would you like to delete this sheet ?"
        />
      )}
    </>
  );
};
