import React, {useEffect, useState} from 'react';
import {Layout} from '../../../../components/layout/Layout';
import {StagingAreaHeader} from './StagingAreaHeader';
import {StagingAreaTable} from './StagingAreaTable';
import {isStagingCatalogue, useInventoryContext} from '../../../../context/inventory-context/InventoryContext';
import {useHistory, useLocation} from 'react-router-dom';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../../../routes/InventoryRoutes';
import {Toast} from '../../../../utils/Toast';
import {LoadingOverlayV1} from '../../../../components/ui/LoadingOverlayV1';
import {VariantStagingAreaTable} from './VariantStagingAreaTable';
import {useInventoryAPI} from '../../../../services/useInventoryAPI';

export const StagingArea = () => {
  const {stagingInventory, uploadTypeId} = useInventoryContext();
  const history = useHistory();
  const location = useLocation();

  const [selectedInventoryRows, setSelectedInventoryRows] = useState<number[]>([]);
  const [isCommiting, setIsCommiting] = useState<boolean>(false);
  const {deteteStagingData} = useInventoryAPI();

  const handleBrowserBack = async () => {
    await deteteStagingData(uploadTypeId);
  };

  useEffect(() => {
    const unlisten = history.listen(newLocation => {
      if (newLocation.pathname !== location.pathname) {
        handleBrowserBack();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, location.pathname]);

  useEffect(() => {
    if (!stagingInventory || !stagingInventory.length) {
      Toast.error('No records found. Please re-upload products.');
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    }
  }, [stagingInventory]);

  const _leftPanel = (
    <LoadingOverlayV1 isLoading={isCommiting}>
      <div className="h-full rounded-md bg-white p-2.5 text-black-800 shadow-[0_0_6px_0_#D3E5EF]">
        <StagingAreaHeader
          selectedInventoryRows={selectedInventoryRows}
          setSelectedInventoryRows={setSelectedInventoryRows}
          setIsCommiting={setIsCommiting}
        />
        {isStagingCatalogue(stagingInventory) ? (
          <StagingAreaTable
            selectedInventoryRows={selectedInventoryRows}
            setSelectedInventoryRows={setSelectedInventoryRows}
          />
        ) : (
          <VariantStagingAreaTable
            selectedInventoryRows={selectedInventoryRows}
            setSelectedInventoryRows={setSelectedInventoryRows}
          />
        )}
      </div>
    </LoadingOverlayV1>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={_leftPanel} splitWidthType={4} />
    </div>
  );
};
