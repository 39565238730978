import React, {useContext, useState} from 'react';
import {PayByLinkContext} from './PayByLinkContext';
import {LockIcon} from '../../assets/svg';
import {Button, Typography} from 'spenda-ui-react';
import {SpendaNoTagLogo} from '../../assets/svg/SpendaNoTagLogo';
import {PriceFormat} from '../../utils/formatter';
import moment from 'moment';
import {LimepayCheckout} from '../../components/limepayCheckout/LimepayCheckout';
import {LimepayPaymentStatus} from '../../components/dialog/LimepayPaymentStatus';
import {PaymentMethods, PaymentStatusType} from '../../model/constants/Constants';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {Toast} from '../../utils/Toast';
import {PayByLinkCNPAuthorize} from './PayByLinkCNPAuthorize';
import {IActiveSession} from '../../model/quotes/quotes';

interface IPayByLinkCNPInvoice {
  code: string;
}

function PayByLinkCNPInvoice(props: IPayByLinkCNPInvoice) {
  const {supplierDetails: pblSummary} = useContext(PayByLinkContext);
  const [showLimepayCheckout, setShowLimepayCheckout] = useState<boolean>(false);
  const [showLimepayPaymentStatus, setShowLimePaymentStatus] = useState<boolean>(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>(false);
  const [showAuthenticationDialog, setShowAuthenticationDialog] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [activeSession, setActiveSession] = useState<IActiveSession>();
  const [amountToPay, setAmountToPay] = useState<number>();

  const {requestApprovalCode, getUnmaskedInvoicePaymentInvitation, getInvoicePaymentInvitation} =
    useSupplierTransactionsAPI();

  const {setSupplierDetails} = useContext(PayByLinkContext);

  const refetchUnmaskedInvoiceDetails = async (approvalSessionGUID: string) => {
    const invoicePaymentInvitationResponse = await getUnmaskedInvoicePaymentInvitation(props.code, approvalSessionGUID);
    const supplierInviteDetails = invoicePaymentInvitationResponse!;
    setSupplierDetails(supplierInviteDetails);
  };

  const refreshData = async () => {
    if (activeSession?.approvalSessionGUID) {
      const invoicePaymentInvitationResponse = await getUnmaskedInvoicePaymentInvitation(
        props.code,
        activeSession.approvalSessionGUID,
      );
      setSupplierDetails(invoicePaymentInvitationResponse);
    } else {
      const invoicePaymentInvitationResponse = await getInvoicePaymentInvitation(props.code);
      setSupplierDetails(invoicePaymentInvitationResponse);
    }
  };

  const isPaid =
    (pblSummary?.invoice?.balance || 0) <= 0 &&
    pblSummary?.invoice?.status.toLocaleLowerCase() === PaymentStatusType.Paid.toLocaleLowerCase();

  const lines = pblSummary?.invoice?.summaryLines ? pblSummary?.invoice?.summaryLines : pblSummary?.invoice?.lines;

  const depositPayment = pblSummary?.invoice?.payments?.find(
    payment => payment.paymentMethodID === PaymentMethods.CustomerDeposit,
  );

  const CustomTableData = ({
    tableHead,
    tableCell,
    className,
  }: {
    tableHead: string;
    tableCell: string | React.ReactNode;
    className?: string;
  }) => {
    return (
      <>
        <td
          align="right"
          className={`w-[15%] border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:w-[12%] sm:text-xs ${className}`}
        >
          {tableHead}
        </td>
        <td
          align="right"
          className={`w-[15%] border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:w-[15%] sm:text-xs ${className}`}
        >
          {tableCell}
        </td>
      </>
    );
  };

  const handleLimepayClose = (showStatus: boolean, isSuccessful: boolean) => {
    setShowLimepayCheckout(false);
    setShowLimePaymentStatus(showStatus);
    setIsPaymentSuccessful(isSuccessful);
    isSuccessful && refreshData();
  };

  const handleRequestOTP = async () => {
    setIsRequesting(true);
    requestApprovalCode(props.code)
      .then(() => {
        Toast.info('OTP Sent');
        setShowAuthenticationDialog(true);
      })
      .catch(error => console.error('Error while requesting approval code', error))
      .finally(() => setIsRequesting(false));
  };

  return (
    <div className="flex h-full w-full flex-col justify-center lg:flex-row 2xl:flex-row">
      {showAuthenticationDialog ? (
        <PayByLinkCNPAuthorize
          onClose={() => setShowAuthenticationDialog(false)}
          handleResendCode={handleRequestOTP}
          abn={pblSummary?.supplierBusinessNumber}
          logo={pblSummary?.logoURL}
          supplierName={pblSummary?.supplierName}
          inviteCode={props.code}
          setActiveSession={setActiveSession}
          inviteSource={pblSummary?.contact?.emailAddress}
          refetchUnmaskedInvoiceDetails={refetchUnmaskedInvoiceDetails}
        />
      ) : (
        <>
          {!activeSession && (
            <div className="mr-5 w-full rounded-md bg-[#BBDBED] p-3 lg:mt-[100px] lg:h-[147px] lg:max-w-[231px]">
              <div className="mb-3 flex flex-row items-center">
                <LockIcon />
                <Typography className="ml-2 font-medium" variant="small">
                  Security validation alert
                </Typography>
              </div>
              <Typography className="mb-3" variant="xsmall">
                For security purposes, we've minimised the visibility of your personal information. To unveil it, please
                click on ‘Reveal’.
              </Typography>
              <div className="flex justify-center">
                <button
                  disabled={isRequesting}
                  color="primary"
                  data-autoid={`btnReveal`}
                  className="align-center h-[27px] w-[65px] justify-center rounded-md border-[1px] border-[#1C76AD] bg-white text-[10px] font-semibold text-primary"
                  onClick={handleRequestOTP}
                >
                  Reveal
                </button>
              </div>
            </div>
          )}
          <div className={`h-[520px] w-full max-w-[750px] bg-white !text-[#333333] sm:h-[730px] 2xl:mr-32`}>
            <header
              className={`after:content-[' '] after:block; after:rounded-tl-0 after:rounded-tr-0 after:rounded-br-0 relative flex h-full flex-col
        rounded-[4px] rounded-tr-[40px] bg-white shadow-[0_3px_10px_rgb(227,238,245,1)] after:absolute after:right-0 after:top-0 
       after:z-0 after:w-[0px] after:overflow-y-auto after:rounded-bl-xl after:border-b-[0px] after:border-l-[30px] after:border-r-[0px] after:border-t-[30px]
       after:border-solid after:border-b-[#e1e1e1] after:border-l-[#e1e1e1] after:border-r-[#fff] after:border-t-[#F4F8FB] after:bg-[#E1E1E1]`}
            >
              <div className={`mb-5 px-5 sm:mx-2`}>
                <div className={`mt-5 flex justify-between`}>
                  <div className="w-full max-w-[160px] pt-2 sm:max-w-max">
                    {pblSummary?.logoURL ? (
                      <img src={pblSummary?.logoURL} alt="Preview Logo" className={`h-[100px] w-[100px]`} />
                    ) : (
                      <SpendaNoTagLogo height="85px" className="sm:w-[200px]" />
                    )}
                  </div>
                  <div className="pt-3">
                    <Typography className="text-sm font-semibold sm:text-xl">Sales Invoice</Typography>
                    <div className="flex justify-between">
                      {isPaid ? (
                        <div
                          className={`mt-2 flex w-full max-w-max items-center justify-center rounded-[10px] border-[3px] border-[#E9F3EF] px-5 sm:h-[61px] sm:max-w-[177px]`}
                        >
                          <Typography className={`text-base font-semibold text-[#3C9F78]`}>Paid</Typography>
                        </div>
                      ) : (
                        <div
                          className={`mt-2 flex w-full max-w-max flex-col items-center rounded-[10px] border-[3px] border-[#E1E1E1] px-5 py-1 sm:h-[61px] sm:max-w-[177px] sm:px-0 sm:pb-0 sm:pt-2`}
                        >
                          <Typography className={`text-[6px] font-medium sm:text-xs`}>Amount Due:</Typography>
                          <Typography className={`text-xs font-semibold sm:text-base`}>
                            {PriceFormat(pblSummary?.invoiceBalance, '$', 2)}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div data-autoid="txtSupplierName" className={`mb-1 flex flex-col text-xl`}>
                    <Typography className="text-sm sm:text-xl">{pblSummary?.supplierName}</Typography>
                    <Typography className="text-sm sm:text-sm">ABN: {pblSummary?.supplierBusinessNumber}</Typography>
                    <Typography className="text-sm sm:text-sm">Email: {pblSummary?.supplierEmail}</Typography>
                    <Typography className="text-sm sm:text-sm">Web: {pblSummary?.webSiteURL}</Typography>
                  </div>
                  <div>
                    <div className="mb-1 flex flex-col items-end justify-end pt-5">
                      <Typography className="pt-1 text-[6px] sm:text-sm">
                        Invoice Date: {moment(pblSummary?.invoice?.invoiceDate || new Date()).format('DD MMM YYYY')}
                      </Typography>
                      <Typography className="text-[6px] sm:text-sm">
                        Due Date: {moment(pblSummary?.invoice?.dueDate || new Date()).format('DD MMM YYYY')}
                      </Typography>
                      <Typography className="text-[6px] sm:text-sm">Ref #: {pblSummary?.invoice?.refNumber}</Typography>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="flex flex-row justify-between pb-1">
                  <div className="my-1 flex flex-col">
                    <Typography variant={'h3'} className="pb-2">
                      Bill To:
                    </Typography>
                    <div>
                      <Typography className="text-[6px] sm:text-sm">
                        {!pblSummary?.contact?.isIndividual
                          ? pblSummary?.contact?.companyName
                          : `${pblSummary?.contact?.firstName} ${pblSummary?.contact?.lastName}`}
                      </Typography>
                      <Typography className="text-[6px] sm:text-sm">ABN: {pblSummary?.contact?.abn}</Typography>
                      <Typography className="text-[6px] sm:text-sm">
                        Email: {pblSummary?.contact?.emailAddress}
                      </Typography>
                    </div>
                  </div>
                  <div className="my-1 flex flex-col">
                    <Typography variant={'h3'} className="pb-2 text-right">
                      Shipping:
                    </Typography>
                    <div>
                      {pblSummary?.invoice?.shipStreetAddress != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.shipStreetAddress}
                        </Typography>
                      )}
                      {pblSummary?.invoice?.shipCity != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.shipCity + ' ' + pblSummary?.invoice?.shipState}
                        </Typography>
                      )}
                      {pblSummary?.invoice?.shipCountry != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.shipCountry + ' ' + pblSummary?.invoice?.shipPostCode}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div className="my-1 flex flex-col">
                    <Typography variant={'h3'} className="pb-2 text-right">
                      Billing:
                    </Typography>
                    <div>
                      {pblSummary?.invoice?.billStreetAddress != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.billStreetAddress}
                        </Typography>
                      )}
                      {pblSummary?.invoice?.billCity != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.billCity + ' ' + pblSummary?.invoice?.billState}
                        </Typography>
                      )}
                      {pblSummary?.invoice?.billCountry != null && (
                        <Typography className="text-right text-[6px] sm:text-sm">
                          {pblSummary?.invoice?.billCountry + ' ' + pblSummary?.invoice?.billPostCode}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div className="max-h-[128px] overflow-y-auto">
                  <table className="w-full text-left">
                    <thead className="bg-[#E1E1E1]">
                      <tr>
                        <th className="py-1.5 pl-[22px] pr-2 text-[6px] font-medium text-black-800 sm:text-xs md:pl-[38px]">
                          Description
                        </th>
                        {pblSummary?.isShowQuantity && (
                          <>
                            <th align="center" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                              Qty
                            </th>
                            <th align="right" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                              Unit Price
                            </th>
                          </>
                        )}
                        <th align="right" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lines?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className={`border-b-2 border-[#E1E1E1] py-1 pl-2 pr-2 sm:pl-4 ${item.quantity !== null ? 'w-3/6 sm:w-3/5' : 'w-[85%]'}`}
                            >
                              <div className="flex">
                                <Typography className="min-w-[10px] text-[6px] font-medium sm:text-xs">
                                  {index + 1}.
                                </Typography>
                                <Typography className="!line-clamp-1 pl-1 text-[6px] font-medium sm:pl-3 sm:text-xs">
                                  {item?.description}
                                </Typography>
                              </div>
                            </td>
                            {pblSummary?.isShowQuantity && (
                              <>
                                <td
                                  width={'10%'}
                                  align="center"
                                  className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                                >
                                  {item?.quantity} {item?.uoM}
                                </td>
                                <td
                                  width={'15%'}
                                  align="right"
                                  className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                                >
                                  {PriceFormat(item?.sellPriceEx, '$', 2)}
                                </td>
                              </>
                            )}
                            <td
                              width={'15%'}
                              align="right"
                              className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                            >
                              {PriceFormat(item?.lineTotalEx, '$', 2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <table className="mb-8 w-full">
                  <tbody>
                    <tr>
                      <td rowSpan={6} className={'w-[70%] sm:w-[75%]'}></td>
                      <CustomTableData
                        tableHead="Sub Total"
                        tableCell={PriceFormat(pblSummary?.invoice?.totalEx, '$', 2)}
                      />
                    </tr>
                    <tr>
                      <CustomTableData tableHead="Tax" tableCell={PriceFormat(pblSummary?.invoice?.totalTax, '$', 2)} />
                    </tr>
                    <tr>
                      <CustomTableData
                        tableHead="Total"
                        tableCell={PriceFormat(pblSummary?.invoice?.totalInc, '$', 2)}
                        className="bg-[#E1E1E1] !font-bold"
                      />
                    </tr>
                    {depositPayment && (
                      <tr>
                        <CustomTableData
                          tableHead="Deposit Paid"
                          tableCell={`${PriceFormat(depositPayment?.appliedAmount)}`}
                          className="bg-[#E1E1E1] !font-bold "
                        />
                      </tr>
                    )}
                    {depositPayment && (
                      <tr>
                        <CustomTableData
                          tableHead="Amount Paid"
                          tableCell={`${PriceFormat((pblSummary?.invoice?.totalInc || 0) - ((depositPayment?.appliedAmount || 0) + (pblSummary?.invoice?.balance || 0)))}`}
                          className="bg-[#E1E1E1] !font-bold "
                        />
                      </tr>
                    )}
                    <tr>
                      <CustomTableData
                        tableHead="Balance"
                        tableCell={PriceFormat(pblSummary?.invoice?.balance, '$', 2)}
                        className="bg-[#E1E1E1] !font-bold"
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              {!isPaid && (
                <div className={`absolute bottom-2.5 left-2.5 z-10 w-[calc(100%-20px)] rounded-md bg-[#ECECEC] p-2.5`}>
                  <div className={`flex items-center justify-center`}>
                    <Button
                      className="h-9 text-sm md:h-10 md:text-base"
                      variant="filled"
                      color="primary"
                      data-autoid={`btnPay`}
                      onClick={() => {
                        setShowLimepayCheckout(true);
                        setAmountToPay(pblSummary?.invoice?.balance);
                      }}
                    >
                      Pay
                    </Button>
                  </div>
                </div>
              )}
            </header>
          </div>
          {showLimepayCheckout && amountToPay && !isPaid && (
            <LimepayCheckout
              open={showLimepayCheckout}
              inviteCode={props.code}
              amountToPay={amountToPay!}
              handleLimepayClose={handleLimepayClose}
            />
          )}
          {showLimepayPaymentStatus && (
            <LimepayPaymentStatus
              open={showLimepayPaymentStatus}
              isSuccessful={isPaymentSuccessful}
              handleClose={setShowLimePaymentStatus}
              amountToPay={amountToPay!}
            />
          )}
        </>
      )}
    </div>
  );
}

export default PayByLinkCNPInvoice;
