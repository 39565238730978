import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {SAutocomplete} from '../inputs/SAutocomplete';
import {Inventory} from '../../model/inventory/Inventory';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {IPagedActionResults} from '../../model/ActionResults';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import PostingInventoryIcon from '../../assets/svg/accounts-payable/PostingInventoryIcon';
import AppContext from '../../context/app/appContext';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import _ from 'lodash';

interface IPostingInventorySelection {
  disableField?: boolean;
  supplierTable?: boolean;
  selectedPostingInventory: Partial<Inventory>;
  setSelectedPostingInventory: Dispatch<SetStateAction<Partial<Inventory>>>;
  isShowRedirectIcon: boolean;
  onExpenseAccountError?: (error: string) => void;
}

const usePostingInventorySelectionStyle = makeStyles({
  inventoryField: {
    '& .Mui-disabled.Mui-disabled .MuiInputAdornment-root': {
      display: 'none',
    },
  },
});

export const PostingInventorySelection = (props: IPostingInventorySelection) => {
  const {
    disableField,
    supplierTable,
    selectedPostingInventory,
    isShowRedirectIcon,
    setSelectedPostingInventory,
    onExpenseAccountError,
  } = props;

  const classes = usePostingInventorySelectionStyle();
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const {isLoading: isInventoryLoading, listInventory} = useInventoryAPI();
  const [suggestions, setSuggestions] = useState<Partial<Inventory>[]>([]);
  const [expenseAccountError, setExpenseAccountError] = useState<string>('');

  const getInventoryList = (
    newValue: string = '',
    setPostingInventory: boolean = false,
    postingInventoryID?: string | undefined,
  ) => {
    listInventory({
      MaxResults: 15,
      ...{isPurchased: true, isSystem: false},
      ...(newValue ? {Search: newValue} : {}),
    }).then((res: IPagedActionResults<Inventory[]>) => {
      setSuggestions(res?.Value || []);
      if (setPostingInventory) {
        const val = res?.Value?.find(item => item.ID === postingInventoryID);
        if (val) setSelectedPostingInventory(val);
      }
    });
  };

  useEffect(() => {
    if (
      selectedPostingInventory &&
      selectedPostingInventory.InventoryCode &&
      !selectedPostingInventory.ExpenseAccount
    ) {
      setExpenseAccountError("Set Expense Code, else your batch won't import to Financial");
    } else if (
      !selectedPostingInventory.InventoryCode &&
      !tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryExpenseAccountCode
    )
      setExpenseAccountError("Set Expense Code, else your batch won't import to Financial");
    else {
      setExpenseAccountError('');
    }
  }, [selectedPostingInventory]);

  useEffect(() => {
    onExpenseAccountError?.(expenseAccountError);
  }, [expenseAccountError]);

  return (
    <>
      <div>
        <div className={`${classes.inventoryField} flex flex-row items-center`}>
          <SAutocomplete<Partial<Inventory>>
            isT2TSupplierModule
            async
            autoFocus={false}
            styles={{marginTop: '0rem'}}
            size={'small'}
            loading={isInventoryLoading}
            placeholder={disableField || supplierTable ? 'Spenda generic inventory' : 'Select'}
            clearClick={() => {
              setSelectedPostingInventory({});
            }}
            suggestions={suggestions}
            selected={
              selectedPostingInventory && !_.isEmpty(selectedPostingInventory) ? selectedPostingInventory : null
            }
            getSuggestions={getInventoryList}
            disabled={disableField}
            renderSuggestion={(item: Partial<Inventory>) => {
              return (
                <p className={'cursor-pointer font-poppins text-spenda-primarytext'}>
                  {item.InventoryCode} {item.ShortDescription ? item.ShortDescription : ''}
                </p>
              );
            }}
            getSuggestionValue={(suggestion: Partial<Inventory>) => {
              return `${suggestion.InventoryCode} ${suggestion.ShortDescription ? suggestion.ShortDescription : ''}`;
            }}
            onSearchChange={(value: string) => {
              getInventoryList(value);
            }}
            onSuggestionSelected={(
              _event: React.FormEvent<any>,
              _selected: SuggestionSelectedEventData<Partial<Inventory>>,
            ) => {
              setSuggestions([_selected.suggestion]);
              setSelectedPostingInventory(_selected.suggestion);
            }}
          />

          {!disableField && isShowRedirectIcon && (
            <PostingInventoryIcon
              className={` ml-2.5 min-w-[18px] cursor-pointer`}
              data-autoid={'imgPostingInventory'}
              onClick={() => {
                if (!selectedPostingInventory || !selectedPostingInventory.ID) {
                  tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId &&
                    history.push(
                      `/inventory/products/edit/${tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId}?tab=1`,
                    );
                } else {
                  history.push(`/inventory/products/edit/${selectedPostingInventory.ID}`);
                }
              }}
            />
          )}
        </div>
        {expenseAccountError && !disableField && (
          <span className="flex justify-end pt-2 font-poppins text-xs text-spenda-error">{expenseAccountError}</span>
        )}
      </div>
    </>
  );
};
