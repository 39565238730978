import React, {memo, useEffect, useState} from 'react';
import {ARDialogTemplate} from './ARDialogTemplate';
import {Visibility} from '@material-ui/icons';
import {DownloadIcon} from '../../assets/svg/DownloadIcon';
import CrossIcon from '../../assets/svg/Cross';

import ClaimViewUpdatedImagesDialog from './ClaimViewUpdatedImagesDialog';
import Dropzone, {FileRejection} from 'react-dropzone';
import {uniq} from 'lodash';
import {Toast} from '../../utils/Toast';
import {IClaimRequestLineItemsFormValues} from '../../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import {
  IClaimRequestDetailsResponse,
  IUploadedClaimsAndReturnResponseObject,
} from '../../model/claims/ClaimsAndReturns';
import {AttachmentTypeEnum, ClaimsAndReturnsStatus} from '../../model/constants/Constants';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Badge, Button, Typography} from 'spenda-ui-react';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import AddIcon from '../../assets/svg/accounts-payable/AddIcon';
import {IDownloadAllAttachmentsPayload} from '../../model/accounts-receivable/AccountsReceivable';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {createDownloadLink} from '../../utils/CommonUtils';
import clsx from 'clsx';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

export interface IEditEvidenceDialogProps {
  isViewOnly?: boolean;
  showModal: boolean;
  canUploadAttachment?: boolean;
  handleClose: () => void;
  lineItemData?: IClaimRequestLineItemsFormValues;
  handleSaveForAttachmentAsDraft?: () => Promise<IClaimRequestDetailsResponse | undefined>;
  accountCustomerId?: number;
  linkedSupplierId?: number;
  claimRequestDetails?: IClaimRequestDetailsResponse;
  handleRefetchClaimDetails?: (crId: number) => void;
  isLoading?: boolean;
  claimRequestId?: number;
}

export interface IUploadedAttachment extends IUploadedClaimsAndReturnResponseObject {
  invoiceLineID?: number | null;
  description?: string;
  code?: string;
  quantity?: number;
  price?: number;
  reason?: string;
  action?: string;
  claimRequestAttachmentID?: number;
  buyerNote?: string;
  buyerNoteCreatedByUserName?: string;
  buyerNoteCreatedDateTime_utc?: string;
  buyerNoteModifiedByUserID?: number;
  buyerNoteModifiedByUserName?: string;
  buyerNoteModifiedDateTime_utc?: string;
  supplierNote?: string;
  supplierNoteCreatedByUserName?: string;
  supplierNoteCreatedDateTime_utc?: string;
  supplierNoteModifiedByUserID?: number;
  supplierNoteModifiedByUserName?: string;
  supplierNoteModifiedDateTime_utc?: string;
}

const EditEvidenceDialog = (props: IEditEvidenceDialogProps) => {
  // Props
  const {
    isViewOnly,
    showModal,
    canUploadAttachment,
    lineItemData,
    handleClose,
    handleSaveForAttachmentAsDraft,
    accountCustomerId,
    linkedSupplierId,
    claimRequestDetails,
    handleRefetchClaimDetails,
    isLoading,
    claimRequestId,
  } = props;

  // States
  const [isShowUpdatedImagesDialog, setIsShowUpdatedImagesDialog] = useState(false);
  const [clickedFileIndex, setClickedFileIndex] = useState<number>();
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<File[]>();
  const [uploadedAttachments, setUploadedAttachments] = useState<IUploadedAttachment[]>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  // APIs
  const {getUploadedAttachments, deleteAttachment} = useAttachmentsAPI();
  const {downloadAllAttachments, isLoading: isDownloadAllAttachmentLoading} = useAttachmentsAPI();

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // Constant
  const isAttachmentFetching = isFetching || isLoading;

  useEffect(() => {
    setIsFetching(true);
    fetchUploadedAttachments(claimRequestDetails);
  }, [claimRequestDetails]);

  const fetchUploadedAttachments = async (claimsReqRes?: IClaimRequestDetailsResponse) => {
    try {
      let attachmentGuids: string[] | undefined = [];
      if (isViewOnly) {
        claimsReqRes?.lines?.forEach(l =>
          l?.claimRequestAttachments?.forEach(a => {
            attachmentGuids?.push(a?.claimRequestAttachmentGUID);
          }),
        );
      } else {
        attachmentGuids = claimsReqRes?.lines
          ?.find(l => l?.claimRequestLineID === lineItemData?.claimRequestLineID)
          ?.claimRequestAttachments?.map(ca => ca.claimRequestAttachmentGUID);
      }

      if (!attachmentGuids?.length) {
        setUploadedAttachments(undefined);
        return;
      }

      const query = {
        guids: attachmentGuids?.toString(),
      };

      const attachments = await getUploadedAttachments(AttachmentTypeEnum?.ClaimsAndReturnsClaimRequest, query);

      let attachmentToView: IUploadedAttachment[] = [];
      attachments?.value?.responseObject?.forEach(a => {
        const claimLineDetails = claimsReqRes?.lines?.find(l => l?.claimRequestLineID === a?.claimRequestLineID);
        const obj = {
          ...a,
          invoiceLineID: claimLineDetails?.invoiceLineID,
          price: claimLineDetails?.sellPriceEx,
          quantity: claimLineDetails?.quantity,
          reason: claimLineDetails?.reason,
          action: claimLineDetails?.action,
          code: claimLineDetails?.code,
          description: claimLineDetails?.description,
        };

        attachmentToView?.push(obj);
      });

      setUploadedAttachments(attachmentToView);
    } catch {
    } finally {
      setIsFetching(false);
    }
  };

  const handleDeleteAttachment = async (attachmentGUID: string) => {
    try {
      const deleteResponse = await deleteAttachment(AttachmentTypeEnum?.ClaimsAndReturnsClaimRequest, attachmentGUID);
      const {claimRequestID} = claimRequestDetails || {};

      if (deleteResponse.isSuccess && claimRequestID) {
        handleRefetchClaimDetails?.(claimRequestID);
      }
    } catch {}
  };

  // Functions
  const handleRejectedFile = (rejectedFile: FileRejection[]) => {
    let errorMsg: string[] = [];

    rejectedFile?.map(r => {
      const errorCode = r?.errors[0].code;
      const errorMessage = r?.errors[0].message;

      switch (errorCode) {
        case 'file-invalid-type':
          errorMsg.push(`${r?.file?.name}, ${errorMessage}`);
          break;
        case 'file-too-large':
          errorMsg.push(`${r?.file?.name} File is larger than 5MB`);
          break;
        case 'too-many-files':
          errorMsg.push(`You can upload 4 files at a time`);
          break;
        default:
          errorMsg.push(`Something went wrong`);
          break;
      }
    });

    const uniqErrors = uniq(errorMsg);

    uniqErrors?.map(e => Toast.info(e));
  };

  const handleAcceptedFile = (files: File[]) => {
    setAddedFilesToUpload(files);
    setIsShowUpdatedImagesDialog(true);
  };

  const handleSuccessfullyUploaded = async (crId: number) => {
    setIsShowUpdatedImagesDialog(false);
    setAddedFilesToUpload(undefined);
    setUploadedAttachments(undefined);
    await handleRefetchClaimDetails?.(crId);
  };

  const handleImageDialogClose = () => {
    setIsShowUpdatedImagesDialog(false);
    setAddedFilesToUpload(undefined);
  };

  const handleDownloadAllAttachments = async () => {
    try {
      if (!accountCustomerId && !linkedSupplierId && !claimRequestId) return;

      const payload: Partial<IDownloadAllAttachmentsPayload> = {
        data: {
          accountCustomerID: isInSupplierContext ? accountCustomerId : undefined,
          linkedSupplierID: isInBuyerContext ? linkedSupplierId : undefined,
          documentID: claimRequestId ?? 0,
          documentLineID: 0,
        },
        guids: null,
      };

      const response = await downloadAllAttachments(payload);
      const blobData = new Blob([response?.data], {type: response?.headers?.contenttype});
      const createdFile = new File([blobData], response?.headers?.filename, {
        type: response?.headers?.contenttype,
      });
      const url = URL.createObjectURL(createdFile);
      createDownloadLink(url, response?.headers?.filename);
    } catch (err) {
      if (err instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        const jsonError = JSON.parse(decoder.decode(err as ArrayBuffer));
        Toast.error(jsonError?.errors?.[0]?.message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  const header = (
    <p className=" font-poppins text-xl font-light text-[#333333]">
      {isViewOnly
        ? 'Evidence'
        : isAttachmentFetching
          ? 'Loading...'
          : uploadedAttachments?.length
            ? 'Edit Evidence'
            : 'Add Evidence'}
    </p>
  );

  const body = (
    <>
      {isAttachmentFetching ? (
        <div className="!min-h-[200px] w-full !min-w-[560px]" data-autoid="LoadingIndicator">
          <LoadingIndicator size="md" isLoading={isAttachmentFetching} />
        </div>
      ) : (
        <div className="mb-12 flex w-full !min-w-[560px] flex-col items-center">
          <Typography
            className=" mb-7 mt-2.5 text-center font-medium text-gray-600"
            data-autoid="lblAttachmentCount"
            variant="xsmall"
          >
            {uploadedAttachments?.length ? `${uploadedAttachments?.length} evidence found` : 'No evidence'}
          </Typography>
          <div className="flex max-h-[400px] w-full flex-row flex-wrap gap-5 overflow-auto px-[22px] pt-2">
            {canUploadAttachment && !isViewOnly && (
              <Dropzone
                noKeyboard
                accept={['.jpeg', '.jpg', '.png']}
                maxFiles={4}
                maxSize={6242880}
                multiple={true}
                onDropAccepted={acceptedFile => handleAcceptedFile(acceptedFile)}
                onDropRejected={rejectedFile => handleRejectedFile(rejectedFile)}
              >
                {({getRootProps, getInputProps}) => (
                  <div
                    {...getRootProps()}
                    className="relative flex cursor-pointer flex-col items-center justify-center"
                  >
                    <input {...getInputProps()} />
                    <div className="flex h-[90px] w-[110px] flex-row items-center justify-center rounded-[6px] bg-[hsl(var(--primary-header))]">
                      <AddIcon className="w-9" data-autoid="imgAddAttachment" />
                    </div>
                    <Typography
                      className="mt-[6px] !max-w-[110px] text-center font-semibold text-gray-600"
                      variant="xsmall"
                    >
                      Add attachment
                    </Typography>
                  </div>
                )}
              </Dropzone>
            )}
            {uploadedAttachments &&
              uploadedAttachments?.map((ua, i) => (
                <div key={ua?.uri} className="relative flex max-h-[110px] flex-col items-center justify-center">
                  <div
                    className={`absolute left-0 top-0 flex h-[110px] max-h-[110px] w-[110px] flex-row items-center justify-center rounded-[6px] bg-[#00000040] opacity-0 hover:opacity-100`}
                  >
                    <div className="flex flex-row gap-x-5">
                      <Visibility
                        data-autoid="btnViewEvidence"
                        className="cursor-pointer !fill-[#fff]"
                        onClick={() => {
                          setClickedFileIndex(i);
                          setIsShowUpdatedImagesDialog(true);
                        }}
                      />
                      <a data-autoid="btnDownloadEvidence" href={ua?.uri} download>
                        <DownloadIcon data-autoid="EditEvidenceDialogDownloadIcon" className="cursor-pointer" />
                      </a>
                    </div>
                    {!isViewOnly && claimRequestDetails?.status === ClaimsAndReturnsStatus.Draft && (
                      <div
                        className="absolute -right-[14px] -top-[6px] flex h-[20px] w-[20px] cursor-pointer items-center gap-4 rounded-full"
                        onClick={() => handleDeleteAttachment(ua?.claimRequestAttachmentGUID)}
                        data-autoid="btnRemoveEvidence"
                      >
                        <Badge
                          className="h-[20px] w-[20px] bg-[#ffcabd]"
                          color="white"
                          placement="top-end"
                          content={<CrossIcon data-autoid="CrossIcon" className="h-[8px] w-[8px] !fill-[#C55D44]" />}
                        />
                      </div>
                    )}
                  </div>
                  <img
                    loading="lazy"
                    src={ua?.uri}
                    alt="sample"
                    width="110px"
                    height="90px"
                    className="h-[110px] w-[110px] rounded-[6px] bg-cover bg-clip-border bg-center bg-no-repeat bg-origin-border"
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      {/* Modals */}
      {isShowUpdatedImagesDialog && (
        <ClaimViewUpdatedImagesDialog
          key={lineItemData?.claimRequestLineID}
          handleSaveForAttachmentAsDraft={handleSaveForAttachmentAsDraft}
          lineItemData={lineItemData}
          addedFilesToUpload={addedFilesToUpload}
          showModal={isShowUpdatedImagesDialog}
          onSuccess={handleSuccessfullyUploaded}
          handleClose={handleImageDialogClose}
          accountCustomerId={accountCustomerId}
          linkedSupplierId={linkedSupplierId}
          uploadedAttachments={uploadedAttachments}
          clickedImageIndex={clickedFileIndex}
          setUploadedAttachments={setUploadedAttachments}
        />
      )}
    </>
  );

  const footer = (
    <div
      className={clsx(
        'flex h-[60px] !w-full flex-row items-center ',
        isViewOnly && claimAndReturnsV272602 ? 'justify-between' : 'justify-end',
      )}
    >
      {isViewOnly && claimAndReturnsV272602 && (
        <Button
          data-autoid="btnDownloadAll"
          variant="filled"
          color="primary"
          onClick={handleDownloadAllAttachments}
          disabled={!uploadedAttachments?.length}
          loading={isDownloadAllAttachmentLoading}
        >
          Download All
        </Button>
      )}
      <Button
        data-autoid="btnCancel"
        variant="outlined"
        color="primary"
        onClick={() => handleClose()}
        className="bg-white"
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <>
      <ARDialogTemplate
        dialogProps={{size: 'sm', open: showModal, className: '!min-w-[584px]'}}
        isFullScreen
        footerClass="h-[60px]"
        header={header}
        body={body}
        footer={footer}
        handleClose={() => handleClose()}
      />
    </>
  );
};

export default memo(EditEvidenceDialog);
