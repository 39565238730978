import React from 'react';
import {Popover, PopoverContent, PopoverHandler, Typography} from 'spenda-ui-react';
import moment from 'moment';

import CalendarIcon from '../../assets/svg/Calendar';
import {IconCall, IconDocument, IconEnvelope} from '../../assets/svg';
import {
  IQuoteLinkedTran,
  ISchedulerData,
  RequiresAttentionStatus,
  ServiceJobStatus,
} from '../../model/service-management/serviceJob';
import {truncateTwoDecimals} from '../../utils/formatter';

export interface TemplateWeekViewSchedulerProps {
  job: ISchedulerData;
  onEditJobClick?: () => void;
  onClickOfLinkedTransaction?: (transaction: IQuoteLinkedTran) => void;
}

export const TemplateWeekViewScheduler = ({
  onEditJobClick,
  onClickOfLinkedTransaction,
  job,
}: TemplateWeekViewSchedulerProps) => {
  const statusColor = (status?: ServiceJobStatus) => {
    switch (status) {
      case ServiceJobStatus.Open:
        return 'border-l-primary bg-primary/15 [&>h3]:text-primary';
      case ServiceJobStatus.ScheduledTentative:
      case ServiceJobStatus.Scheduled:
        // cool-purple
        return 'bg-[#8178CF]/10 border-l-[#8178CF] [&>h3]:text-[#8178CF]';
      case ServiceJobStatus.AwaitingStock:
      case ServiceJobStatus.Reschedule:
      case ServiceJobStatus.Paused:
      case ServiceJobStatus.Closed:
        return 'bg-warning/15 border-l-warning [&>h3]:text-warning';
      case ServiceJobStatus.Completed:
        return 'bg-success/10 border-l-success [&>h3]:text-success';
      case ServiceJobStatus.InProgress:
        // warm-purple
        return 'bg-[#9D5CB3]/10 border-l-[#9D5CB3] [&>h3]:text-[#9D5CB3]';
      default:
        return 'bg-[#8178CF]/10 border-l-[#8178CF] [&>h3]:text-[#8178CF]';
    }
  };

  const popoverStatusColor = (status?: ServiceJobStatus) => {
    switch (status) {
      case ServiceJobStatus.Open:
        return 'bg-primary/15 [&>p]:text-primary';
      case ServiceJobStatus.ScheduledTentative:
      case ServiceJobStatus.Scheduled:
        return 'bg-[#8178CF]/10 [&>p]:text-[#8178CF]';
      case ServiceJobStatus.AwaitingStock:
      case ServiceJobStatus.Reschedule:
      case ServiceJobStatus.Paused:
      case ServiceJobStatus.Closed:
        return 'bg-warning/15 [&>p]:text-warning';
      case ServiceJobStatus.Completed:
        return 'bg-success/10 [&>p]:text-success';
      case ServiceJobStatus.InProgress:
        return 'bg-[#9D5CB3]/10 [&>p]:text-[#9D5CB3]';
      default:
        return 'bg-[#8178CF]/10 [&>p]:text-[#8178CF]';
    }
  };

  const status = job?.isWaitingForStock
    ? ServiceJobStatus.AwaitingStock
    : !job?.isWaitingForStock && !job.isSent
      ? ServiceJobStatus.ScheduledTentative
      : job.status;

  const bookingHours = truncateTwoDecimals(moment(job.endDate).diff(moment(job.startDate), 'hours', true));

  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popover placement="right">
        <PopoverHandler>
          <div
            className={`relative cursor-pointer overflow-hidden rounded-md border-l-2 px-2.5 py-1 ${statusColor(status)}`}
          >
            <Typography
              variant="h3"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onEditJobClick?.();
              }}
              className="w-fit pb-1 text-sm font-medium hover:underline"
            >
              {job.title}
            </Typography>
            <Typography className="truncate pb-1 text-xs font-medium text-black-300">
              {job.suburb} {job.suburb && '•'} {moment(job.startDate).format('HH:mm')}-
              {moment(job.endDate).format('HH:mm')} • {bookingHours}h
            </Typography>{' '}
          </div>
        </PopoverHandler>
        <PopoverContent className="z-10 overflow-hidden p-0 !shadow-[0_0px_12px_0_#D3E5EF]">
          <div className="w-[300px]">
            <Typography className="py-2.5 pl-3 text-sm font-medium text-black-800">
              {job?.customerName ?? '--'}
            </Typography>
            <hr className="border-[#ECECEC]" />
            <ul className="px-1.5 [&>li]:flex [&>li]:items-center [&>li]:gap-2.5 [&>li]:border-b [&>li]:border-[#ECECEC] [&>li]:px-1.5 [&>li]:py-3 [&>li]:text-spenda-labeltext">
              {job?.description && (
                <li>
                  <Typography className="text-sm font-normal text-spenda-labeltext">{job.description}</Typography>
                </li>
              )}
              {job?.totalBookedJobHours && (
                <li>
                  <CalendarIcon className="h-3 w-3 fill-spenda-labeltext" />
                  <Typography className="font-normal text-spenda-labeltext" variant="xsmall">
                    {moment(job.startDate).format('DD MMM (HH:mm)')} - {moment(job.endDate).format('DD MMM (HH:mm)')}
                  </Typography>
                  <span className="ml-auto text-xs font-normal text-spenda-labeltext">{bookingHours}h</span>
                </li>
              )}

              {job?.emailAddress && (
                <li>
                  <IconEnvelope />
                  <Typography variant="xsmall" className="text-spenda-labeltext">
                    {job.emailAddress}
                  </Typography>
                </li>
              )}

              {job?.phoneNumber && (
                <li>
                  <IconCall />
                  <Typography variant="xsmall" className="text-spenda-labeltext">
                    {job.phoneNumber}
                  </Typography>
                </li>
              )}

              {job?.linkedTransactions && job?.linkedTransactions?.length > 0 ? (
                <li>
                  {job.linkedTransactions?.map(linkedTransaction => (
                    <div
                      key={linkedTransaction.refNumber}
                      className="flex cursor-pointer items-center gap-x-1.5 hover:underline"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickOfLinkedTransaction?.(linkedTransaction);
                      }}
                    >
                      <IconDocument />
                      <Typography className="text-xs font-medium text-primary">
                        {linkedTransaction.refNumber}
                      </Typography>
                    </div>
                  ))}
                </li>
              ) : null}
            </ul>
            <div className={`${popoverStatusColor(status)} py-2`}>
              <Typography className="text-center text-xs font-medium">{RequiresAttentionStatus[status!]}</Typography>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
