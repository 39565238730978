import React, {useContext} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import AppContext from '../../context/app/appContext';

const UserUnauthorized = () => {
  const {logout} = useContext(AppContext);

  const handleLogout = async () => {
    logout();
  };

  return (
    <div className=" bckg-spenda flex h-full items-center justify-center">
      <div className="mx-32">
        <Typography variant="h1" className={`mb-5 flex justify-center text-2xl font-medium text-white`}>
          User Unauthorized
        </Typography>
        <Typography variant="paragraph" className={`flex justify-center text-center text-xl text-white opacity-50`}>
          You don’t have permission to access this resource. Please contact your administrator to request the necessary
          access.
        </Typography>
        <div className="mt-5 flex justify-center">
          <Button className="text-center" onClick={handleLogout}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserUnauthorized;
