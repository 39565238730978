import React from 'react';
import {Popover, PopoverContent, PopoverHandler, Typography} from 'spenda-ui-react';
import IconQuotesListing from '../../assets/svg/quotes-management/IconQuotesListing';
import {IQuoteLines} from '../../model/quotes/quotes';
import {ProductWarrantyPeriod} from '../../model/constants/Constants';

export interface IWarrantyViewPopover {
  warrantyDetails: IQuoteLines['warrantyDetails'];
}

export const WarrantyViewPopover = ({warrantyDetails}: IWarrantyViewPopover) => {
  return (
    <Popover placement="bottom">
      <PopoverHandler>
        <button disabled={warrantyDetails?.length === 0} className="flex items-center justify-start space-x-1.5">
          {warrantyDetails?.length !== 0 ? (
            <>
              <IconQuotesListing className="h-[8px] w-[8px] sm:h-3 sm:w-3" />
              <Typography className="text-[6px] font-medium text-primary underline sm:text-xs">
                {warrantyDetails?.length}
              </Typography>
            </>
          ) : (
            <Typography className="text-[6px] font-medium sm:text-xs">NA</Typography>
          )}
        </button>
      </PopoverHandler>
      <PopoverContent className="z-10 overflow-hidden p-0 !shadow-[0_0px_4px_0_rgba(0,0,0,0.2)] sm:min-w-36 sm:max-w-52">
        {warrantyDetails?.map((warranty, index) => (
          <div key={'warranty-' + index} className={`relative w-full cursor-pointer overflow-hidden px-2.5 py-1`}>
            <Typography className="text-[6px] font-semibold sm:text-xs">
              <a
                href={warranty.uri}
                title={warranty.warrantyMediaFileName}
                target="_blank"
                download
                className="line-clamp-1 font-medium text-primary underline"
              >
                {warranty.warrantyMediaFileName}
              </a>
            </Typography>
            <Typography className="text-[6px] font-medium text-black-800 sm:text-xs">
              Period:{' '}
              {warranty.warrantyPeriodType === ProductWarrantyPeriod.LIFETIME
                ? `${warranty.warrantyPeriodType}`
                : `${warranty.warrantyPeriod} ${warranty.warrantyPeriodType}`}
            </Typography>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};
