import React, {useContext, useEffect, useState} from 'react';
import {Alert, Badge, Button, Chip, IconButton, Menu, MenuHandler, MenuList, Typography} from 'spenda-ui-react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';

import {Layout} from '../../../components/layout/Layout';
import WarningIcon from '../../../assets/svg/quotes-management/WarningIcon';
import {QuoteServiceDialog} from '../../../components/dialog/QuoteServiceDialog';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import useQuotesAPI from '../../../services/useQuotesAPI';
import QuoteVariant from '../../../components/quote-management/QuoteVariant';
import QuoteVariantDetails from './QuoteVariantDetails';
import {DatTypes, QuoteStatus, QuoteStatusLabel} from '../../../model/constants/Constants';
import {QuoteRejectDialog} from '../../../components/dialog/QuoteRejectDialog';
import QuoteContext from '../../../context/quote-context/QuoteContext';
import QuoteContextProvider from '../../../context/quote-context/QuoteContextProvider';
import {IQuoteAttachment, IQuotesPackage} from '../../../model/quotes/quotes';
import {IQuotes} from '../../../model/quotes/quotes';
import {AUTH_SELLING_QUOTES_PREVIEW} from '../../../routes/QuoteManagementRoutes';
import {CreateServiceJobModal} from '../../../components/dialog/CreateServiceJobModal';
import useServiceJobAPI from '../../../services/useServiceJobAPI';
import {ServiceJobScope} from '../../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../../context/serviceManagement/ServiceContextProvider';
import {AttachDocs} from '../../../assets/svg/AttachDocs';
import { QuoteAttachmentPreviewDialog } from '../../../components/dialog';

const QuotesDetail = () => {
  // States
  const [rejectedQuote] = useState<boolean>(false);
  const [quotePackage, setQuotesPackage] = useState<IQuotesPackage>();
  const [selectedVariant, setSelectedVariant] = useState<number>(-1);
  const [selectedQuoteDetails, setSelectedQuoteDetails] = useState<IQuotes>();

  const _leftPanel = (
    <div className="flex h-[calc(100vh-120px)] flex-col justify-between rounded bg-white">
      <LeftPanelComponent
        selectedVariant={selectedVariant}
        selectedQuoteDetails={selectedQuoteDetails}
        setSelectedQuoteDetails={setSelectedQuoteDetails}
        setSelectedVariant={setSelectedVariant}
        setQuotePackage={setQuotesPackage}
        quotePackageState={quotePackage}
      />
      {rejectedQuote && (
        <Alert className="bg-error/10 p-2.5">
          <div className="mb-3 flex items-center justify-start gap-[6px]">
            <WarningIcon className="min-w-[12px]" />
            <Typography variant="small" className="font-medium text-black-800">
              Quote Rejected
            </Typography>
          </div>
          <Typography variant="xsmall" className="text-black-800">
            <span className="font-medium">Customer reason: </span>
            “This quote has been rejected quote has been rejected due to a misalignment with our current budget
            constraints. We appreciate your understanding.”
          </Typography>
        </Alert>
      )}
    </div>
  );

  const _rightPanel = (
    <div className="h-[calc(100vh-120px)] rounded bg-white p-2">
      <RightPanelComponent selectedQuoteDetails={selectedQuoteDetails} quotePackage={quotePackage} />
    </div>
  );

  return (
    <QuoteContextProvider>
      <div className={`relative h-full overflow-hidden bg-primary/5 font-poppins`}>
        <Layout leftPanel={_leftPanel} rightPanel={_rightPanel} splitWidthType={'Quote'} />
      </div>
    </QuoteContextProvider>
  );
};

interface LeftPanelComponentProps {
  selectedVariant: number;
  setSelectedVariant: React.Dispatch<React.SetStateAction<number>>;
  setSelectedQuoteDetails: React.Dispatch<React.SetStateAction<IQuotes>>;
  selectedQuoteDetails?: IQuotes;
  setQuotePackage?: React.Dispatch<React.SetStateAction<IQuotesPackage>>;
  quotePackageState?: IQuotesPackage;
}
const LeftPanelComponent = (props: LeftPanelComponentProps) => {
  const {
    selectedVariant,
    selectedQuoteDetails,
    setSelectedQuoteDetails,
    setSelectedVariant,
    setQuotePackage,
    quotePackageState,
  } = props;
  const {customerDetails, quotePackage, updateQuotePackage} = useContext(QuoteContext);
  const [internalNote, setInternalNote] = useState<string>('');

  useEffect(() => {
    if (selectedVariant !== -1) {
      const selQuote = quotePackage?.quotes?.find(quote => quote.quoteID === selectedVariant);
      selQuote && setSelectedQuoteDetails(selQuote);
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (!quotePackage) return;
    setInternalNote(quotePackage?.internalNote || '');
    if (quotePackage.status === QuoteStatus.Approved) {
      // sort quotes set approved quote on top
      const approvedQuote = quotePackage?.quotes?.find(quote => quote.status === QuoteStatus.Approved);
      if (approvedQuote) {
        quotePackage.quotes = _.sortBy(quotePackage.quotes, obj => {
          return obj.quoteID === approvedQuote?.quoteID ? 0 : 1;
        });
        setSelectedVariant(approvedQuote?.quoteID!);
        setSelectedQuoteDetails(approvedQuote);
      }
    } else if (!quotePackageState && quotePackage?.quotes && quotePackage?.quotes.length > 0) {
      setSelectedVariant(quotePackage?.quotes[0]?.quoteID!);
      setSelectedQuoteDetails(quotePackage?.quotes[0]);
    } else if (quotePackageState && selectedQuoteDetails) {
      const latestQuote = quotePackage?.quotes?.find(quote => selectedQuoteDetails.quoteID === quote.quoteID);
      if (latestQuote) {
        setSelectedVariant(latestQuote?.quoteID!);
        setSelectedQuoteDetails(latestQuote);
      }
    }
    setQuotePackage && setQuotePackage(quotePackage);
  }, [quotePackage]);

  return (
    <>
      {quotePackage && (
        <QuoteVariant
          selectedCustomer={customerDetails}
          quotePackage={quotePackage}
          isQuoteSent
          selectedVariant={selectedVariant!}
          setSelectedVariant={setSelectedVariant}
          setInternalNote={setInternalNote}
          internalNote={internalNote}
          selectedQuoteDetails={selectedQuoteDetails}
          updateQuotePackage={() => updateQuotePackage({quotePackageID: quotePackage.quotePackageID, internalNote})}
        />
      )}
    </>
  );
};

interface RightPanelComponentProps {
  selectedQuoteDetails?: IQuotes;
  quotePackage?: IQuotesPackage;
}

const RightPanelComponent = (props: RightPanelComponentProps) => {
  const {quotePackage, selectedQuoteDetails} = props;
  const {refreshQuotePackage} = useContext(QuoteContext);
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();
  const {acceptQuote, rejectQuote, getQuoteAttachments, isLoading: quoteAttachmentLoading} = useQuotesAPI();
  const {createJobFromQuote, isLoading: serviceLoading} = useServiceJobAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRejectQuoteDialog, setShowRejectQuoteDialog] = useState<boolean>(false);
  const history = useHistory();
  const [showServiceJobModal, setShowServiceJobModal] = useState<boolean>(false);
  const [serviceJobId, setServiceJobId] = useState<number>();
  const [quoteAttachments, setQuoteAttachments] = useState<IQuoteAttachment[]>([]);
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState<boolean>(false);

  const handleAcceptQuote = async () => {
    if (selectedQuoteDetails && selectedQuoteDetails.quoteID) {
      await acceptQuote(selectedQuoteDetails.quoteID);
      await refreshQuotePackage();
    }
    setIsLoading(false);
  };

  const handleRejectQuote = async (rejectReason?: string) => {
    setIsLoading(true);
    if (selectedQuoteDetails && selectedQuoteDetails.quoteID) {
      await rejectQuote(selectedQuoteDetails.quoteID, rejectReason);
      await refreshQuotePackage();
    }
    setIsLoading(false);
  };

  const handleIsDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const fetchAttachments = async () => {
    if (quoteAttachments.length === 0 && selectedQuoteDetails?.countAttachments) {
      const attachments = await getQuoteAttachments(selectedQuoteDetails?.quoteID!);
      setQuoteAttachments(attachments!);
    }
    setOpenAttachmentDialog(!openAttachmentDialog);
  };

  const showActionJobs =
    (serviceManagementV1 &&
      quotePackage?.status === QuoteStatus.Approved &&
      selectedQuoteDetails?.status === QuoteStatus.Approved &&
      ((selectedQuoteDetails?.serviceJobID == null && !selectedQuoteDetails?.linkedTransactions?.length) ||
        (selectedQuoteDetails?.serviceJobID &&
          !selectedQuoteDetails?.linkedTransactions?.filter(
            trans => trans.id != selectedQuoteDetails?.serviceJobID && trans.datTypeID != DatTypes.Invoice,
          )?.length))) ||
    (quotePackage?.status === QuoteStatus.Draft && selectedQuoteDetails?.status === QuoteStatus.Draft);

  return (
    <>
      <div className="mb-2.5 flex min-h-[51px] w-full items-center justify-between border-b border-b-[#ECECEC] pb-2.5">
        {selectedQuoteDetails && (
          <div className="flex flex-row ">
            <Typography variant="h2">
              {`${selectedQuoteDetails?.name}`} - {quotePackage?.refNumber}
            </Typography>
            {selectedQuoteDetails?.status === QuoteStatus.Rejected && (
              <Chip
                className="m-auto ml-5 flex h-6 justify-center"
                color="error"
                data-autoid={`txt${QuoteStatusLabel.Rejected}`}
                value={QuoteStatusLabel.Rejected}
              />
            )}
            {selectedQuoteDetails?.status === QuoteStatus.Approved && (
              <Chip
                className="m-auto ml-5 flex h-6 justify-center"
                color="primary"
                data-autoid={`txt${QuoteStatusLabel.Approved}`}
                value={QuoteStatusLabel.Approved}
              />
            )}
            {selectedQuoteDetails?.status === QuoteStatus.Draft && quotePackage?.isSent && (
              <Chip
                className="m-auto ml-5 flex h-6 justify-center"
                color="cool-purple"
                data-autoid={`txt${QuoteStatusLabel.Sent}`}
                value={QuoteStatusLabel.Sent}
              />
            )}
          </div>
        )}

        {selectedQuoteDetails && (
          <div className="flex gap-2.5">
            {selectedQuoteDetails?.countAttachments !== 0 && (
              <div className="pr-2">
                <Badge
                  className="error h-[22px] min-h-[20px] w-[22px] min-w-[20px]"
                  color="error"
                  invisible={selectedQuoteDetails?.countAttachments === 0}
                  content={selectedQuoteDetails?.countAttachments}
                >
                  <IconButton variant="outlined" name="attachment" onClick={fetchAttachments}>
                    <AttachDocs className="-rotate-45" width={22} height={22} />
                  </IconButton>
                </Badge>
              </div>
            )}
            {selectedQuoteDetails?.status === QuoteStatus.Draft && quotePackage?.isSent && (
              <Button
                variant="outlined"
                onClick={() => {
                  history.push(`${AUTH_SELLING_QUOTES_PREVIEW}${quotePackage?.quotePackageID}`, {isResend: true});
                }}
              >
                Resend Quote
              </Button>
            )}
            {quotePackage?.status === QuoteStatus.Draft && selectedQuoteDetails?.status === QuoteStatus.Draft && (
              <Button
                variant="outlined"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  handleAcceptQuote();
                }}
              >
                Accept for Customer
              </Button>
            )}
            {showActionJobs && (
              <Menu
                dismiss={{
                  itemPress: false,
                }}
                open={isMenuOpen}
                handler={() => {
                  if (!isMenuOpen && isLoading) return;
                  setIsMenuOpen(!isMenuOpen);
                }}
                placement="bottom-end"
              >
                <MenuHandler className="flex h-[40px] w-[200px] cursor-pointer items-center justify-center gap-[1.25rem] rounded-md bg-primary pl-4">
                  <div>
                    <Typography data-autoid={'txtActionQuote'} className="text-center font-semibold text-white">
                      Action Quote
                    </Typography>
                    <div className="flex items-center justify-end gap-2 ">
                      <span className="border border-y-[10px] border-[#fff]"></span>
                      <div className={`${isMenuOpen ? 'rotate-180' : 'rotate-0'}`} data-autoid={`imgActionQuote`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                          <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </MenuHandler>
                {isMenuOpen && (
                  <MenuList className="w-[200px] overflow-hidden border-primary p-0 ">
                    {serviceManagementV1 &&
                      quotePackage?.status === QuoteStatus.Approved &&
                      selectedQuoteDetails.status === QuoteStatus.Approved &&
                      ((selectedQuoteDetails?.serviceJobID == null &&
                        !selectedQuoteDetails?.linkedTransactions?.length) ||
                        (selectedQuoteDetails?.serviceJobID &&
                          !selectedQuoteDetails?.linkedTransactions?.filter(
                            trans =>
                              trans.id != selectedQuoteDetails?.serviceJobID && trans.datTypeID != DatTypes.Invoice,
                          )?.length)) && (
                        <li
                          onClick={() => handleIsDialogOpen()}
                          data-autoid={'liCreateServiceJob'}
                          className="w-full cursor-pointer list-none border-b border-[#ECECEC] px-3 py-2  text-left text-base  font-semibold text-black-800 hover:bg-[#E3EEF5]"
                        >
                          Create a service job
                        </li>
                      )}
                    {quotePackage?.status === QuoteStatus.Draft &&
                      selectedQuoteDetails?.status === QuoteStatus.Draft && (
                        <li
                          className="w-full cursor-pointer list-none border-b border-[#ECECEC]  px-3 py-2  text-left text-base  font-semibold text-black-800 hover:bg-[#E3EEF5]"
                          onClick={() => setShowRejectQuoteDialog(true)}
                          data-autoid={'liRejectQuote'}
                        >
                          Reject quote
                        </li>
                      )}
                    {isDialogOpen && (
                      <QuoteServiceDialog
                        handleGoBack={() => setIsDialogOpen(!isDialogOpen)}
                        handleCreate={async () => {
                          // create service job from quote
                          const serviceJobResponse = await createJobFromQuote(selectedQuoteDetails.quoteID!);
                          setServiceJobId(serviceJobResponse.serviceJobID);
                          setShowServiceJobModal(true);
                        }}
                        isLoading={serviceLoading}
                      />
                    )}
                  </MenuList>
                )}
              </Menu>
            )}
          </div>
        )}
      </div>
      {openAttachmentDialog && (
        <QuoteAttachmentPreviewDialog
          handleOpenAndClose={setOpenAttachmentDialog}
          uploadedAttachments={quoteAttachments}
          loading={quoteAttachmentLoading}
        />
      )}
      {selectedQuoteDetails && <QuoteVariantDetails selectedQuoteDetails={selectedQuoteDetails} />}
      {showRejectQuoteDialog && (
        <QuoteRejectDialog
          handleRejectQuote={handleRejectQuote}
          handleCancel={() => setShowRejectQuoteDialog(false)}
          isLoading={isLoading}
          quoteName={selectedQuoteDetails?.name}
        />
      )}

      {showServiceJobModal && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            open={showServiceJobModal}
            handleClose={() => {
              setShowServiceJobModal(false);
              setIsDialogOpen(false);
              refreshQuotePackage();
            }}
            scope={ServiceJobScope.QuoteDetails}
            serviceJobId={serviceJobId}
          />
        </ServiceContextProvider>
      )}
    </>
  );
};
export default QuotesDetail;
