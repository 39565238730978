import React, {useMemo, useState} from 'react';
import {Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Typography} from 'spenda-ui-react';
import {useEnvironment} from '../../../hooks/useEnvironment';
import {CreateClaimRequestDialog} from '../../../components/AccountsReceivable/CreateClaimRequestDialog';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';
import {VisibleContent} from '../../../components/ui/VisibleContent';
import {CustomContent} from '../../../components/ui/CustomContent';
import IconFilter from '../../../assets/svg/IconFilter';
import AdvanceFilter from '../../../components/ECommerce/AdvanceFilter';
import {IconSort} from '../../../assets/svg';
import {IClaimsAndReturnFilters, ISortOption} from './ClaimsHighLevel';
import {IFilterSection} from '../requires-attention/RequiresAttention';
import {CSVLink} from 'react-csv';
import {IClaimRequestList} from '../../../model/claims/ClaimsAndReturns';

interface IFilterHandlers {
  setIsShowFilterDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isShowFilterDialog: boolean;
  setFilters: React.Dispatch<React.SetStateAction<IClaimsAndReturnFilters>>;
  filters: IClaimsAndReturnFilters;
  filterSections: IFilterSection[];
  handleSaveFilters: (filters: IClaimsAndReturnFilters) => void;
  handleClearFilters: () => void;
}

interface ISortHandlers {
  handleSortChange: (value: string | undefined) => void;
  updatedSortOptions: ISortOption[];
  sortKey: string;
}

interface IClaimsHighLevelToolbarProps {
  activeBucket?: string;
  claimAndReturnsV272602: boolean;
  supplierId?: number;
  setSearchQuery: React.Dispatch<
    React.SetStateAction<{
      searchKey: string;
      searchCategory: string;
    }>
  >;
  filterHandlers: IFilterHandlers;
  sortHandlers: ISortHandlers;
  claimList?: IClaimRequestList[];
}

export const ClaimsHighLevelToolbar = (props: IClaimsHighLevelToolbarProps) => {
  // Props
  const {
    activeBucket,
    supplierId,
    claimAndReturnsV272602,
    setSearchQuery,
    filterHandlers: {
      isShowFilterDialog,
      setIsShowFilterDialog,
      filters,
      handleSaveFilters,
      handleClearFilters,
      filterSections,
    },
    sortHandlers: {handleSortChange, updatedSortOptions, sortKey},
    claimList,
  } = props;

  // Hooks
  const {isInSupplierContext} = useTenantInfo();
  const {isCapricornEnvironment} = useEnvironment();

  //States
  const [toggleCreateClaimRequest, setToggleCreateClaimRequest] = useState(false);

  // Constant
  const datePickerButtons = ['This week', 'Last month'];

  const refNumberLabel = CustomContent({
    keyPath: 'accountReceivables.claimRequests.search.refNumber',
    children: 'Ref Number',
  });

  const searchCategories = useMemo(() => {
    const categories = [
      {value: 'Amount', label: 'Amount'},
      {value: 'RefNumber', label: refNumberLabel, placeholder: refNumberLabel},
    ];

    if (!isCapricornEnvironment) {
      categories.push({value: 'VendorName', label: 'Vendor name'});
    }

    if (isInSupplierContext) {
      categories.push({value: 'CustomerName', label: 'Customer Name'});
    }

    return categories.sort((a, b) => a.label.localeCompare(b.label));
  }, [isCapricornEnvironment, isInSupplierContext, refNumberLabel]);

  return (
    <div className={`mb-3 flex h-[49px] items-center justify-between border-[#D8D8D8] border-b-default pb-2.5`}>
      <Typography className="mr-5 inline whitespace-nowrap font-poppins font-medium" variant="h3">
        {activeBucket}
      </Typography>

      <>
        {claimAndReturnsV272602 && (
          <>
            <div className={`flex w-full flex-row items-center justify-between`}>
              <div className="min-w-[400px]">
                <ARSearchBar
                  type="advance"
                  searchString={''}
                  onSubmit={({searchString, searchCategory}) => {
                    setSearchQuery({searchKey: searchString, searchCategory: searchString ? searchCategory || '' : ''});
                  }}
                  isSearching={false}
                  dataAutoIdSuffix={'claimsAndReturn'}
                  searchCategory={'RefNumber'}
                  searchCategories={searchCategories}
                />
              </div>

              <div className="flex justify-end gap-2">
                <IconButton
                  variant="outlined"
                  color="primary"
                  data-autoid={'btnAdvanceFilter'}
                  onClick={() => setIsShowFilterDialog(true)}
                >
                  <IconFilter />
                </IconButton>
                <Menu>
                  <MenuHandler>
                    <IconButton variant="outlined" data-autoid={'btnSortFilter'} className="focus:outline-none">
                      <IconSort />
                    </IconButton>
                  </MenuHandler>
                  <MenuList className="flex flex-col p-0">
                    {updatedSortOptions?.map((option, index) => (
                      <MenuItem
                        data-autoid={`lblSortKey${option.id}`}
                        key={option.id}
                        color="primary"
                        onClick={() => handleSortChange(option.id)}
                        className={`rounded-none ${option.id === sortKey && 'bg-primary/[0.2]'} ${index !== updatedSortOptions.length - 1 ? 'border-b border-[#D8D8D8]' : ''} p-2`}
                      >
                        <Typography variant="small" className="text-sm font-medium text-[#333333]">
                          {option.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>

                <VisibleContent keyPath="accountsReceivable.creditMemos.createClaimRequestButton">
                  <Button variant="filled" color="primary" onClick={() => setToggleCreateClaimRequest(true)}>
                    Create Claim Request
                  </Button>
                </VisibleContent>
                <DowloadClaimsCsvButton data={claimList} />
              </div>
            </div>

            {toggleCreateClaimRequest && (
              <CreateClaimRequestDialog
                supplierId={supplierId}
                showModal={toggleCreateClaimRequest}
                onConfirm={function (): void {
                  throw new Error('Function not implemented.');
                }}
                handleClose={() => setToggleCreateClaimRequest(false)}
              />
            )}
            {isShowFilterDialog && (
              <AdvanceFilter
                showModal={isShowFilterDialog}
                setOpen={() => setIsShowFilterDialog(!isShowFilterDialog)}
                onSave={handleSaveFilters}
                onClear={handleClearFilters}
                filterSections={filterSections}
                selectedFilters={filters}
                dateButtons={datePickerButtons}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};

interface IDowloadClaimCsvButtonProps {
  data?: IClaimRequestList[];
}

const DowloadClaimsCsvButton = (props: IDowloadClaimCsvButtonProps) => {
  // Props
  const {data} = props;

  // Hooks
  const {isInBuyerContext} = useTenantInfo();

  const headers = [
    {label: isInBuyerContext ? 'Supplier' : 'Customer', key: 'cName'},
    {label: 'Vendor', key: 'vendorName'},
    {label: 'Ref ID', key: 'claimCode'},
    {label: isInBuyerContext ? 'Raised on' : 'Claim raised on', key: 'createdDate'},
    {label: 'Invoice ref', key: 'invoiceRefNumber'},
    {label: 'Invoice Date', key: 'invoiceDate'},
    {label: 'Items', key: 'noItems'},
    {label: 'Amount', key: 'totalInc'},
    {label: 'Status', key: 'status'},
  ];

  return (
    <CSVLink headers={headers} data={data || []} filename={'All_Claims.csv'}>
      <Button disabled={!data?.length} variant="filled" color="primary" type="button">
        Download CSV
      </Button>
    </CSVLink>
  );
};
