import React, {SetStateAction, useEffect} from 'react';
import {Tooltip, Typography} from 'spenda-ui-react';

import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';
import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {SPagination} from '../../../../components/tables/SPagination';
import {Toast} from '../../../../utils/Toast';
import {useInventoryContext} from '../../../../context/inventory-context/InventoryContext';
import {PriceFormat} from '../../../../utils/formatter';
import {useStagingInventory} from '../../../../hooks/useStagingInventory';
import {IStagingVariant} from '../../../../model/inventory/Inventory';

interface IVariantStagingAreaTable {
  selectedInventoryRows: number[];
  setSelectedInventoryRows: React.Dispatch<SetStateAction<number[]>>;
}

export const VariantStagingAreaTable: React.FC<IVariantStagingAreaTable> = ({
  selectedInventoryRows,
  setSelectedInventoryRows,
}) => {
  const {invalidStagingInventoryCount, validStagingInventoryCount} = useInventoryContext();

  const {loading, items, totalCount, pageSize, currentPage, searchFilter, setSearchFilter} = useStagingInventory();

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      startRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      maxResults: rows,
      startRow: 1,
    });
  };

  const getBorderClass = (validationResult: string) => {
    if (validationResult === 'missing') {
      return 'border border-error';
    } else if (validationResult === 'invalid') {
      return 'border border-warning';
    }
    return '';
  };

  const commonRowClass = 'm-0 flex items-center text-lg focus-within:border-primary';

  useEffect(() => {
    if (invalidStagingInventoryCount > 0) {
      Toast.warning(
        <>
          <Typography className="font-medium text-spenda-primarytext">
            {validStagingInventoryCount} variants uploaded, {invalidStagingInventoryCount} issues found
          </Typography>
          <Typography variant="xsmall" className="text-spenda-primarytext">
            Please address the following issues in your Excel template. Once these issues are resolved, please re-upload
            your file.
          </Typography>
        </>,
        {
          border: '1px solid #C68A19',
          padding: '10px',
          width: '503px',
        },
      );
    }
  }, []);

  const columns = [
    {
      title: '',
      key: 'inventoryVariantCSVID',
      align: 'center',
      columClassName: 'sticky left-0 z-20',
      rowClassName: 'sticky left-0 z-15 border-b border-[#f1f1f1]',
      rowRenderer: (rowData: IStagingVariant, _index: number) => {
        return (
          <div className="w-[30px]">
            <RoundedCheckbox
              value={rowData.inventoryVariantCSVID}
              onChange={() => {
                const selectedRows = [...selectedInventoryRows];
                if (selectedRows.includes(rowData.inventoryVariantCSVID)) {
                  const index = selectedRows.findIndex(item => item === rowData.inventoryVariantCSVID);
                  selectedRows.splice(index, 1);
                } else {
                  selectedRows.push(rowData.inventoryVariantCSVID);
                }
                setSelectedInventoryRows(selectedRows);
              }}
              checked={selectedInventoryRows.includes(rowData.inventoryVariantCSVID)}
            />
          </div>
        );
      },
    },
    {
      title: 'Master Product SKU',
      key: 'masterProduct_SKU',
      align: 'left',
      columClassName: 'sticky left-[30px] z-20',
      rowClassName: 'sticky left-[30px] z-15 bg-white border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'masterproduct_sku').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'masterproduct_sku').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} w-[200px] cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtMasterProductSKU-${rowIndex}`}>
              {rowData?.masterProduct_SKU}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant Product SKU',
      key: 'variantProduct_SKU',
      width: '100px',
      align: 'left',
      columClassName: 'sticky left-[230px] z-20',
      rowClassName: 'sticky left-[230px] z-15 border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variantproduct_sku').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variantproduct_sku').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} w-[200px] cursor-pointer`}>
            <Typography className="px-2 font-medium text-primary" data-autoid={`txtVariantProduct_SKU-${rowIndex}`}>
              {rowData?.variantProduct_SKU}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant1 Abbreviated Name',
      key: 'variant1_AbbreviatedName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_abbreviatedname').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_abbreviatedname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant1_AbbreviatedName-${rowIndex}`}>
              {rowData?.variant1_AbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant1 Name',
      key: 'variant1_Name',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_name').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant1_name').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant1_Name-${rowIndex}`}>
              {rowData?.variant1_Name}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant1 Option Abbreviated Name',
      key: 'variant1_OptionAbbreviatedName',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_optionabbreviatedname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant1_optionabbreviatedname')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant1_OptionAbbreviatedName-${rowIndex}`}>
              {rowData?.variant1_OptionAbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant1 Option Name',
      key: 'variant1_OptionName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_optionname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant1_optionname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtvariant1_OptionName-${rowIndex}`}>
              {rowData?.variant1_OptionName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant1 Option SellPriceEx Delta',
      key: 'variant1_OptionSellPriceExDelta',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant1_optionsellpriceexdelta').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant1_optionsellpriceexdelta')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtVariant1_OptionSellPriceExDelta-${rowIndex}`}>
              {rowData?.variant1_OptionSellPriceExDelta &&
                PriceFormat(parseInt(rowData?.variant1_OptionSellPriceExDelta || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant2 Abbreviated Name',
      key: 'variant2_AbbreviatedName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_abbreviatedname').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_abbreviatedname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant2_AbbreviatedName-${rowIndex}`}>
              {rowData?.variant2_AbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant2 Name',
      key: 'variant2_Name',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_name').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant2_name').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant2_Name-${rowIndex}`}>
              {rowData?.variant2_Name}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant2 Option Abbreviated Name',
      key: 'variant2_OptionAbbreviatedName',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_optionabbreviatedname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant2_optionabbreviatedname')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant2_OptionAbbreviatedName-${rowIndex}`}>
              {rowData?.variant2_OptionAbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant2 Option Name',
      key: 'variant2_OptionName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_optionname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant2_optionname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtvariant2_OptionName-${rowIndex}`}>
              {rowData?.variant2_OptionName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant2 Option SellPriceEx Delta',
      key: 'variant2_OptionSellPriceExDelta',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant2_optionsellpriceexdelta').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant2_optionsellpriceexdelta')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtVariant2_OptionSellPriceExDelta-${rowIndex}`}>
              {rowData?.variant2_OptionSellPriceExDelta &&
                PriceFormat(parseInt(rowData?.variant2_OptionSellPriceExDelta || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant3 Abbreviated Name',
      key: 'variant3_AbbreviatedName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_abbreviatedname').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_abbreviatedname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant3_AbbreviatedName-${rowIndex}`}>
              {rowData?.variant3_AbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant3 Name',
      key: 'variant3_Name',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_name').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant3_name').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant3_Name-${rowIndex}`}>
              {rowData?.variant3_Name}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant3 Option Abbreviated Name',
      key: 'variant3_OptionAbbreviatedName',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_optionabbreviatedname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant3_optionabbreviatedname')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant3_OptionAbbreviatedName-${rowIndex}`}>
              {rowData?.variant3_OptionAbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant3 Option Name',
      key: 'variant3_OptionName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_optionname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant3_optionname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtvariant3_OptionName-${rowIndex}`}>
              {rowData?.variant3_OptionName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant3 Option SellPriceEx Delta',
      key: 'variant3_OptionSellPriceExDelta',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant3_optionsellpriceexdelta').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant3_optionsellpriceexdelta')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtVariant3_OptionSellPriceExDelta-${rowIndex}`}>
              {rowData?.variant3_OptionSellPriceExDelta &&
                PriceFormat(parseInt(rowData?.variant3_OptionSellPriceExDelta || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant4 Abbreviated Name',
      key: 'variant4_AbbreviatedName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_abbreviatedname').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_abbreviatedname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant4_AbbreviatedName-${rowIndex}`}>
              {rowData?.variant4_AbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant4 Name',
      key: 'variant4_Name',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_name').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant4_name').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant4_Name-${rowIndex}`}>
              {rowData?.variant4_Name}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant4 Option Abbreviated Name',
      key: 'variant4_OptionAbbreviatedName',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_optionabbreviatedname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant4_optionabbreviatedname')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant4_OptionAbbreviatedName-${rowIndex}`}>
              {rowData?.variant4_OptionAbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant4 Option Name',
      key: 'variant4_OptionName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_optionname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant4_optionname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtvariant4_OptionName-${rowIndex}`}>
              {rowData?.variant4_OptionName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant4 Option SellPriceEx Delta',
      key: 'variant4_OptionSellPriceExDelta',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant4_optionsellpriceexdelta').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant4_optionsellpriceexdelta')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtVariant4_OptionSellPriceExDelta-${rowIndex}`}>
              {rowData?.variant4_OptionSellPriceExDelta &&
                PriceFormat(parseInt(rowData?.variant4_OptionSellPriceExDelta || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant5 Abbreviated Name',
      key: 'variant5_AbbreviatedName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_abbreviatedname').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_abbreviatedname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant5_AbbreviatedName-${rowIndex}`}>
              {rowData?.variant5_AbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant5 Name',
      key: 'variant5_Name',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_name').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant5_name').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant5_Name-${rowIndex}`}>
              {rowData?.variant5_Name}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant5 Option Abbreviated Name',
      key: 'variant5_OptionAbbreviatedName',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_optionabbreviatedname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant5_optionabbreviatedname')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`}>
            <Typography className="px-2" data-autoid={`txtVariant5_OptionAbbreviatedName-${rowIndex}`}>
              {rowData?.variant5_OptionAbbreviatedName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant5 Option Name',
      key: 'variant5_OptionName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_optionname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant5_optionname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtvariant5_OptionName-${rowIndex}`}>
              {rowData?.variant5_OptionName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Variant5 Option SellPriceEx Delta',
      key: 'variant5_OptionSellPriceExDelta',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingVariant) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'variant5_optionsellpriceexdelta').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'variant5_optionsellpriceexdelta')
                  .length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtVariant5_OptionSellPriceExDelta-${rowIndex}`}>
              {rowData?.variant5_OptionSellPriceExDelta &&
                PriceFormat(parseInt(rowData?.variant5_OptionSellPriceExDelta || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warning message',
      key: 'warningMessage',
      width: '15%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      rowRenderer: (rowData: IStagingVariant, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            {!rowData.errors.length && !rowData.warnings.length ? (
              '-'
            ) : (
              <>
                {(rowData.errors.length === 1 && !rowData.warnings.length) ||
                (!rowData.errors.length && rowData.warnings.length === 1) ? (
                  <>
                    {rowData?.errors?.length ? (
                      <Typography data-autoid={`txtErrorMessage-${rowIndex}`} className="text-error">
                        {`${rowData?.errors?.[0]?.description
                          ?.replace('${ColumnName}', rowData?.errors?.[0]?.columnName)
                          ?.replace('${actual}', rowData?.errors?.[0]?.actual)}`}
                      </Typography>
                    ) : (
                      <Typography data-autoid={`txtWarningMessage-${rowIndex}`} className="ml-1 text-warning ">
                        {`${rowData?.warnings?.[0]?.description
                          ?.replace('${ColumnName}', rowData?.warnings?.[0]?.columnName)
                          ?.replace('{actual}', rowData?.warnings?.[0]?.actual)}`}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Tooltip
                    placement="top-start"
                    className={`rounded-[4px] bg-white p-0 text-black-800 shadow-xl`}
                    content={
                      (rowData.errors.length || rowData.warnings.length) && (
                        <div className="flex flex-col px-3 py-2">
                          <Typography className="text-error" variant="small">
                            Errors:
                          </Typography>
                          <ul className="list-disc space-y-1 pl-7">
                            {rowData?.errors?.map((err, index) => {
                              return (
                                <li key={index}>
                                  <Typography data-autoid={`txtErrorMessage-${index}`} variant="small">
                                    {`${err?.description
                                      ?.replace('${ColumnName}', err?.columnName)
                                      ?.replace('${expected}', err?.expected || '')
                                      ?.replace('${actual}', err?.actual)}`}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                          <Typography className="text-warning" variant="small">
                            Warnings:
                          </Typography>
                          <ul className="list-disc space-y-1 pl-7">
                            {rowData?.warnings?.map((war, index) => {
                              return (
                                <li key={index}>
                                  <Typography data-autoid={`txtErrorMessage-${index}`} variant="small">
                                    {`${war?.description
                                      ?.replace('${ColumnName}', war?.columnName)
                                      ?.replace('${expected}', war?.expected || '')
                                      ?.replace('{actual}', war?.actual)}`}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )
                    }
                  >
                    <div className={`flex cursor-pointer flex-row`}>
                      <Typography data-autoid={`txtErrorMessage-${rowIndex}`} className="text-error">
                        {`${rowData.errors.length} ${rowData.errors.length > 1 ? 'errors, ' : 'error, '}`}
                      </Typography>
                      <Typography data-autoid={`txtWarningMessage-${rowIndex}`} className="ml-1 text-warning ">
                        {`${rowData.warnings.length} ${rowData.warnings.length > 1 ? 'warnings' : 'warning'}`}
                      </Typography>
                    </div>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full w-full">
      <div className={`h-[calc(100vh-320px)] flex-grow`}>
        <ARTable
          isLoading={loading}
          rows={items}
          columns={columns}
          tableClass="!w-[calc(100%+2000px)] border-separate border-spacing-0"
          conatinerClass="!px-0"
        />
      </div>
      <div className="mt-4">
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};
