import {ITenantModuleDetails} from './../../model/tenant/TenantModuleDetails';
import types from '../types';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import {IUserTenant} from '../../model/user/User';

export default (state: any, action: {type: any; payload?: any}) => {
  switch (action.type) {
    case types.SET_USER:
      // Return state with loggedIn user
      return {
        ...state,
        user: action.payload,
      };
    case types.SET_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case types.SET_USER_TENANT:
      return {
        ...state,
        user: action.payload.user,
        tenant: action.payload.tenant,
      };
    case types.SET_USER_TENANT_LIST:
      return {
        ...state,
        userTenants: action.payload,
        userRole: action.payload?.find((u: IUserTenant) => u.IsSelected)?.RoleName,
      };
    case types.SET_TENANT_INFO:
      return {
        ...state,
        tenantInfo: action.payload,
      };
    case types.SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case types.SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case types.LOAD_SESSION:
      const {
        menu,
        suppliers,
        marketplaceSupplier,
        user,
        tenant,
        tenantInfo,
        featureFlags,
        modules,
        availablePaymentMethodTypes,
        userTenants,
        isQuotesSettingsSaved,
        serviceTenantConfigs,
      } = action.payload;

      return {
        ...state,
        menu,
        suppliers,
        marketplaceSupplier,
        user,
        tenant,
        tenantInfo,
        featureFlags,
        modules,
        availablePaymentMethodTypes,
        userTenants,
        isQuotesSettingsSaved,
        serviceTenantConfigs,
        userRole: userTenants?.find((u: IUserTenant) => u.IsSelected)?.RoleName,
      };
    case types.LOGOUT:
      // Clear user from state
      return {
        ...state,
        user: undefined,
        tenant: undefined,
        suppliers: [],
        menu: undefined,
        marketplaceSupplier: undefined,
        supplierToLink: undefined,
      };
    case types.SET_MARKETPLACE_SUPPLIER:
      return {
        ...state,
        marketplaceSupplier: action.payload,
      };
    case types.SET_SUPPLIER_TO_LINK:
      return {
        ...state,
        supplierToLink: action.payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_SELECTED_MODULE:
      let selectedModule = action.payload as Partial<ITenantModuleDetails>;

      return {
        ...state,
        selectedModule,
      };
    case types.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case types.SET_SELECTED_ADAPTOR:
      let selectedAdaptor = action.payload as IMasterAdaptor;

      return {
        ...state,
        selectedAdaptor,
      };
    case types.SET_WORKFLOW_ID:
      return {
        ...state,
        workFlowId: action.payload,
      };
    case types.SET_STATEMENT_INVITATION:
      return {
        ...state,
        statementInvitationId: action.payload,
      };
    case types.SET_INVOICE_PAYMENT_INVITATION:
      return {
        ...state,
        invoiceInvitationId: action.payload,
      };
    case types.SET_CONNECTED_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case types.SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    case types.SET_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    case types.SET_IS_PSBL_PIBL_ENABLE:
      return {
        ...state,
        isPSBLPIBLEnable: action.payload,
      };
    case types.SET_IS_SHOW_CONFIGURE_SETTINGS_DIALOG:
      return {
        ...state,
        isShowConfigureSettingsDialog: action.payload,
      };
    case types.SET_IS_MOBILE_DISCLAIMER_SHOWN:
      return {
        ...state,
        isMobileDisclaimerShown: action.payload,
      };
    case types.SET_IS_QUOTES_SETTINGS_SAVED:
      return {
        ...state,
        isQuotesSettingsSaved: action.payload,
      };
    case types.SET_AVAILABLE_PAYMENT_METHOD_TYPES:
      return {
        ...state,
        availablePaymentMethodTypes: action.payload,
      };
    case types.SET_SERVICE_TENANT_CONFIGS:
      return {
        ...state,
        serviceTenantConfigs: action.payload,
      };
    default:
      return state;
  }
};
