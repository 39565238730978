import React, {useMemo, useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import moment from 'moment';

import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {RoundedCheckbox} from '../../../components/inputs/RoundedCheckbox';
import {CreateServiceJobModal} from '../../../components/dialog/CreateServiceJobModal';
import {ServiceContextProvider} from '../../../context/serviceManagement/ServiceContextProvider';
import {InvoiceGenerateDialog} from '../../../components/dialog/InvoiceGenerateDialog';
import {SPagination} from '../../../components/tables/SPagination';
import {IServiceJob} from '../../../model/service-management/serviceJob';
import {useUninvoicedContext} from './context/UninvoicedContext';
import {UninvoicedServiceTableColumn} from '../../../model/constants/Constants';
import {truncateTwoDecimals} from '../../../utils/formatter';

const ServiceUninvoiceJobList = () => {
  const [openBookingDialog, setOpenBookingDialog] = useState({
    open: false,
    serviceJobId: -1,
  });
  const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] = useState<boolean>(false);

  const {
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
    items,
    pageSize,
    totalCount,
    selectedServiceJobs,
    setSelectedServiceJobs,
    setSearchFilter,
    selectedFilterOptions,
  } = useUninvoicedContext();

  const handleOnRowSelect: ChangeEventHandler = e => {
    const serviceJobId = Number(e.target.value);
    if (e.target.checked) {
      setSelectedServiceJobs([...selectedServiceJobs, serviceJobId]);
    } else {
      setSelectedServiceJobs(selectedServiceJobs.filter(v => v !== serviceJobId));
    }
  };

  const allColumns = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: IServiceJob) => {
        return (
          <RoundedCheckbox
            value={rowData.serviceJobID}
            onChange={handleOnRowSelect}
            checked={selectedServiceJobs.includes(rowData.serviceJobID!)}
          />
        );
      },
    },
    {
      title: 'Completion date',
      key: UninvoicedServiceTableColumn.completionDate,
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtCompleteDate`}>
          {moment(rowData.completedDateTime_utc).format('D MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Customer name',
      key: UninvoicedServiceTableColumn.customerName,
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" className="cursor-pointer text-primary underline" data-autoId={`ref`}>
          <span
            onClick={() => {
              setOpenBookingDialog({
                open: true,
                serviceJobId: rowData.serviceJobID!,
              });
            }}
            data-autoid={`lnk`}
          >
            {rowData.customerName}
          </span>
        </Typography>
      ),
    },
    {
      title: 'Primary tech',
      key: UninvoicedServiceTableColumn.primaryTech,
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtPrimaryTech`}>
          {rowData.primaryTechnicianUserName}
        </Typography>
      ),
    },
    {
      title: 'Asset details',
      key: UninvoicedServiceTableColumn.assetDetails,
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography
          variant="small"
          className="max-w-[25rem] truncate"
          title={`${rowData?.siteStreetAddress},${rowData.siteCity}, ${rowData.siteCountry}, ${rowData.siteState}, ${rowData?.sitePostCode}`}
          data-autoid={`txtAssetDetails`}
        >
          {rowData?.siteStreetAddress}, {rowData.siteCity}, {rowData.siteCountry}, {rowData.siteState},{' '}
          {rowData?.sitePostCode}
        </Typography>
      ),
    },
    {
      title: 'Customer email',
      key: UninvoicedServiceTableColumn.customerEmail,
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtCustomerEmail`}>
          {rowData.onSiteContactEmailAddress || 'NA'}
        </Typography>
      ),
    },
    {
      title: 'Phone no.',
      key: UninvoicedServiceTableColumn.phoneNo,
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtPhoneNumber`}>
          {rowData.onSitePhoneNumber || 'NA'}
        </Typography>
      ),
    },
    {
      title: 'No. of lines',
      key: UninvoicedServiceTableColumn.noOfLines,
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtNoOfLines`}>
          {rowData.lineCount}
        </Typography>
      ),
    },
    {
      title: 'Total inc',
      key: UninvoicedServiceTableColumn.totalInc,
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtTotalInc`}>
          {truncateTwoDecimals(rowData.totalInc || 0)}
        </Typography>
      ),
    },
    {
      title: 'Billable hours',
      key: UninvoicedServiceTableColumn.billableHours,
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IServiceJob) => (
        <Typography variant="small" data-autoid={`txtTotalLineQty-${rowData.serviceJobID}`}>
          {truncateTwoDecimals(rowData?.totalBillableJobHours || 0)}
        </Typography>
      ),
    },
  ];

  const filteredColumns = useMemo(() => {
    return [allColumns[0], ...allColumns.filter(column => selectedFilterOptions.includes(column.key))];
  }, [selectedFilterOptions, selectedServiceJobs]);

  const handleSelectAll: ChangeEventHandler = e => {
    if (!e.target.checked) {
      setSelectedServiceJobs([]);
      return;
    }
    setSelectedServiceJobs(items?.map(item => item.serviceJobID!) ?? []);
  };

  const selectedAll = useMemo(
    () => (items?.length ? items?.every(v => selectedServiceJobs?.includes(v?.serviceJobID!)) : false),
    [items, selectedServiceJobs],
  );

  const handleServiceJobModal = () => {
    setOpenBookingDialog({
      open: false,
      serviceJobId: -1,
    });
    setSearchFilter(searchFilter => ({...searchFilter, SearchString: ''}));
  };

  const handleCancelInvoiceDialog = () => {
    setOpenGenerateInvoiceDialog(false);
    setSelectedServiceJobs([]);
    setSearchFilter(searchFilter => ({...searchFilter, SearchString: ''}));
  };

  return (
    <div className="h-full w-full">
      <div className="my-2 h-1 w-full border-b border-b-[#ECECEC]" />
      <div className="ml-4 flex items-center justify-start gap-2.5 font-poppins">
        <RoundedCheckbox label="Select all" onChange={handleSelectAll} checked={selectedAll} />
        <Button
          onClick={() => setOpenGenerateInvoiceDialog(true)}
          variant="outlined"
          disabled={selectedServiceJobs.length ? false : true}
        >
          Generate Invoice
        </Button>
      </div>
      <div className={`relative mt-2.5 h-[calc(100vh-310px)]`}>
        <ARTable
          isHighlightRowOnHover
          columns={filteredColumns}
          scope="AR"
          rows={items ?? []}
          isLoading={false}
          tableClass="!mx-0"
        />
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={newPage => handlePageChange(newPage, pageSize)}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      {openBookingDialog.open && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            serviceJobId={openBookingDialog.serviceJobId}
            open={openBookingDialog.open}
            handleClose={handleServiceJobModal}
          />
        </ServiceContextProvider>
      )}
      {openGenerateInvoiceDialog && (
        <InvoiceGenerateDialog
          selectedServiceJobs={selectedServiceJobs}
          totalInc={
            selectedServiceJobs.length === 1
              ? items?.find(item => item.serviceJobID === selectedServiceJobs[0])?.totalInc
              : -1
          }
          getCustomerDetails={generatedInvoice => {
            const res = items?.find(item => item.serviceJobID === generatedInvoice[0].serviceJobID);
            return {
              customerEmails: [res?.onSiteContactEmailAddress!],
              customerIDs: [res?.accountCustomerID!],
            };
          }}
          handleCancel={handleCancelInvoiceDialog}
        />
      )}
    </div>
  );
};

export default ServiceUninvoiceJobList;
