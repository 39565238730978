import React, {useEffect, useId, useRef} from 'react';
import ReactDOM from 'react-dom';
import {IconButton} from 'spenda-ui-react';

import {CloseCross} from '../../assets/svg/CloseCross';

const PreviewAttachment = ({
  imageUrl,
  setShow,
  type,
}: {
  imageUrl: string;
  setShow: (show: boolean) => void;
  type: string;
}) => {
  return (
    <Portal>
      <div className="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm transition-all duration-1000 ease-in">
        <div className="absolute right-10 top-5">
          <IconButton color="white" variant="outlined" onClick={() => setShow(false)}>
            <CloseCross />
          </IconButton>
        </div>

        {type === 'Document' ? (
          <iframe src={imageUrl} className="h-[90%] w-full max-w-[70%] rounded-md bg-black object-cover" />
        ) : (
          <img
            className="h-[70%] w-full max-w-[70%] rounded-md object-cover"
            src={imageUrl}
            alt="Image is not available"
          />
        )}
      </div>
    </Portal>
  );
};

export default PreviewAttachment;

const Portal = ({children}: React.PropsWithChildren) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  const id = useId();

  if (!elRef.current) {
    elRef.current = document.createElement('div');
    elRef.current.id = `portal-${id}`;
  }

  useEffect(() => {
    const el = elRef.current!;
    try {
      document.body.appendChild(el);
    } catch (error) {
      console.error('Error appending element to body:', error);
    }
    return () => {
      try {
        document.body.removeChild(el);
      } catch (error) {
        console.error('Error removing element from body:', error);
      }
    };
  }, []);

  return ReactDOM.createPortal(children, elRef.current);
};
