import React, {useState, useEffect} from 'react';
import {Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import useAccountAPI from '../../services/useAccountAPI';
import {IAccounts} from '../../model/accounts/Accounts';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DatTypes, PaymentMethodTypes} from '../../model/constants/Constants';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import {Toast} from '../../utils/Toast';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import LoadingOverLay from '../ui/LoadingOverlay';
import DeleteIcon from '@material-ui/icons/Delete';
import {SModal} from '../modals/modalSpendaMeterialUI';
import CloseIcon from '@material-ui/icons/Close';
import backButton from '../../assets/png/back-button.png';
import {APFooter} from './APFooter';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';
import {SButton} from '../buttons/Button';
import {quotesPaymentMethods} from '../dialog/QuoteDepositDialog';

interface IPayments {
  paymentMethod: string;
  clearsTo: string;
}

interface APPaymentSetupProps {
  onNextClick?: () => void;
  onBackPress?: () => void;
  selectedAdaptor?: IMasterAdaptor;
  handleClose?: () => void;
  isAPSettings?: boolean;
  isQuotes?: boolean;
}

const paymentMethods = [
  PaymentMethodTypes.Mastercard,
  PaymentMethodTypes.Visa,
  PaymentMethodTypes.BankTransfer,
  PaymentMethodTypes.Airplus,
];

export const APPaymentSetup: React.FunctionComponent<APPaymentSetupProps> = ({
  onNextClick,
  onBackPress,
  selectedAdaptor,
  handleClose,
  isAPSettings,
  isQuotes,
}: APPaymentSetupProps) => {
  const useAPPaymentSetupStyles = makeStyles({
    apOnboardingModal: {
      maxWidth: '800px',
    },
    apSettingsModal: {
      maxWidth: '1200px',
    },
    apBusinessHeader: {
      borderBottom: '1px solid #CCCCCC',
      '& h2': {
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: 300,
        fontSize: '28px',
        color: '#333333',
      },
    },
    backBtn: {
      maxWidth: '40px',
      height: '40px',
    },
    conditionScroll: {
      '&::-webkit-scrollbar-track': {
        background: '#EAEFF2',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#1C78AD',
      },
    },
    paymentsTable: {
      margin: '10px 0 0 0',
      '& table': {
        overflow: 'hidden',
        borderRadius: isAPSettings ? '2px 2px 0px 0px' : '8px 8px 2px 2px',
        '& th': {
          color: '#999999',
          fontWeight: 600,
          fontSize: '14px',
          fontFamily: 'Poppins',
          width: '180px',
        },
      },
    },
    dropdown: {
      '&.MuiTableCell-body': {
        padding: '0px!important',
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
          background: 'transparent',
          padding: '0px 10px',
          '& input': {
            fontFamily: 'poppins',
            fontSize: '14px',
            textAlign: 'right',
          },
        },
      },
      '& .MuiInputBase-root': {
        maxWidth: '600px',
        width: '100%',
        height: '40px',
        border: '1px solid transparent',
        '&.Mui-focused': {
          border: '1px solid #1C78AD',
        },
        '&:before, &:after': {
          display: 'none',
        },
      },
      '& .MuiSelect-select': {
        color: '#333333',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '500',
        fontFamily: 'poppins',
        paddingLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& p': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '& svg': {
        color: '#1c78ad',
        width: '25px',
        height: '25px',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          border: '1px solid #333',
        },
      '& legend': {
        width: '89px',
      },
    },
    '& .menuItem': {
      overflow: 'visible!important',
      '& li': {
        color: '#333',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        justifyContent: 'flex-start!important',
        padding: '10px',
        '&:hover': {
          background: '#D3E5EF80',
        },
        '&.Mui-selected': {
          background: '#D3E5EF50',
        },
      },
    },
  });

  const classes = useAPPaymentSetupStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getAccounts, submitPayment, getPaymentMethods} = useAccountAPI();
  const [clearsToAccounts, setClearsToAccounts] = useState<IAccounts[]>([]);
  const {synkDataRead} = useWorkflowAPI();
  const [payments, setPayments] = useState<IPayments[]>([]);
  const [savedPayments, setSavedPayments] = useState<IPayments[]>([]);

  const getChartAccounts = () => {
    getAccounts().then((data: IAccounts[]) => {
      setClearsToAccounts(data);
    });
  };

  useEffect(() => {
    if (clearsToAccounts && clearsToAccounts.length === 0) {
      getChartAccounts();
    }
    getPaymentMethods().then(data => {
      if (Array.isArray(data?.Settings) && data.Settings.length) {
        const _payments = data.Settings.map((method: any) => ({
          clearsTo: method?.Account?.ID,
          paymentMethod: method?.PaymentMethodName,
        }));
        const filteredAPPaymentObjects = _payments.filter((paymentObj: any) =>
          paymentMethods.includes(paymentObj.paymentMethod),
        );
        const filteredQuotesPaymentObjects = _payments.filter((paymentObj: any) =>
          quotesPaymentMethods.includes(paymentObj.paymentMethod),
        );
        if (isQuotes) {
          setSavedPayments(
            _payments.filter((paymentObj: any) => !quotesPaymentMethods.includes(paymentObj.paymentMethod)),
          );
        } else {
          setSavedPayments(_payments.filter((paymentObj: any) => !paymentMethods.includes(paymentObj.paymentMethod)));
        }
        setPayments(!isQuotes ? filteredAPPaymentObjects : filteredQuotesPaymentObjects);
      } else {
        if (!payments.length) setPayments([{paymentMethod: '', clearsTo: ''}]);
      }
    });
  }, []);

  const refreshAccounts = async () => {
    if (selectedAdaptor && selectedAdaptor?.Name && selectedAdaptor?.ID) {
      setIsLoading(true);
      await synkDataRead(false, 'Read', selectedAdaptor.Name, selectedAdaptor.ID, DatTypes.Accounts, 15);
      await getChartAccounts();
      setIsLoading(false);
      Toast.info('Chart of Accounts imported Successfully.');
    }
  };

  const handlePaymentsChange = (event: any, index: number) => {
    let value = event.target.value
      .toString()
      .split('.')
      .map((el: string, i: number) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');

    if (event.target.name === 'apPaymentMethod' && payments.find(payment => payment.paymentMethod === value)) {
      Toast.error('Payment Method is already selected.');
      return;
    }

    const targetName = event.target.name === 'apPaymentMethod' ? 'paymentMethod' : 'clearsTo';
    const _tempPayments: Array<any> = [...payments];
    _tempPayments[index][targetName] = value;
    setPayments(_tempPayments);
  };

  const addNewPayment = () => {
    setPayments(prevPayments => [
      ...prevPayments,
      {
        paymentMethod: '',
        clearsTo: '',
      },
    ]);
  };

  const deletePayment = (index: number) => {
    const _payments = [...payments];
    _payments.splice(index, 1);
    setPayments(_payments);
  };

  const onSubmitPayment = async () => {
    if (payments.find(payment => !payment.paymentMethod || !payment.clearsTo)) {
      Toast.error('Missing Payment method/Clears to Account');
      return;
    }
    setIsLoading(true);
    const paymentsData = {
      Settings: payments.map(payment => {
        return {
          PaymentMethodName: payment.paymentMethod.replace(/ /g, ''),
          Account: {
            ID: payment.clearsTo,
            IsActive: Boolean(
              (clearsToAccounts.find(clearsToAccount => clearsToAccount.ID === Number(payment.clearsTo)) || {})
                .IsActive,
            ),
          },
        };
      }),
    };
    if (savedPayments.length) {
      const filteredSettings = savedPayments.map(item => {
        return {
          PaymentMethodName: item.paymentMethod,
          Account: {
            ID: item.clearsTo,
            IsActive: true,
          },
        };
      });
      paymentsData.Settings = [...paymentsData.Settings, ...filteredSettings];
    }
    submitPayment(paymentsData)
      .then(() => {
        isAPSettings && Toast.info('Saved Successfully.');
        onNextClick && onNextClick();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        alert('Failed to submit payment');
      });
  };

  const renderSettings = () => {
    return (
      <div
        className={`spenda-color flex h-full ${isQuotes && 'w-full'} max-w-full items-center justify-center text-xl`}
      >
        <LoadingOverLay
          isLoading={isLoading}
          overlayBgColor={isAPSettings ? 'rgba(128,128,128,0.5)' : 'rgba(0, 0, 0, 0.7)'}
        >
          <div
            className={`modal-box ${
              isAPSettings
                ? `${classes.apSettingsModal} ${isQuotes && 'mt-[-100px]'}`
                : `${classes.apOnboardingModal} px-3 py-3 `
            } `}
          >
            {isAPSettings && (
              <Box className="absolute right-0 flex justify-end	" marginTop={isQuotes ? '-80px' : '-60px'}>
                <SButton
                  textColor="white"
                  width="100px"
                  height="40px"
                  lineHeight="l"
                  fontSize="xs"
                  boxShadow="none"
                  type="submit"
                  backgroundColor="#1C78AD"
                  padding="7px 20px!important"
                  margin="0"
                  variant={'contained'}
                  borderRadius="6px"
                  disabled={isLoading}
                  onClick={() => {
                    onSubmitPayment();
                  }}
                  data-autoid="btnSave"
                >
                  Save
                </SButton>
              </Box>
            )}
            {!isAPSettings && (
              <Box className={`${classes.apBusinessHeader} -mx-3 flex justify-between px-3 pb-3`}>
                <span className="cursor-pointer" onClick={() => onBackPress && onBackPress()} data-autoid="btnBack">
                  <img src={backButton} alt="Back Button" className={classes.backBtn} />
                </span>
                <h2 className={`w-full text-center`}>Let’s setup your payment methods</h2>
                <Box className="flex justify-end text-right">
                  <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                    <CloseIcon />
                  </span>
                </Box>
              </Box>
            )}
            <div
              className={`mr-4 ${!isQuotes && 'mt-5'} flex flex-col items-end justify-end`}
              style={{marginLeft: 'auto'}}
            >
              {!isAPSettings ? (
                <p style={{fontSize: 'small'}} className="mb-1 font-poppins">
                  <span style={{color: 'black'}} className="text-xs">
                    Can't see your chart of accounts{' '}
                  </span>
                  <span
                    className="cursor-pointer text-xs font-semibold underline underline-offset-1"
                    onClick={refreshAccounts}
                    data-autoid="btnRefresh"
                  >
                    Refresh
                  </span>
                </p>
              ) : (
                <div className="flex w-full items-center justify-between">
                  <h2 className="text-base font-medium" style={{color: '#333'}}>
                    Payment methods:
                  </h2>
                  <ArRefreshBtn
                    handleRefreshData={getChartAccounts}
                    datTypeId={DatTypes.Accounts}
                    title={'Refresh Charts of Accounts'}
                    hideAdapterImage={true}
                  />
                </div>
              )}
            </div>
            <TableContainer className={`${classes.paymentsTable} overflow-y-auto  ${isAPSettings ? 'px-0' : 'px-2'}`}>
              <Table data-autoid="tblPaymentMethods" className="tableCellHeight" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{width: '43%'}}>
                      Payment methods
                    </TableCell>
                    <TableCell align="left" style={{width: '43%'}}>
                      Clears to
                    </TableCell>
                    <TableCell align="left" style={{width: '14%'}}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments &&
                    payments.map((item: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left" className={classes.dropdown}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              value={item.paymentMethod}
                              style={{
                                color: 'black',
                              }}
                              onChange={event => handlePaymentsChange(event, index)}
                              inputProps={{
                                id: 'deviceSource-native-select',
                                name: 'apPaymentMethod',
                              }}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}
                              data-autoid={`selectPaymentMethod-${index}`}
                            >
                              <MenuItem value="" disabled>
                                <p className="menuItem opacity-50">Select method</p>
                              </MenuItem>
                              {(isQuotes ? quotesPaymentMethods : paymentMethods).map(method => {
                                return (
                                  <MenuItem key={method} value={method}>
                                    <p className="menuItem">{method}</p>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </TableCell>
                          <TableCell align="left" className={classes.dropdown}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              value={item.clearsTo}
                              style={{
                                color: 'black',
                              }}
                              onChange={event => handlePaymentsChange(event, index)}
                              inputProps={{
                                id: 'deviceSource-native-select',
                                'data-autoid': `ddlClearsTo-${index}`,
                                name: 'apClearsTo',
                              }}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}
                              data-autoid={`selectClearTo-${index}`}
                            >
                              <MenuItem value="" disabled>
                                <p className="menuItem opacity-50">Select account</p>
                              </MenuItem>
                              {clearsToAccounts.map(({Name, ID, Code}) => (
                                <MenuItem key={ID} value={ID}>
                                  <p className="menuItem">
                                    {Code} - {Name}
                                  </p>
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell align="left">
                            {index !== 0 && (
                              <button onClick={() => deletePayment(index)} data-autoid={`btnDelete-${index}`}>
                                <DeleteIcon className="deleteBtn" htmlColor="#CCCCCC" fontSize="small" />
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {payments.length < (isQuotes ? 30 : 4) && (
              <span
                className="ml-4 cursor-pointer font-poppins text-base font-semibold"
                onClick={() => {
                  addNewPayment();
                }}
                data-autoid="btnAddNewPaymentMethod"
              >
                + Add new payment method
              </span>
            )}
            {!isAPSettings && (
              <APFooter
                onNextClick={onSubmitPayment}
                buttonLabel={'Next'}
                isCancelButton={true}
                secondaryButtonLabel="Skip"
                handleCancelClick={() => onNextClick && onNextClick()}
              />
            )}
          </div>
        </LoadingOverLay>
      </div>
    );
  };

  return isAPSettings ? renderSettings() : <SModal open>{renderSettings()}</SModal>;
};
