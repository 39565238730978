import * as React from 'react';

export const InventoryArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={121}
    height={6}
    fill="none"
    {...props}
  >
    <path
      fill="#EAEAEA"
      d="M1.078 2.5h-.5v1h.5v-1zm119.859.5l-5-2.887v5.774l5-2.887zm-119.86.5h115.36v-1H1.077v1z"
    />
  </svg>
  );
};
