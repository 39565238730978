import React, {useEffect, useState, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, FormikHelpers, Form, FormikProps} from 'formik';
import {Button, Checkbox} from 'spenda-ui-react';
import * as Yup from 'yup';
import {omit} from 'lodash';

import {AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST} from '../../routes/CustomerRoutes';
import {Layout} from '../../components/layout/Layout';
import {SButton} from '../../components/buttons/Button';
import {AddCustomerForm} from '../../components/form/CustomerForm';
import {LinkOperationalCustomerDialog} from '../../components/dialog/LinkOperationalCustomerDialog';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {AlertDialog} from '../../components/dialog/AlertDialogSlideV2';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useCustomersAPI, useCustomerLinking} from '../../services/useCustomersAPI';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {Toast} from '../../utils/Toast';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {ILocation} from '../../model/address/Location';
import {ICustomer} from '../../model/customer/Customer';
import {IAddress} from '../../model/address/Address';
import {LoadingOverlayV1} from '../../components/ui/LoadingOverlayV1';

export const AddCustomer = () => {
  const history = useHistory();
  const {customerID: customerIdValue} = useParams<{customerID?: string | undefined}>();
  const [refNumberChange, setRefNumberChange] = useState<boolean>(false);

  const [customerID, setCustomerID] = useState<number>(parseInt(customerIdValue ? customerIdValue : ''));

  const {
    saveCustomer,
    getCustomer,
    updateCustomer,
    getcustomerStatements,
    isLoading: apiCallLoading,
  } = useCustomersAPI();

  const [showLinkOperationalCustomerDialog, setShowLinkOperationalCustomerDialog] = React.useState<boolean>(false);
  const [customerLink] = React.useState<boolean>(true);
  const {loading: linkGenericLoader, response: responseCustomerLinking, linkAccountCustomers} = useCustomerLinking();
  const {response, unLinkCustomerAccount, unLinkCustomerClass, linkGenericClass} = useCustomerLinking();

  const [searchGenericFilter, setSearchGenericFilter] = useState<any>();
  const [customerDataGeneric, setCustomerDataGeneric] = useState<ICreateCustomerClassValues[]>();
  const [search] = useState<any>();
  const [unLink, setUnLink] = useState<boolean>(true);
  const [changeLink, setChangeLink] = useState<boolean>(false);
  const [changeNewCustomer, setChangeNewCustomer] = useState<boolean>(false);
  const [newCustomerLink, setNewCustomerLink] = useState<boolean>(false);
  const [newCustomerID, setNewCustomerId] = useState<number>();
  const [showUnLinkDialog, setShowUnLinkDialog] = useState<boolean>(false);
  const [checkPopUpStatus, setCheckPopUpStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checkPopUpStatusData, setCheckPopUpStatusData] = useState<any>();

  const formRef = useRef<FormikProps<ICustomer>>(null);
  const {getList} = useCustomerClassesAPI();
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();
  const validationSchema = Yup.object({
    ABN: Yup.string().matches(
      /^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/,
      'ABN must be of 11 characters without any blank space',
    ),
    Contacts: Yup.array().of(
      Yup.object()
        .nullable(true)
        .shape({
          EmailAddress: Yup.string().email('Invalid Email address').nullable(true),
          FirstName: Yup.string().when('IsActive', {
            is: true,
            then: Yup.string()
              .required('First Name is required')
              .max(50, 'First Name cannot be longer than 50 characters.')
              // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid  First name'),
              .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  first name'),
          }),
          LastName: Yup.string().when('IsActive', {
            is: true,
            then: Yup.string()
              .required('Last Name is required')
              .max(50, 'Last Name cannot be longer than 50 characters.')
              // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid  last name'),
              .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  last name'),
          }),

          Phone1: Yup.string().when('IsActive', {
            is: true,
            then: Yup.string()
              .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                'Not a valid phone number',
              )
              .min(10, 'Not a valid phone number')
              .max(10, 'Not a valid phone number'),
          }),
          PhoneMobile: Yup.string().when('IsActive', {
            is: true,
            then: Yup.string()
              .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                'Not a valid Mobile number',
              )
              .min(10, 'Not a valid Mobile number')
              .max(10, 'Not a valid Mobile number'),
          }),
        }),
    ),
    Phone1: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Not a valid phone number',
      )
      .min(10, 'Not a valid phone number')
      .max(10, 'Not a valid phone number'),
    Name: Yup.string()
      // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid  Company name')
      .when(['IsIndividual'], (IsIndividual: string, schema: any) => {
        return IsIndividual === 'false' ? schema.required(' Company Name is required') : schema.nullable();
      }),
    RefNumber: Yup.string().test({
      name: 'RefNumber',

      test: function (value) {
        let check = true;
        if (customerID) {
          if (customerEdit.RefNumber === value) {
            check = false;
          }
        }
        return refNumberChange === true && check
          ? this.createError({message: 'Unique Reference number require', path: 'RefNumber'})
          : true;
      },
    }),
  });

  const toggleCompanyDetailsDialog = () => {
    setShowLinkOperationalCustomerDialog(!showLinkOperationalCustomerDialog);
  };
  const toggleSaveNewCustomer = () => {
    setShowUnLinkDialog(!showUnLinkDialog);
  };

  const linkClick = async (customersId: number, AccountCustomerID: number) => {
    if (!linkGenericLoader) {
      await linkAccountCustomers(customersId, AccountCustomerID);
      setChangeLink(true);
    }
  };

  const newCustomer: ICustomer = {
    IsActive: true,
    Name: '',
    Phone1: '',
    ABN: '',
    RefNumber: '',
    IsIndividual: 'false',
    IsExemptFromMerchantSurcharge: 'false',
    Locations: [
      {
        Addresses: [
          {
            Name: '',
            Country: '',
            State: '',
            StreetAddress: '',
            City: '',
            PostCode: '',
            FullAddress: '',
            IsDefaultDeliverTo: true,
            IsDefaultBilling: true,
            IsActive: true,
          },
        ],
        IsActive: true,
        Name: '',
        IsDefaultLocation: true,
        ID: '',
      },
    ],

    Contacts: [
      {
        IsPrimaryContact: true,
        FirstName: '',
        IsActive: true,
        LastName: '',
        EmailAddress: '',
        Phone1: '',
      },
    ],
  } as ICustomer;

  const [customerEdit, setCustomerEdit] = React.useState<ICustomer>(newCustomer);
  const [customerDetails, setCustomerDetails] = React.useState<ICustomer>({});
  const [accountCustomerData, setAccountCustomerData] = React.useState<ICustomer>({});
  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);
  const [customerInvoices, setCustomerInvoices] = useState<ICustomer>({});

  const fetchCustomerInfo = (customerID: number) => {
    getCustomer(customerID).then(async (res: ICustomer) => {
      if (res.BillToCustomerID) {
        Promise.all([getCustomer(res?.BillToCustomerID), getcustomerStatements(Number(res?.AccountCustomerID))]).then(
          ([accountCustomer, customerStatementsRes]) => {
            setAccountCustomerData(accountCustomer);
            setCustomerInvoices(customerStatementsRes);
          },
        );
      }
      setCustomerDetails(res);
    });
  };

  useEffect(() => {
    if (response?.IsSuccess && customerID) {
      fetchCustomerInfo(customerID);
    }
  }, [response]);

  useEffect(() => {
    if (checkPopUpStatus === true) {
      toggleSaveNewCustomer();

      saveCustomer(checkPopUpStatusData, 13).then(res => {
        let dataNew = res?.Value?.ID;
        setNewCustomerId(dataNew);
        setCustomerID(dataNew);
        setCheckPopUpStatus(false);
        Toast.info('Customer created');
      });
    }
  }, [checkPopUpStatus]);
  const unlinkCustomer = async (customer: ICustomer) => {
    setUnLink(false);
    if (customer.ID && customer.BillToCustomerID) {
      await unLinkCustomerAccount(customer.ID);
      setChangeLink(true);
    } else if (customer.ID && customer.ClassID) {
      await unLinkCustomerClass(customer.ID);
      setChangeLink(true);
      setCustomerEdit(prev => {
        return {
          ...prev,
          ClassID: null,
        };
      });
    }
  };
  useEffect(() => {
    if (response?.IsSuccess && unLink) {
      toggleCompanyDetailsDialog();
    }
    setUnLink(true);
  }, [response]);

  useEffect(() => {
    if (customerID) {
      getCustomer(customerID).then(
        async (res: ICustomer) => {
          if (res) {
            let loc = res?.Locations?.map((d: ILocation) => ({
              Addresses: d?.Addresses?.map((b: IAddress) => ({
                Name: b.Name,
                Country: b.Country,
                State: b.State,
                StreetAddress: b.StreetAddress,
                City: b.City,
                PostCode: b.PostCode,
                FullAddress: b.FullAddress,
                IsDefaultDeliverTo: b.IsDefaultDeliverTo,
                IsDefaultBilling: b.IsDefaultBilling,
                IsActive: b.IsActive,
                ID: b.ID,
              })),
              IsActive: d?.IsActive,
              Name: d?.Name,
              IsDefaultLocation: d?.IsDefaultLocation,
              ID: d?.ID,
            }));

            let con = res?.Contacts?.map((d: any) => ({
              BusinessContactID: d?.BusinessContactID,
              EmailAddress: d?.EmailAddress,
              FirstName: d?.FirstName,
              IsActive: true,
              LastName: d?.LastName,
              Phone1: d?.Phone1,
              PhoneMobile: d?.PhoneMobile,
            }));

            const _customers = {
              IsActive: res.IsActive,
              Name: res?.IsIndividual === true ? '' : res.Name,
              Phone1: res.Phone1,
              ABN: res.ABN,
              BillToCustomerID: res.BillToCustomerID,
              ClassID: res.ClassID,
              RefNumber: res.RefNumber,
              IsIndividual: res.IsIndividual ? 'true' : 'false',
              IsExemptFromMerchantSurcharge: res.IsExemptFromMerchantSurcharge ? 'true' : 'false',
              Locations: loc,

              Contacts: con,
            } as ICustomer;
            setCustomerEdit(_customers);
            setCustomerDetails(res);
            setIsLoading(false);
          }
          if (res.BillToCustomerID) {
            Promise.all([
              getCustomer(res?.BillToCustomerID),
              getcustomerStatements(Number(res?.AccountCustomerID)),
            ]).then(([accountCustomer, customerStatementsRes]) => {
              setAccountCustomerData(accountCustomer);
              setCustomerInvoices(customerStatementsRes);
            });
          }
          setCustomerDetails(res);
        },
        () => {
          history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST);
        },
      );
    } else {
      setIsLoading(false);
    }
  }, [changeLink, changeNewCustomer]);
  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    if (setSearchGenericFilter) {
      setSearchGenericFilter({
        ...omit(searchGenericFilter, ['customerClassID', 'LastRowNumber']),
        ...(selected ? {customerClassID: selected?.ID} : {}),
      });
    }
    setCustomerClassFilter(selected || undefined);
  };
  useEffect(() => {
    if (search) {
      const filter = {
        search,
      };
      getList(filter).then(res => {
        setCustomerDataGeneric(res.Value);
      });
    } else {
      setCustomerDataGeneric([]);
    }
  }, [search]);

  const linkGenericCustomer = async (customerId: number, customerClassFilter: number) => {
    if (!linkGenericLoader) {
      await linkGenericClass(customerId, customerClassFilter ? customerClassFilter : 0);
      setChangeLink(true);
      setCustomerEdit(prev => {
        return {
          ...prev,
          ClassID: customerClassFilter,
        };
      });
      handleCustomerClassFilter(null);
    }
  };

  const onSubmit = (values: ICustomer, helpers: FormikHelpers<ICustomer>): void => {
    let data: any = values;
    if (data.IsIndividual === 'true') {
      data.Name = '';
      data.Phone1 = '';
      data.CompanyName = '';
    }
    let i = 0;
    for (i = data?.Locations?.length || -1; i >= 0; i--) {
      if (data?.Locations?.[i]?.Addresses?.[0]?.FullAddress) {
        delete data?.Locations?.[i]?.Addresses?.[0]?.FullAddress;
      }
      if (
        !data?.Locations?.[i]?.Addresses?.[0]?.Name &&
        !data?.Locations?.[i]?.Addresses?.[0]?.Country &&
        !data?.Locations?.[i]?.Addresses?.[0]?.State &&
        !data?.Locations?.[i]?.Addresses?.[0]?.StreetAddress &&
        !data?.Locations?.[i]?.Addresses?.[0]?.City &&
        !data?.Locations?.[i]?.Addresses?.[0]?.PostCode &&
        data?.Locations?.[i]?.tempFlag
      ) {
        data = data?.Locations?.splice(i, 1) && data;
      }
      if (data?.Locations?.[i]?.tempFlag) {
        delete data?.Locations?.[i]?.tempFlag;
      }
      for (let j = 0; j < data?.Locations?.[i]?.AddAddress?.length; j++) {
        if (data?.Locations?.[i]?.AddAddress?.[j]?.addressFlag) {
          delete data?.Locations?.[i]?.Addresses?.[j]?.addressFlag;
        }
      }
    }

    if (customerID) {
      updateCustomer(customerID, data, 13).then(() => {
        helpers.setSubmitting(false);
        history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST);
        Toast.info('Customer updated');
      });
    } else if (newCustomerLink === true) {
      helpers.setSubmitting(false);
      toggleSaveNewCustomer();
      setCheckPopUpStatusData(data);
    } else {
      saveCustomer(data, 13).then(() => {
        helpers.setSubmitting(false);
        history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST);
        Toast.info('Customer created');
      });
    }
  };

  const handleArchiveCustomer = (event: React.ChangeEvent<HTMLInputElement>, fProps: FormikHelpers<ICustomer>) => {
    fProps.setFieldValue('IsActive', !event.target.checked);
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const _addCustomer = (
    <div
      className={
        'relative flex h-full max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white'
      }
      id="sales-orders-list"
    >
      {isLoading ? (
        <LoadingIndicator isLoading size={'md'} />
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={customerEdit}
          onSubmit={onSubmit}
          initialStatus={true}
        >
          {props => (
            <LoadingOverlayV1 isLoading={apiCallLoading}>
              <Form onSubmit={props.handleSubmit} onKeyDown={onKeyDown} className="flex h-full flex-col px-5 py-4">
                <div className={'flex w-full justify-between'} style={{padding: '20px 10px'}}>
                  <p className={'font-poppins text-xl font-light text-[#4D4D4D]'}>
                    {customerID ? 'Edit Customer' : 'Create A New Customer'}
                  </p>
                  <div className="flex">
                    <Checkbox
                      className="border-primary checked:border-primary checked:bg-primary"
                      label="Archive Customer"
                      onChange={e => handleArchiveCustomer(e, props)}
                      labelProps={{
                        className: 'text-spenda-primarytext font-medium',
                      }}
                    />
                  </div>
                </div>
                <AddCustomerForm
                  {...props}
                  handleUnlinkCustomer={unlinkCustomer}
                  handlelinkCustomer={toggleCompanyDetailsDialog}
                  newCustLink={setNewCustomerLink}
                  customerId={customerID}
                  customerToEdit={customerDetails}
                  accountData={accountCustomerData}
                  accountCustData={accountCustomerData}
                  setRefNumberChange={setRefNumberChange}
                  customerInvoices={customerInvoices}
                />
                <div
                  className={`sticky bottom-0 -mb-5 mr-3 flex w-[calc(100%-14px)] justify-end rounded-lg bg-spenda-footerBg p-2.5`}
                >
                  <Button
                    variant="outlined"
                    className="bg-white"
                    onClick={() => {
                      if (props.dirty) {
                        setShowSaveChangesDialog(true);
                      } else {
                        history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button disabled={props.isSubmitting} type="submit" className="ml-2" loading={props.isSubmitting}>
                    Save
                  </Button>
                </div>
              </Form>
            </LoadingOverlayV1>
          )}
        </Formik>
      )}
      <LinkOperationalCustomerDialog
        responseCustomerLinking={responseCustomerLinking}
        linkClick={linkClick}
        linkAccountCustomers={linkAccountCustomers}
        open={showLinkOperationalCustomerDialog}
        onClose={toggleCompanyDetailsDialog}
        customerLink={customerLink}
        setChangeNewCustomer={setChangeNewCustomer}
        customerId={customerID ? customerID : newCustomerID}
        fetchCustomerInfo={fetchCustomerInfo}
        getCustomer={getCustomer}
        handleFilter={handleCustomerClassFilter}
        customerDataGeneric={customerDataGeneric}
        linkGeneric={linkGenericCustomer}
        customerClassFilter={customerClassFilter}
      ></LinkOperationalCustomerDialog>
      {showUnLinkDialog && (
        <AlertDialogSlide
          showCancel={true}
          onCancel={() => {
            toggleSaveNewCustomer();
            setNewCustomerLink(false);
          }}
          maxWidth="xs"
          title="Save Changes"
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => {
                  setCheckPopUpStatus(true);
                  setNewCustomerLink(false);
                }}
              >
                Save
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="red"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => {
                  toggleSaveNewCustomer();
                  setNewCustomerLink(false);
                }}
              >
                Cancel
              </SButton>
            </div>
          }
        >
          <p className="pb-3 text-center font-medium">
            You need to save your changes before defining posting behaviour.Do you want to save your changes?
          </p>
        </AlertDialogSlide>
      )}
    </div>
  );
  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      {showSaveChangesDialog && (
        <AlertDialog
          title="Discard Changes"
          size="sm"
          content="You have unsaved changes, would you like to discard changes or return to the page?"
          contextTextVariant="paragraph"
          contentClass="px-5"
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => setShowSaveChangesDialog(false),
            },
            {
              label: 'Discard',
              action: () => history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST),
            },
          ]}
        />
      )}
      <Layout leftPanel={_addCustomer} splitWidthType={4} />
    </div>
  );
};
