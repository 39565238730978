import {IActionResultsList, IActionRes, IActionResults} from '../model/ActionResults';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {IUserAccountInfo, IUserRolePaymentBatch} from '../model/user/UserAccountInfo';
import useHttp from '../hooks/useHttp';

const useUsersAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const search = (filter: ISearchFilter): Promise<IActionResultsList<IUserAccountInfo>> => {
    return POST(`users/getusersthatusercanedit`, filter);
  };

  const get = (
    filter: ISearchFilter & {ExcludeUserRoleIDs?: number[]},
  ): Promise<IActionResultsList<IUserAccountInfo>> => {
    return GET('users', filter);
  };

  const getUser = (id: number): Promise<IUserAccountInfo> => {
    return GET(`users/get/${id}`).then((res: IActionResults<IUserAccountInfo>) => res.Value);
  };

  const saveUser = (user: Partial<IUserAccountInfo>): Promise<IActionRes> => {
    return POST(`users/save`, user);
  };

  const inactivate = (userId: number): Promise<IActionRes> => {
    return PUT(`users/delete`, {Value: userId});
  };

  const toogleEnabledDisabled = (userId: number, enabled: boolean): Promise<IActionRes> => {
    return PUT(`users/delete`, {Value: userId, IsValue: enabled});
  };

  const isUnique = (emailAddress: string): Promise<boolean> => {
    return PUT(`users/IsEmailAddressUnique?emailAddress=${emailAddress}`).then((userId: number) => userId > 0);
  };

  const saveUserRolePaymentBatch = async (userRole: IUserRolePaymentBatch): Promise<IUserRolePaymentBatch> => {
    return POST('userroles/paymentbatch', {Value: userRole}).then(
      (data: IActionResults<IUserRolePaymentBatch>) => data.Value,
    );
  };

  const getUserRolePaymentBatch = async (): Promise<IUserRolePaymentBatch> => {
    return GET('userroles/paymentbatch').then((data: IActionResults<IUserRolePaymentBatch>) => data.Value);
  };

  const deleteUser = (payload: {value: number}) => {
    return PUT('users/Delete', payload);
  };

  const restoreUser = (userID: number) => {
    return PUT(`Spenda/Tenant/Users/${userID}/restore`, undefined);
  };

  return {
    getUser,
    saveUser,
    saveUserRolePaymentBatch,
    getUserRolePaymentBatch,
    search,
    get,
    isUnique,
    inactivate,
    toogleEnabledDisabled,
    deleteUser,
    restoreUser,
    isLoading,
  };
};

export default useUsersAPI;
