/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {APOnboardingV1 as Onboarding} from '../../components/accountsPayableOnboarding/onboardingV1/APOnboardingV1';
import {ModuleInfoHeader} from '../../components/module-info/ModuleInfoHeader';
import AppContext from '../../context/app/appContext';
import {ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import {
  APOnboardingSteps,
  LowTouchPsblOnboardingSteps,
  ModuleTypes,
  PsblOnboardingSteps,
} from '../../model/constants/Constants';
import {Spinner, Tabs} from 'spenda-ui-react';
import {useOnboarding} from '../../services/useOnboarding';
import {IOnboardingV1Step, IPsblOnboardingSteps} from '../../model/OnboardingV1Step';
import clsx from 'clsx';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

const initialTabContentState: IOnboardingV1Step[] = [
  {
    stepID: APOnboardingSteps.ConfigureFinancialAdaptor,
    startedDateTime_utc: undefined,
    completedDateTime_utc: undefined,
    isSkipped: false,
  },
];

const PsblOnboadingSteps: IPsblOnboardingSteps[] = [
  {stepID: PsblOnboardingSteps.ConfigureFinancialAdaptor, completedDateTime_utc: ''},
  {stepID: PsblOnboardingSteps.BusinessInformation, completedDateTime_utc: ''},
  {stepID: PsblOnboardingSteps.PostingConfigurations, completedDateTime_utc: ''},
];

interface IAPOnboardingV1Props {
  isPsblOnboarding?: boolean;
  isApOnboarding?: boolean;
}

const APOrPsblOnboarding = (props: IAPOnboardingV1Props) => {
  const {isPsblOnboarding, isApOnboarding} = props;
  const {payStatementByLinkV289938, lmiCommercialRelease92671, psblV22LowTouchOnboarding92923} =
    useFeatureFlags().supplierDriven();

  const {onboardingAccount}: any = useParams();
  const appContext = useContext(AppContext);
  const tenant = appContext.tenant as ITenant;
  const user = appContext.user as IUser;
  const {getOnboardingStep, getPsblOnboardingSteps, handleTabChange, isLoading} = useOnboarding();
  const [tabContentState, setTabContentState] = useState<IOnboardingV1Step[] | IPsblOnboardingSteps[]>(
    initialTabContentState,
  );
  const [psblOnboardingSteps, setPsblOnboardingSteps] = useState<IPsblOnboardingSteps[]>(PsblOnboadingSteps);
  const history = useHistory();
  const {search: searchLocation, state: routeState} = useLocation<IOnboardingV1Step[] | IPsblOnboardingSteps[]>();
  const query = new URLSearchParams(searchLocation);
  const skipAdapter = useRef<boolean | null>(null);
  const currentStep = useRef<number | undefined>(
    isPsblOnboarding && psblV22LowTouchOnboarding92923
      ? LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor
      : APOnboardingSteps.ConfigureFinancialAdaptor,
  );

  const filterTabContent = useCallback((steps: IOnboardingV1Step[]) => {
    const filteredTabContent = steps.filter((tab: IOnboardingV1Step) => {
      if (
        tab.stepID === APOnboardingSteps.ConfigureFinancialAdaptor ||
        tab.stepID === APOnboardingSteps.ClearingAccount
      ) {
        return false;
      }
      return true;
    });
    return filteredTabContent;
  }, []);

  const updateCurrentStep = useCallback((steps: IOnboardingV1Step[] | IPsblOnboardingSteps[]) => {
    for (let step of steps) {
      if (APOnboardingSteps.ConfigureFinancialAdaptor === step.stepID && step.isSkipped && isApOnboarding) {
        skipAdapter.current = true;
      } else if (step.startedDateTime_utc && !step.isSkipped && !step.completedDateTime_utc) {
        currentStep.current = step.stepID;
        return;
      } else if (!step.startedDateTime_utc && !step.completedDateTime_utc && !step.isSkipped) {
        currentStep.current = step.stepID;
        (async () =>
          await handleTabChange({
            isPsblOnboarding,
            action: 'start',
            nextStep: step.stepID,
          }))();
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (isPsblOnboarding && payStatementByLinkV289938 && !psblV22LowTouchOnboarding92923) {
      if (lmiCommercialRelease92671 && appContext?.tenantInfo?.TenantUserDetails?.IsOnboardingMeetingCompleted) {
        currentStep.current = PsblOnboardingSteps.PostingConfigurations;
      }
      skipAdapter.current = false;
      setPsblOnboardingSteps(PsblOnboadingSteps);
      return;
    }

    const onboardingStep = async () => {
      let steps: IOnboardingV1Step[] | IPsblOnboardingSteps[];
      if (routeState) {
        steps = routeState;
        history.replace({state: undefined});
      } else {
        if (isPsblOnboarding && payStatementByLinkV289938 && psblV22LowTouchOnboarding92923) {
          steps = await getPsblOnboardingSteps({ModuleID: ModuleTypes.SpendaPay});
        } else {
          steps = await getOnboardingStep();
        }
      }
      updateCurrentStep(steps);
      if (
        isPsblOnboarding &&
        payStatementByLinkV289938 &&
        psblV22LowTouchOnboarding92923 &&
        currentStep.current === LowTouchPsblOnboardingSteps.TermsAndConditions
      ) {
        history.push('/supplier/module-info/psbl');
        return;
      }
      if (skipAdapter.current && isApOnboarding) {
        let filteredStep = filterTabContent(steps as IOnboardingV1Step[]);
        setTabContentState(filteredStep);
      } else {
        setTabContentState(steps);
      }
    };

    const skipFinancialAdapter = async () => {
      await handleTabChange({
        action: 'skip',
        nextStep: APOnboardingSteps.BusinessInformation,
        skipSteps: [APOnboardingSteps.ConfigureFinancialAdaptor, APOnboardingSteps.ClearingAccount],
      });
      let steps = await getOnboardingStep();
      await updateCurrentStep(steps);
      const filteredStep = await filterTabContent(steps);
      setTabContentState(filteredStep);
    };

    if (query.get('skipAdapter') === 'true' && isApOnboarding) {
      skipFinancialAdapter();
    } else {
      skipAdapter.current = false;
      onboardingStep();
    }
  }, [isPsblOnboarding, payStatementByLinkV289938, lmiCommercialRelease92671, psblV22LowTouchOnboarding92923]);

  return (
    <>
      <ModuleInfoHeader user={user} tenant={tenant} isPsblOnboarding={isPsblOnboarding && payStatementByLinkV289938} />
      <div
        className={clsx(
          isPsblOnboarding && payStatementByLinkV289938 && 'bg-primary/10 px-2.5 pt-2.5 2xl:pt-2.5',
          isLoading && 'flex-1',
        )}
      >
        {isLoading ? (
          <div className="flex h-full flex-col items-center justify-center bg-spenda-newbg">
            <div className="flex gap-x-2">
              <Spinner color="primary" className="h-7 w-7 text-primary/40" />
            </div>
          </div>
        ) : (
          <Tabs orientation="vertical" value={currentStep.current}>
            <Onboarding
              isAPOnboardingV1
              onboardingAccount={onboardingAccount}
              onBoardingAccountRedirectUrl={onboardingAccount ? window.location.href : ''}
              isSkipAdapter={skipAdapter.current}
              setTabContentState={setTabContentState}
              tabContentState={tabContentState}
              currentActiveTab={currentStep.current}
              isPsblOnboarding={isPsblOnboarding}
              psblOnboardingSteps={psblOnboardingSteps}
              setPsblOnboardingSteps={setPsblOnboardingSteps}
              payStatementByLinkV289938={payStatementByLinkV289938}
              lmiCommercialRelease92671={lmiCommercialRelease92671}
              psblV22LowTouchOnboarding92923={psblV22LowTouchOnboarding92923}
            />
          </Tabs>
        )}
      </div>
    </>
  );
};

export default APOrPsblOnboarding;
