import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button} from 'spenda-ui-react';
import {SetUpInventory} from '../../lowTouchOnboarding/SetUpInventory';
import {usePSBLHook} from '../../../hooks/useARHook';
import {useParams} from 'react-router-dom';
import {Inventory} from '../../../model/inventory/Inventory';
import {useInventoryAPI} from '../../../services/useInventoryAPI';
import {IPagedActionResults} from '../../../model/ActionResults';

interface IAddInventoryTableModal {
  isOpen: boolean;
  onClose: () => void;
}

export const AddInventoryTableModal = (props: IAddInventoryTableModal) => {
  //Props
  const {isOpen, onClose} = props;

  // Hooks
  const {supplierId: sId} = useParams<{supplierId?: string}>();
  const {getSelectedSupplier} = usePSBLHook();

  //Api
  const {listInventory} = useInventoryAPI();

  // Constant
  const supplierId = Number(sId);
  const supplier = getSelectedSupplier(supplierId);
  const marketplaceID = supplier?.MarketplaceID;
  const [isSelectableView, setIsSelectableView] = useState<boolean>(true);
  const [handleSave, setHandleSave] = useState<boolean>(false);
  const [selectedInventories, setSelectedInventories] = useState<Partial<Inventory[]>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const getDefaultData = () => {
    listInventory({
      MaxResults: 15,
      ...{isPurchased: true, isSystem: false},
    }).then((res: IPagedActionResults<Inventory[]>) => {
      const val = res?.Value;
      setSelectedInventories(val);
    });
  };
  useEffect(() => {
    getDefaultData();
  }, []);

  return (
    <ARDialogTemplate
      dialogProps={{size: 'md', open: isOpen}}
      isFullScreen
      header={<p className="font-poppins text-xl font-light text-[#333333]">Inventory items selection</p>}
      bodyClass="relative !px-0 h-full"
      body={
        <div
          className={`flex h-full ${isSelectableView ? 'max-h-[400px]' : 'max-h-[500px]'} flex-col items-center overflow-auto pb-20  sm:justify-center`}
        >
          <SetUpInventory
            handleNext={() => {
              onClose();
            }}
            loadingProps={{setIsLoading, isLoading}}
            disabledProps={{setIsDisabled, isDisabled}}
            selectedInventories={selectedInventories}
            handleSave={handleSave}
            isSelectableView={isSelectableView}
            isInSupplierMappingModule
            marketplaceID={marketplaceID}
          />
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            color="primary"
            className="bg-white"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            data-autoid={`btnSave`}
            variant="filled"
            color="primary"
            onClick={() => {
              if (!isSelectableView) {
                setHandleSave(true);
              }
              setIsSelectableView(false);
            }}
            disabled={isSelectableView ? false : isDisabled}
            loading={isLoading}
          >
            {isSelectableView ? 'Next' : 'Save'}
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
