import {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  ResponseType,
  InternalAxiosRequestConfig,
} from 'axios';
import Environment from '../utils/environment';
import qs from 'query-string';
import {Toast} from '../utils/Toast';
import {useErrorContext} from '../exception/ErrorBoundary';

interface CustomRequestConfig extends AxiosRequestConfig {
  hideMessages?: boolean;
  isPublicApi?: boolean;
  getResponseMetaData?: boolean;
}

export const isAxiosError = <ResponseType>(error: unknown): error is AxiosError<ResponseType> => {
  return axios.isAxiosError(error);
};

interface IResponse {
  data?: any;
  status?: number;
  error?: any;
  error_description?: string;
  errors?: any[];
  message?: string;
  Message?: string;
  Errors?: any[];
}

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {setUnAuthorize} = useErrorContext();
  const {getAccessTokenSilently, isAuthenticated, user} = useAuth0();

  const client: AxiosInstance = axios.create({
    baseURL: Environment.API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'API-Environment': Environment.FEAT_NAME,
    },
    timeout: 30000,
  });

  const publicAPIClient: AxiosInstance = axios.create({
    baseURL: Environment.API_URL,
    timeout: 30000,
  });

  const apiUrlPrefix: string = '/api/';
  const claimID: string = 'https://spenda.co/loginSessionID';

  const request = function (options: CustomRequestConfig) {
    const setAuthorizationHeader = async (request: InternalAxiosRequestConfig) => {
      let access_token;
      let claims: any = {};
      if (isAuthenticated) {
        if (user) {
          claims.loginSessionID = user[claimID];
        }

        try {
          access_token = await getAccessTokenSilently(claims);
        } catch (err) {
          console.warn(err);
        }
      }

      if (access_token) {
        request.headers.setAuthorization(`Bearer ${access_token}`);
      } else {
        delete request.headers.Authorization;
      }
      return request;
    };

    const onSuccess = (response: AxiosResponse<any>): Promise<any> => {
      setIsLoading(false);

      if (response && response.status !== 200) {
        let errorMsg = response.statusText;
        return Promise.reject(errorMsg);
      } else if (response && response.status === 200) {
        if (!response.data) {
          return Promise.resolve();
        }

        if (!options.hideMessages && !response.data?.IsSuccess) {
          response.data.Messages && response.data.Messages.forEach((msg: string) => Toast.info(msg));
        }
      }
      if (options.getResponseMetaData) {
        return Promise.resolve(response);
      }
      return Promise.resolve(response.data);
    };

    const onError = (error: AxiosError) => {
      setIsLoading(false);

      if (error.code === 'ECONNABORTED' && error.message.includes('timeout') && error.message.includes('exceeded')) {
        // Toast.info("Timeout exceeded");
        return Promise.reject('Timeout');
      }

      if (error && !error.response && error.message) {
        Toast.error(error.message);
      }

      if (error && error.response && isAxiosError<IResponse>(error)) {
        // Request was made but server responded with something other than 2xx

        switch (error.response.status) {
          case 401: // Unauthorised
            setUnAuthorize();
            break;
          case 404: // Not Found
            // case 408: // Timeout does not reach here as Axios does not return a 408 as part of a timeout response
            Toast.info(error.response?.data?.message ?? 'Resource not found');
            break;
          case 400: // Bad Request.
            const e = error.response.data;

            if (e.error !== 'invalid_grant' && e.error_description) {
              Toast.error(e.error_description);
            }

            /******* Remove the below code when we have all API's error responses fixed from the server *******/
            if (!e.error_description && e.Message) {
              console.error('IMPORTANT! Raise a PBI with Server Devs to fix error case: ', e);
              e.error_description = e.Message;
            }

            if (!e.error_description && !e.Message && e.Errors && e.Errors.length) {
              console.error('IMPORTANT! Raise a PBI with Server Devs to fix error case: ', e);
              e.error_description = e.Errors[0].Message;
              Toast.error(e.error_description ?? 'An error occurred. Please try again.');
            }
            /******* Code to be removed, till here *******/

            if (!e.error_description && !e.Message && e.errors && e.errors.length) {
              e.error_description = e.errors[0].message;
              Toast.error(e.error_description ?? 'An error occurred. Please try again.');
            }

            return Promise.reject(e);
          case 500: // Internal Server Error
            const errors = error?.response?.data?.errors;

            if (options.isPublicApi && errors?.length && errors?.[0]?.message) {
              Toast.error(errors?.[0]?.message);
              return Promise.reject(errors);
            }

            Toast.error('Internal Server Error. Please try again, or contact support');
            break;
          default:
            break;
        }
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error('Error Message:', error.message);
      }

      return Promise.reject(error.response || error.message);
    };

    if (options?.isPublicApi) {
      setIsLoading(true);
      return publicAPIClient(options).then(onSuccess).catch(onError);
    }

    client.interceptors.request.use(setAuthorizationHeader, (error: any) => Promise.reject(error));

    if (options) {
      setIsLoading(true);
    }

    return client(options).then(onSuccess).catch(onError);
  };

  const GET = (
    url: string,
    data?: any,
    hideMessages?: boolean,
    responseType: ResponseType = 'json',
    isPublicApi?: boolean,
  ): Promise<any> => {
    const req: CustomRequestConfig = {
      url: data ? `${apiUrlPrefix}${url}?${qs.stringify(data)}` : `${apiUrlPrefix}${url}`,
      method: 'GET',
      hideMessages,
      responseType,
      isPublicApi,
    };
    return request(req);
  };

  const POST = (
    url: string,
    data: any,
    includeApiInUrl: boolean = true,
    hideMessages: boolean = false,
    headers?: any,
    isPublicApi?: boolean,
    responseType: ResponseType = 'json',
    getResponseMetaData?: boolean,
  ): Promise<any> => {
    if (includeApiInUrl) {
      url = apiUrlPrefix + url;
    }

    const req: CustomRequestConfig = {
      url,
      method: 'post',
      data,
      hideMessages,
      headers,
      isPublicApi,
      responseType,
      getResponseMetaData,
    };

    return request(req);
  };

  const PUT = (url: string, data?: any) => {
    const req: AxiosRequestConfig = {
      url: `${apiUrlPrefix}${url}`,
      method: 'PUT',
      data,
    };

    return request(req);
  };

  const PATCH = (url: string, data?: any) => {
    const req: AxiosRequestConfig = {
      url: `${apiUrlPrefix}${url}`,
      method: 'PATCH',
      data,
    };

    return request(req);
  };

  const DELETE = (url: string, data?: any) => {
    const req: AxiosRequestConfig = {
      url: `${apiUrlPrefix}${url}`,
      method: 'DELETE',
      data,
    };
    return request(req);
  };

  return {GET, POST, PUT, PATCH, DELETE, isLoading};
};

export default useHttp;
