import {AxiosResponse} from 'axios';
import useHttp from '../hooks/useHttp';
import {IBaseResponseV3, IDownloadAllAttachmentsPayload} from '../model/accounts-receivable/AccountsReceivable';
import {IUploadedClaimsRequestAttachment} from '../model/claims/ClaimsAndReturns';
import {AttachmentTypeEnum} from '../model/constants/Constants';

export const useAttachmentsAPI = () => {
  const {GET, POST, DELETE, isLoading} = useHttp();

  const uploadAttachments = (payload: any, headers: any): Promise<any> => {
    return POST(`Spenda/Attachments/File/Upload`, payload, true, false, headers);
  };

  const getUploadedAttachments = (
    attachmentTypeID: AttachmentTypeEnum,
    query: {guids: string},
  ): Promise<IBaseResponseV3<IUploadedClaimsRequestAttachment>> => {
    return GET(`Spenda/Attachments/File/${attachmentTypeID}`, query);
  };

  const getSecureAttachment = async (attachmentTypeID: AttachmentTypeEnum, attachmentGUID: string) => {
    return GET(
      `Spenda/Attachments/File/${attachmentTypeID}/${attachmentGUID}/Content`,
      undefined,
      false,
      'arraybuffer',
    );
  };

  const deleteAttachment = (
    attachmentTypeID: AttachmentTypeEnum,
    attachmentGUID: string,
  ): Promise<IBaseResponseV3<any>> => {
    return DELETE(`Spenda/Attachments/File/${attachmentTypeID}/${attachmentGUID}`);
  };

  const deleteAttachments = (
    attachmentTypeID: AttachmentTypeEnum,
    attachmentGUIDs: string[],
  ): Promise<IBaseResponseV3<any>> => {
    return DELETE(`Spenda/Attachments/File/${attachmentTypeID}`, attachmentGUIDs);
  };

  const downloadAllAttachments = (payload: Partial<IDownloadAllAttachmentsPayload>): Promise<AxiosResponse<any>> => {
    return POST(
      `Spenda/Attachments/File/Content/Zip`,
      payload,
      undefined,
      false,
      undefined,
      false,
      'arraybuffer',
      true,
    );
  };

  return {
    deleteAttachment,
    getUploadedAttachments,
    getSecureAttachment,
    uploadAttachments,
    deleteAttachments,
    downloadAllAttachments,
    isLoading,
  };
};
