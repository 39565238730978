import React, {useCallback, useEffect, useState} from 'react';
import {debounce} from 'lodash';
import {Chip, Option, SearchInput, Select, Typography} from 'spenda-ui-react';

import {AlertDialog, AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {ARTable} from '../AccountsReceivable/ARTable';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {ILinkVariantsRequest, Inventory, VariationOptions} from '../../model/inventory/Inventory';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {useFormikContext} from 'formik';
import {AddInventoryValues} from '../form/InventoryForm';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';
import {InventoryArrow} from '../../assets/svg';

interface IInventoryItemsDialog {
  handleCancel: () => void;
  variationOptions: VariationOptions[];
  inventoryID: string;
}

const columns = (selectedProductID: string) => [
  {
    title: 'SKU',
    key: 'sku',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => <Typography data-autoid={`txtSku-${index}`}>{row.SKU}</Typography>,
  },
  {
    title: 'Code',
    key: 'code',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => (
      <Typography data-autoid={`txtCode-${index}`}>{row.InventoryCode}</Typography>
    ),
  },
  {
    title: 'Product',
    key: 'product',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => (
      <div className="flex items-center justify-between">
        <Typography data-autoid={`txtProduct-${index}`}>{row.Description}</Typography>
        {selectedProductID === row.ID && <GreenCheckIcon className="w-min-[18px] ml-1" width="18px" height={'18px'} />}
      </div>
    ),
  },
];

export const InventoryItemsDialog: React.FC<IInventoryItemsDialog> = ({
  handleCancel,
  variationOptions,
  inventoryID,
}) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Inventory[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<Inventory>({} as Inventory);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const {listInventory, isLoading, linkVariants, getInventoryById} = useInventoryAPI();

  const {setFieldValue, values} = useFormikContext<AddInventoryValues>();

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setQuery(searchValue);
    }, 750),
    [],
  );

  const handleSearchChange = (value: string) => {
    setQuery(value);
    debouncedSearch(value);
  };

  const handleRowClick = (row: Inventory) => {
    setSelectedInventoryItem(row);
  };

  const save = () => {
    const payload: ILinkVariantsRequest = {
      VariationOptions: variationOptions,
      ID: selectedInventoryItem.ID,
      IsVariantPriceOverride: false,
    };
    linkVariants(inventoryID, payload)
      .then(async () => {
        const latestInventory = await getInventoryById(inventoryID);
        if (latestInventory) {
          setFieldValue('ChildVariants', latestInventory.ChildVariants);
        }
      })
      .catch(error => console.error('Error linking variants:', error))
      .finally(() => !isLoading && handleCancel());
  };
  const handleSaveAndClose = () => {
    if (!selectedInventoryItem || !inventoryID) {
      console.error('Product ID is not defined');
      return;
    }
    if (values.InventoryClassID !== selectedInventoryItem.InventoryClassID) {
      setShowAlertDialog(true);
      return;
    }
    save();
  };

  const emptyDataMessage = () => {
    return (
      <Typography variant="small" className="flex text-base font-semibold text-black-800">
        No results found
      </Typography>
    );
  };

  useEffect(() => {
    listInventory({
      ...(query && {SearchString: query}),
      MaxResults: 1000,
      SortField: 'InventoryCode',
      SortAsc: sortOrder === 'asc' ? true : false,
      ExactMatch: false,
      IsApprovedForPOS: true,
      ViewMode: 'NonVariantSales',
    })
      .then(res => {
        setResults(res?.Value || []);
      })
      .catch(error => console.error('Error fetching inventory:', error));
  }, [query, sortOrder]);

  return (
    <AlertDialogSlideV2
      headingClassess="border-b border-[#EAEAEA] flex items-center justify-center"
      dialogActionsAlignment="justify-between"
      dialogBodyClassess="flex flex-col gap-3 h-[70vh]"
      dialogClassess="!min-w-[948px]"
      data-autoid="dlgInventoryItems"
      actions={[
        {
          label: 'Cancel',
          variant: 'outlined',
          action: handleCancel,
        },
        {
          label: 'Save & Close',
          disabled: !selectedInventoryItem || isLoading,
          loading: isLoading,
          action: handleSaveAndClose,
        },
      ]}
      title={'Inventory items'}
    >
      <div className="mx-4 mr-10 flex items-center justify-between">
        <Typography variant="h3" className="text-center text-spenda-primarytext">
          Products ({results.length})
        </Typography>
        <div className="flex items-center gap-2">
          <div className="flex w-[330px] items-center">
            <SearchInput
              expanded
              reverse
              clearIcon
              iconButtonProps={{name: 'Search'}}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search product, code..."
              value={query}
              onChange={e => handleSearchChange(e.target.value)}
              clearIconProps={{
                onClick: () => setQuery(''),
              }}
              className="w-full pr-5 text-black-800 outline-none"
            />
          </div>
          <div className="w-[180px]">
            <Select
              label="Sort by code: A to Z"
              value={sortOrder}
              onChange={value => setSortOrder(value as 'asc' | 'desc')}
            >
              <Option value="asc">Sort by code: A to Z</Option>
              <Option value="desc">Sort by code: Z to A</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="relative mx-4 mt-4 h-full overflow-y-auto">
        <LoadingOverlayV1 isLoading={isLoading}>
          <ARTable
            isLoading={false}
            rows={results}
            columns={columns(selectedInventoryItem.ID!)}
            tableClass="!-mx-2.5 !w-[calc(100%+20px)] text-black"
            isHighlightRowOnHover
            onRowClick={(row: Inventory) => {
              handleRowClick(row);
            }}
            emptyDataMessage={emptyDataMessage}
          />
        </LoadingOverlayV1>
      </div>
      {showAlertDialog && (
        <AlertDialog
          title="Link to master"
          headingTextSize="h2"
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => setShowAlertDialog(false),
            },
            {
              label: 'Link to Master',
              action: () => {
                setShowAlertDialog(false);
                save();
              },
            },
          ]}
        >
          <div className="flex flex-col gap-y-12 px-10 pb-11">
            <section className="rounded-lg bg-error/10 px-12 py-2.5 text-center">
              <Typography className="px-11 text-spenda-primarytext">
                Linking this item will update the following attributes from your variant master...
              </Typography>
            </section>
            <Typography className="px-11 text-center text-spenda-primarytext">
              Are you sure you want to update these properties with the values from your master item?
            </Typography>

            <div className="flex items-center justify-between px-5">
              <div className="flex w-fit flex-col items-center gap-1">
                <Typography variant="xsmall" className="text-[#CCCCCC]">
                  Inventory class
                </Typography>
                <Typography variant="h3" className="text-spenda-primarytext">
                  {selectedInventoryItem?.InventoryClassName || '--'}
                </Typography>
                <Chip value={'Current'} size="sm" color="gray" className="px-3 font-light text-spenda-greytext" />
              </div>
              <InventoryArrow />
              <div className="flex w-fit flex-col items-center gap-1">
                <Typography variant="xsmall" className="text-[#CCCCCC]">
                  Inventory class
                </Typography>
                <Typography variant="h3" className="text-spenda-primarytext">
                  {values?.InventoryClassName || '--'}
                </Typography>
                <Chip value={'New'} size="sm" color="primary" className="px-7" />
              </div>
            </div>
          </div>
        </AlertDialog>
      )}
    </AlertDialogSlideV2>
  );
};
