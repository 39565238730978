import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {IStagingCatalogue, IStagingVariant} from '../../model/inventory/Inventory';
import {UploadType} from '../../model/constants/Constants';

export type IStagingInventory = IStagingCatalogue | IStagingVariant;

export function isStagingCatalogue(objArr: any): objArr is IStagingCatalogue {
  return objArr && objArr.length && objArr[0].type === 'catalogue';
}

interface InventoryContextType {
  uploadTypeId: UploadType;
  setUploadTypeId: Dispatch<SetStateAction<UploadType>>;
  stagingInventory?: IStagingInventory[];
  setStagingInventory: Dispatch<SetStateAction<IStagingInventory[]>>;
  filteredStagingInventory: IStagingInventory[];
  updateFilteredStagingData: (filters: {all: boolean; valid: boolean; errors: boolean; warnings: boolean}) => void;
  totalStagingInventoryCount: number;
  invalidStagingInventoryCount: number;
  validStagingInventoryCount: number;
  warningsStagingInventoryCount: number;
  filterStagingRows: (inventoryCSVIDs: number[]) => void;
}

const InventoryContext = createContext<InventoryContextType | null>(null);

const useInventoryContext = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('useInventoryContext must be used within a InventoryContextProvider');
  }
  return context;
};

export {InventoryContext, useInventoryContext};
