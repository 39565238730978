import React, {FC, useMemo} from 'react';
import {useFormikContext} from 'formik';

import QuoteAttachment from '../../quote-management/QuoteAttachment';
import {IServiceJob, ServiceJobStatus} from '../../../model/service-management/serviceJob';
import {useServiceContext} from '../../../context/serviceManagement/ServiceContext';
import {IQuoteAttachment} from '../../../model/quotes/quotes';

type ServiceAttachmentTabProps = {
  isLoading: boolean;
};

const ServiceAttachmentTab: FC<ServiceAttachmentTabProps> = ({isLoading}) => {
  const {values} = useFormikContext<IServiceJob>();
  const {serviceJob, addedFilesToUpload, setAddedFilesToUpload, setDeleteAttachmentGUIDs, deleteAttachmentGUIDs} =
    useServiceContext();

  const deleteServiceAttachment = async (guid: string) => {
    setDeleteAttachmentGUIDs(prev => [...prev, guid]);
  };

  const deleteFile = (index: number) => {
    let data = [...addedFilesToUpload];
    if (index >= 0) {
      data.splice(index, 1);
      setAddedFilesToUpload(data);
    }
  };

  const uploadedAttachments = useMemo(
    () =>
      values?.attachments?.filter(
        (attachment: IQuoteAttachment) =>
          !deleteAttachmentGUIDs.includes(attachment.attachmentGuid) && !attachment.isCreatedByServiceTechnician,
      ),
    [values?.attachments, deleteAttachmentGUIDs],
  );

  return (
    <QuoteAttachment
      addedFilesToUpload={addedFilesToUpload}
      setAddedFilesToUpload={setAddedFilesToUpload}
      deleteFile={deleteFile}
      uploadedAttachments={uploadedAttachments}
      deleteQuoteAttachment={deleteServiceAttachment}
      disabled={isLoading}
      isEditAble={serviceJob?.status !== ServiceJobStatus.Closed && serviceJob?.status !== ServiceJobStatus.Cancelled}
      isServiceJobView
    />
  );
};

export default ServiceAttachmentTab;
