import React, {PropsWithChildren, useEffect, useState} from 'react';
import {InventoryContext, isStagingCatalogue, IStagingInventory} from './InventoryContext';
import _ from 'lodash';
import {UploadType} from '../../model/constants/Constants';

const InventoryContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [stagingInventory, setStagingInventory] = useState<IStagingInventory[]>([]);
  const [filteredStagingInventory, setFilteredStagingInventory] = useState<IStagingInventory[]>([]);
  const [invalidStagingInventoryCount, setInvalidStagingInventoryCount] = useState<number>();
  const [validStagingInventoryCount, setValidStagingInventoryCount] = useState<number>();
  const [warningsStagingInventoryCount, setWarningsStagingInventoryCount] = useState<number>();
  const [totalStagingInventoryCount, setTotalStagingInventoryCount] = useState<number>();
  const [uploadTypeId, setUploadTypeId] = useState<UploadType>(UploadType.Inventory);

  useEffect(() => {
    if (stagingInventory && stagingInventory.length > 0) {
      setTotalStagingInventoryCount(stagingInventory?.length);
      const totalErrors = _.size(_.flattenDeep(_.map(stagingInventory, 'errors')));
      setInvalidStagingInventoryCount(totalErrors);
      const validCount = _.size(_.filter(stagingInventory, item => _.isEmpty(item.errors)));
      setValidStagingInventoryCount(validCount);
      const totalWarnings = _.size(_.flattenDeep(_.map(stagingInventory, 'warnings')));
      setWarningsStagingInventoryCount(totalWarnings);
      setFilteredStagingInventory(stagingInventory);
    } else {
      setFilteredStagingInventory([]);
    }
  }, [stagingInventory]);

  const updateFilteredStagingData = (filters: {all: boolean; valid: boolean; errors: boolean; warnings: boolean}) => {
    if (stagingInventory && stagingInventory.length > 0) {
      if (filters.all) {
        setFilteredStagingInventory(stagingInventory);
      } else {
        const data = _.cloneDeep(stagingInventory);
        const isCatalogue = isStagingCatalogue(data);
        let filteredData: IStagingInventory[] = [];
        // Apply filters based on selected flags (valid, errors, warnings)
        if (filters.valid) {
          const valid = _.filter(data, item => _.isEmpty(item.errors));
          filteredData = _.uniqBy(
            _.concat(filteredData, valid),
            isCatalogue ? 'inventoryCSVID' : 'inventoryVariantCSVID',
          );
        }
        if (filters.errors) {
          const errors = _.filter(data, item => item.errors && item.errors.length > 0);
          filteredData = _.uniqBy(
            _.concat(filteredData, errors),
            isCatalogue ? 'inventoryCSVID' : 'inventoryVariantCSVID',
          );
        }
        if (filters.warnings) {
          const warnings = _.filter(data, item => item.warnings && item.warnings.length > 0);
          filteredData = _.uniqBy(
            _.concat(filteredData, warnings),
            isCatalogue ? 'inventoryCSVID' : 'inventoryVariantCSVID',
          );
        }
        setFilteredStagingInventory(filteredData || []);
      }
      return;
    }
    setFilteredStagingInventory([]);
  };

  const filterStagingRows = (inventoryCSVIDs: number[]) => {
    const stagingObjects = _.filter(
      stagingInventory,
      obj => !_.includes(inventoryCSVIDs, isStagingCatalogue(obj) ? obj.inventoryCSVID : obj.inventoryVariantCSVID),
    );
    const filteredObjects = _.filter(
      filteredStagingInventory,
      obj => !_.includes(inventoryCSVIDs, isStagingCatalogue(obj) ? obj.inventoryCSVID : obj.inventoryVariantCSVID),
    );
    setStagingInventory(stagingObjects || []);
    setFilteredStagingInventory(filteredObjects || []);
  };

  return (
    <InventoryContext.Provider
      value={{
        uploadTypeId: uploadTypeId,
        setUploadTypeId: setUploadTypeId,
        filteredStagingInventory: filteredStagingInventory,
        updateFilteredStagingData: updateFilteredStagingData,
        stagingInventory: stagingInventory,
        setStagingInventory: setStagingInventory,
        totalStagingInventoryCount: totalStagingInventoryCount || 0,
        invalidStagingInventoryCount: invalidStagingInventoryCount || 0,
        validStagingInventoryCount: validStagingInventoryCount || 0,
        warningsStagingInventoryCount: warningsStagingInventoryCount || 0,
        filterStagingRows: filterStagingRows,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
export {InventoryContextProvider};
