import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';

import {PriceFormat} from '../../utils/formatter';
import {Inventory} from '../../model/inventory/Inventory';
import {ICategory} from '../../model/inventory/Category';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {InventoryToolbar} from '../../components/inventory/InventoryToolbar';
import {UploadProductsDialog} from '../../components/dialog/UploadProductsDialog';
import {useInventoryAPI, useLoadInventory} from '../../services/useInventoryAPI';
import {Button, Typography} from 'spenda-ui-react';

import {
  AUTH_INVENTORY_BRAND_MANAGEMENT,
  AUTH_INVENTORY_CATEGORY_MANAGEMENT,
  AUTH_INVENTORY_PRODUCT_ADD,
  AUTH_INVENTORY_PRODUCT_EDIT,
  AUTH_INVENTORY_PRODUCT_UPLOAD,
} from '../../routes/InventoryRoutes';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import _ from 'lodash';
import {createDownloadLink} from '../../utils/CommonUtils';
import {AlertDialogSlideV2} from '../../components/dialog/AlertDialogSlideV2';

const columnsRender = {
  InventoryCode: {
    disabled: true,
    title: 'Code',
    field: 'InventoryCode',
    render: (rowData: Inventory) => {
      // if (rowData?.variants) {
      //   return (
      //     <div {...css({position: 'relative'})}>
      //       <span>{rowData.InventoryCode}</span>
      //       <span
      //         {...css({
      //           padding: '0 0.25rem',
      //           lineHeight: '2',
      //           backgroundColor: '#C68A1920',
      //           borderRadius: '0.1rem',
      //           color: '#C68A19',
      //           fontWeight: 500,
      //           fontSize: '0.5rem',
      //           position: 'absolute',
      //           top: '-0.75rem',
      //           left: 0,
      //         })}
      //       >{`${rowData?.variants} variants`}</span>
      //     </div>
      //   );
      // }
      return <span className="font-poppins text-sm">{rowData.InventoryCode}</span>;
    },
  },
  ShortDescription: {
    disabled: true,
    title: 'Product Name',
    field: 'ShortDescription',
    className: 'ellipsisCol',
    render: (rowData: Inventory) => (
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start gap-2">
          <span className="font-poppins text-sm">{rowData.ShortDescription}</span>
          {rowData.InventoryClass?.PostingBehaviour === 'Account' && rowData.IsUsedInGenericClass ? (
            <span className="text-spenda-labeltext">Generic</span>
          ) : rowData.IsVariantMaster ? (
            <span className="text-spenda-warning">Master</span>
          ) : null}
        </div>
      </div>
    ),
  },
  PostAs: {
    title: 'Post As',
    field: 'PostAs',
    className: 'ellipsisCol',
    render: (rowData: Inventory) => {
      return (
        <div className="flex flex-col items-start gap-2">
          {rowData.IsVariantMaster ? (
            <span>NA</span>
          ) : rowData.InventoryClass ? (
            rowData.InventoryClass.PostingBehaviour === 'Account' ? (
              <span>Self</span>
            ) : rowData.InventoryClass.PostingBehaviour === 'Generic' ? (
              <span>{rowData.InventoryClass.SalesPostingInventoryCode}</span>
            ) : null
          ) : null}
        </div>
      );
    },
  },
  UoM: {title: 'UoM', field: 'UoM'},
  soh: {title: 'SOH', field: 'soh'},
  category: {
    title: 'Category',
    field: 'category',
    className: 'ellpsisCol',
    render: (rowData: Inventory) =>
      Array.isArray(rowData?.Categories) ? rowData.Categories.map((c: ICategory) => c.Name).join(', ') : '',
  },
  brand: {
    title: 'Brand',
    field: 'brand',
    render: (rowData: Inventory) => rowData?.Brand?.Name || '',
  },
  // warehouse: {
  //   title: 'Warehouse',
  //   field: 'warehouse',
  //   render: (rowData: Inventory) => rowData?.Brand?.Name || '',
  // },
  InventoryClassName: {title: 'Product Class', field: 'InventoryClassName'},
  CostPriceEx: {
    title: 'Cost Price',
    field: 'CostPriceEx',
    render: (rowData: Inventory) => PriceFormat(rowData.CostPriceEx || 0),
  },
  StandardSellPriceEx: {
    title: 'Sell Price(Ex.)',
    field: 'StandardSellPriceEx',
    render: (rowData: Inventory) => PriceFormat(rowData.StandardSellPriceEx || 0),
  },
  StandardSellPriceInc: {
    title: 'Sell Price(Inc.)',
    field: 'StandardSellPriceInc',
    render: (rowData: Inventory) => PriceFormat(rowData.StandardSellPriceInc || 0),
  },
  IsPublished: {
    title: 'Published',
    field: 'IsPublished',
    render: (rowData: Inventory) => {
      switch (rowData.IsPublished) {
        case true:
          return <span className="text-[#3C9F78]">True</span>;
        case false:
          return <span className="text-[#938032]">False</span>;
        default:
          return rowData.IsPublished;
      }
    },
  },
  IsPostingInventoryItem: {
    title: 'Posting Item',
    field: 'IsPostingInventoryItem',
    render: (rowData: Inventory) => {
      switch (rowData.IsPostingInventoryItem) {
        case true:
          return <span className="text-[#3C9F78]">True</span>;
        case false:
          return <span className="text-[#938032]">False</span>;
        default:
          return rowData.IsPostingInventoryItem;
      }
    },
  },
  DefaultSupplierName: {title: 'Supplier', field: 'DefaultSupplierName'},
  IsActive: {
    title: 'Status',
    field: 'IsActive',
    render: (rowData: Inventory) => {
      switch (rowData.IsActive) {
        case true:
          return <span className="text-[#3C9F78]">Active</span>;
        case false:
          return <span className="text-[#934532]">In Active</span>;
        default:
          return rowData.IsActive;
      }
    },
  },
};

export const ProductsList = () => {
  const history = useHistory();

  const [selected, setSelected] = useState<number[]>([]);
  const [isShowUploadProductsDialog, setIsShowUploadProductsDialog] = useState<boolean>(false);
  const [isDownloadingProducts, setIsDownloadingProducts] = useState<boolean>(false);
  const [isShowDownloadCSVConfirmation, setIsShowDownloadCSVConfirmation] = useState<boolean>(false);
  const {downloadProducts} = useInventoryAPI();
  const [selectedColumns, setSelectedColumns] = useState<any>([
    'InventoryCode',
    'ShortDescription',
    'UoM',
    'category',
    'brand',
    'soh',
    'CostPriceEx',
    'StandardSellPriceEx',
    'IsActive',
    'PostAs',
    'InventoryClassName',
  ]);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: inventoryList,
    searchFilter,
    getInventoryList,
    setSearchFilter,
  } = useLoadInventory();

  const handleRowClick = (rowData: Inventory) => {
    history.push(AUTH_INVENTORY_PRODUCT_EDIT.replace(/:productID/g, rowData.ID));
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const handleContinue = () => {
    setIsShowUploadProductsDialog(false);
    history.push(AUTH_INVENTORY_PRODUCT_UPLOAD);
  };

  const handleDownloadProducts = async () => {
    try {
      setIsDownloadingProducts(true);
      const request = _.omit(searchFilter, [
        'MaxResults',
        'SortAsc',
        'LastRowNumber',
        'Search',
        'BrandIDs[]',
        'CategoryIDs[]',
        'Types[]',
      ]);
      const csvData = await downloadProducts({
        ...request,
        SortOrder: 'DESC',
        SearchString: searchFilter.Search,
        BrandIDs: searchFilter['BrandIDs[]'],
        CategoryIDs: searchFilter['CategoryIDs[]'],
        Types: searchFilter['Types[]'],
      });
      const blob = new Blob([csvData], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);
      createDownloadLink(url, 'products.csv');
    } finally {
      setIsDownloadingProducts(false);
      setIsShowDownloadCSVConfirmation(false);
    }
  };

  const productsList = (
    <div
      className={` flex max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white px-5 pt-4`}
      id="products-list"
    >
      <div className={'flex w-full justify-between px-1 py-4'}>
        <Typography className="text-xl font-medium text-[#4D4D4D]">Products</Typography>
        <div className="flex">
          {QuoteV289367 && (
            <Button
              variant="outlined"
              size="sm"
              className="mr-2"
              loading={isDownloadingProducts}
              disabled={isDownloadingProducts}
              onClick={() => setIsShowDownloadCSVConfirmation(true)}
            >
              Download Products
            </Button>
          )}
          <RouterLink to={AUTH_INVENTORY_BRAND_MANAGEMENT}>
            <Button variant="outlined" size="sm" className="mr-2">
              Edit Brands
            </Button>
          </RouterLink>
          <RouterLink to={AUTH_INVENTORY_CATEGORY_MANAGEMENT}>
            <Button variant="outlined" size="sm" className="mr-2">
              Edit Categories
            </Button>
          </RouterLink>
          {QuoteV289367 && (
            <Button variant="outlined" size="sm" className="mr-2" onClick={() => setIsShowUploadProductsDialog(true)}>
              Upload Products
            </Button>
          )}
          <RouterLink to={AUTH_INVENTORY_PRODUCT_ADD}>
            <Button size="sm">Create Product</Button>
          </RouterLink>
        </div>
      </div>
      <InventoryToolbar
        loading={loading}
        title={`Product Listing (${totalCount})`}
        searchFilter={searchFilter}
        columns={columnsRender}
        selectedColumns={selectedColumns}
        data={inventoryList}
        selected={selected}
        refetchList={getInventoryList}
        setSelected={setSelected}
        setSelectedColumns={setSelectedColumns}
        setSearchFilter={setSearchFilter}
      />
      <STable
        columns={Object.entries(columnsRender)
          .map(([key, value]: [string, any]) => {
            if (selectedColumns.includes(key)) {
              return value;
            }
          })
          .filter(value => value)}
        data={inventoryList}
        emptyDataMessage={'No Products'}
        stickyHeader={true}
        selection={true}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        onRowClick={handleRowClick}
        newUI
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 15, 25]}
        onRowsPerPageChange={handleRowsChange}
      />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={productsList} splitWidthType={4} />
      {isShowUploadProductsDialog && (
        <UploadProductsDialog
          handleCancel={() => setIsShowUploadProductsDialog(false)}
          handleContinue={handleContinue}
        />
      )}
      {isShowDownloadCSVConfirmation && (
        <AlertDialogSlideV2
          title={'Download Products'}
          headingTextSize="h2"
          headingClassess="justify-center w-full"
          footerClass="justify-between sm:w-auto w-full"
          dialogClassess="!min-w-[20%] !max-w-[482px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
          dialogBodyClassess="flex justify-center"
          data-autoid={'dlgProducts'}
          actions={[
            {
              label: 'Cancel',
              action: () => setIsShowDownloadCSVConfirmation(false),
              color: 'primary',
              variant: 'outlined',
              disabled: isDownloadingProducts,
            },
            {
              label: 'Download',
              action: handleDownloadProducts,
              disabled: isDownloadingProducts,
              loading: isDownloadingProducts,
              color: 'primary',
            },
          ]}
        >
          <div className="mx-auto w-full py-8 sm:max-w-[74%]">
            <Typography className="text-black-800">Are you sure you want to download products ?</Typography>
          </div>
        </AlertDialogSlideV2>
      )}
    </div>
  );
};
