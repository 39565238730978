import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Typography} from 'spenda-ui-react';

import {DatTypes} from '../../model/constants/Constants';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import {CreateServiceJobModal} from '../dialog/CreateServiceJobModal';
import {QuotesTransactionType, ServiceJobScope} from '../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../context/serviceManagement/ServiceContextProvider';
import {APInvoiceDetailView} from '../../screens/purchasing/APInvoiceDetailView';
import PaymentSummary from '../dialog/PaymentSummary';
import {IQuotesPaymentSummary} from '../../model/quotes/quotes';
import _ from 'lodash';
interface Event {
  eventType: string;
  eventDate: string;
  createdDateTime_utc?: string;
  paymentDateTime_utc?: string;
  [key: string]: any; // to allow dynamic properties
}

export const QuoteLinkedTransaction = () => {
  const {quotePackage, refreshQuotePackage, approvedQuote} = useQuoteContext();

  const [openServiceDialog, setOpenServiceDialog] = useState<boolean>(false);
  const [selectedPaymentSummary, setSelectedPaymentSummary] = useState<Event>();
  const [invoiceId, setInvoiceId] = useState<number | undefined>();
  const [serviceJobId, setServiceJobId] = useState<number>();
  const [eventData, setEventData] = useState<Event[]>([]);

  const handleOpenServiceDialog = (id: number) => {
    setServiceJobId(id);
    setOpenServiceDialog(true);
  };

  const handleCloseServiceDialog = () => {
    refreshQuotePackage();
    setOpenServiceDialog(false);
    setServiceJobId(undefined);
  };

  const handleClosePaymentSummary = () => {
    setSelectedPaymentSummary(undefined);
  };

  useEffect(() => {
    const linkedTransactions = _.flatMap(quotePackage?.quotes, quote => quote.linkedTransactions || []);
    const paymentSummary = approvedQuote?.paymentSummary || [];
    const mergedData = [
      ...linkedTransactions?.map(item => ({
        ...item,
        eventType: 'Linked Transaction',
        eventDate: item.createdDateTime_utc,
      })),
      ...paymentSummary?.map(item => ({
        ...item,
        eventType: 'Payment Summary',
        eventDate: item.paymentDateTime_utc,
      })),
    ];
    setEventData(mergedData);
  }, [quotePackage, approvedQuote]);

  return (
    <>
      {_.sortBy(eventData, ['eventDate']).map((event, index) => {
        return event.eventType === 'Payment Summary' ? (
          <div
            key={event.refNumber}
            className="mb-2.5 flex w-full cursor-pointer flex-col items-start rounded-[10px] bg-primary/10 p-3.5"
            onClick={() => setSelectedPaymentSummary(event)}
          >
            <Typography className="text-base font-medium text-primary">{`${event.isDepositPayment ? 'Deposit' : 'Invoice'} Payment - [${event?.refNumber}]`}</Typography>
            <Typography variant="xsmall" className="font-normal text-black-800">
              Generated on: {moment.utc(event?.paymentDateTime_utc).local().format('DD/MM HH:mm')}
            </Typography>
          </div>
        ) : (
          <>
            <div
              className="mb-2.5 flex w-full cursor-pointer flex-col items-start rounded-[10px] bg-primary/10 p-3.5"
              key={index}
              data-autoid={`lnk-${event.refNumber}`}
              onClick={() =>
                event.datTypeID === DatTypes.ServiceJob ? handleOpenServiceDialog(event.id) : setInvoiceId(event.id)
              }
            >
              <Typography className="text-base font-medium text-primary" data-autoid={`txt${event.refNumber}`}>
                {event.datTypeID === DatTypes.ServiceJob
                  ? 'Service job'
                  : `${event.transactionSubType === QuotesTransactionType.Deposit ? event.transactionSubType : ''} Invoice`}{' '}
                - [{event.refNumber}]
              </Typography>
              <Typography
                variant="xsmall"
                className="font-normal text-black-800"
                data-autoid={`txtGeneratedDate${event.refNumber}`}
              >
                Generated on: {moment.utc(event.createdDateTime_utc).local().format('DD/MM HH:mm')}
              </Typography>
            </div>
            {openServiceDialog && serviceJobId === event.id && (
              <ServiceContextProvider>
                <CreateServiceJobModal
                  serviceJobId={event.id}
                  open={serviceJobId === event.id}
                  handleClose={handleCloseServiceDialog}
                  scope={ServiceJobScope.QuoteDetails}
                />
              </ServiceContextProvider>
            )}
            {invoiceId === event.id && (
              <APInvoiceDetailView
                open={Boolean(invoiceId)}
                onClose={() => {
                  setInvoiceId(undefined);
                }}
                isServiceJobInvoice
                apInvoiceId={{transId: invoiceId!}}
              />
            )}
          </>
        );
      })}
      {selectedPaymentSummary && (
        <PaymentSummary
          handleClose={handleClosePaymentSummary}
          selectedPaymentSummary={selectedPaymentSummary as unknown as IQuotesPaymentSummary}
          depositPaymentSummary={approvedQuote?.paymentSummary?.find(summ => summ.isDepositPayment)}
          invoiceLinkedTrans={approvedQuote?.linkedTransactions?.find(
            tran => tran.transactionSubType === QuotesTransactionType.Invoice,
          )}
        />
      )}
    </>
  );
};
