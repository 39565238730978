import React, {useContext, useEffect, useState} from 'react';
import {ARDialogTemplate} from './ARDialogTemplate';
import {MenuItem} from '@material-ui/core';
import {ARExpandedSearchBar} from './ARExpandedSearchBar';
import {useCustomerStatementsAPI} from '../../services/useCustomerStatementsAPI';
import {ICustomerStatementsResponse, IGetAllCustomersResponse} from '../../model/customer/CustomerStatements';
import IconLink from '../../assets/svg/IconLink';
import IconNotLinked from '../../assets/svg/IconNotLinked';
import {useHistory} from 'react-router-dom';
import {AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE} from '../../routes/AuthenticatedRoutes';
import {ClaimActions} from '../../model/constants/Constants';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {useClaimsAndReturnInputHook} from '../../hooks/useARHook';
import AppContext from '../../context/app/appContext';
import useClaimsAndReturnsAPI from '../../services/useClaimsAndReturnsAPI';
import {IVendorListResponse} from '../../model/claims/ClaimsAndReturns';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import SDatePicker from '../pickers/DatePicker';
import moment from 'moment';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Button, Input, Typography} from 'spenda-ui-react';
import {UnableToCreateClaimRequestDialog} from './modals/UnableToCreateClaimRequestDialog';
import {ClaimsAndReturnActionReasonDropdown} from '../../screens/AccountsReceivable/claims-and-returns/ClaimsAndReturnInputs';

export interface ICreateClaimModalFormValues {
  vendorId?: number;
  accountCustomerId?: number;
  txGuid?: string;
  vendorRefNumber?: string;
  disableCreateClaimBtn?: boolean;
  vendorName: string;
  custOrInvSearch: string;
  invoiceDate: string;
  invoiceRefNumber: string;
  salesOrderNo: string;
  defaultReason: string;
  defaultAction: string;
  comment: string;
  searchCategory?: string;
}

const formInitialValues: ICreateClaimModalFormValues = {
  vendorName: '',
  custOrInvSearch: '',
  invoiceDate: '',
  invoiceRefNumber: '',
  salesOrderNo: '',
  defaultReason: '',
  comment: '',
  defaultAction: ClaimActions.Credit,
  searchCategory: 'Transactions',
  vendorId: undefined,
  accountCustomerId: undefined,
  txGuid: undefined,
  vendorRefNumber: undefined,
  disableCreateClaimBtn: undefined,
};

export interface ICreateClaimRequestDialogProps {
  showModal: boolean;
  supplierId?: number;
  onConfirm: () => void;
  handleClose: () => void;
}

export const CreateClaimRequestDialog = (props: ICreateClaimRequestDialogProps) => {
  //Props
  const {showModal, supplierId, handleClose} = props;

  // Hooks
  const history = useHistory();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {reasonDropdownOptions, claimDefaultReason, isClaimReasonDropdowLoading} = useClaimsAndReturnInputHook({
    linkedSupplierID: supplierId,
  });

  // APIs
  const {getAllCustomers, isLoading} = useCustomerStatementsAPI();
  const {getBuyerAllStatements, isLoading: isInvoiceSearchLoadingBuyer} = useSupplierTransactionsAPI();
  const {getCustomerAllTransactions, isLoading: isInvoiceSearchLoadingSupp} = useCustomerStatementsAPI();
  const {getVendorList, isLoading: isVendorLoading} = useClaimsAndReturnsAPI();

  // Context
  const {tenant} = useContext(AppContext);

  // States
  const [customerSearchResults, setCustomerSearchResults] = useState<IGetAllCustomersResponse>();
  const [invoiceSearchResults, setInvoiceSearchResults] = useState<ICustomerStatementsResponse>();
  const [vendorListOptions, setVendorListOptions] = useState<IVendorListResponse[]>();

  const [formValues, setFormValues] = useState<ICreateClaimModalFormValues>(formInitialValues);
  const [businessName, setBusinessName] = useState<string | undefined>('');
  const [isShowUnableToCreateClaimDialog, setIsShowUnableToCreateClaimDialog] = useState(false);

  useEffect(() => {
    setFormValues(oldVal => ({
      ...oldVal,
      defaultReason: claimDefaultReason || oldVal?.defaultReason,
    }));
    isInBuyerContext && setBusinessName(tenant?.Name);
  }, [tenant, claimDefaultReason, isInBuyerContext]);

  const fetchCustomers = async (searchStr: string) => {
    try {
      if (searchStr?.length <= 2) return;

      const vl = await getAllCustomers({searchString: searchStr});
      setCustomerSearchResults(vl);
    } catch {}
  };

  const fetchInvoiceSearchResults = async (searchStr: string, accountCustomerId?: number) => {
    const query = {
      searchField: 'RefNumber',
      searchString: searchStr,
      showInvoicesOnly: true,
    };

    if (searchStr?.length < 2) return;

    if (isInBuyerContext) {
      const ct = await getBuyerAllStatements(Number(supplierId), query);
      setInvoiceSearchResults(ct);
    } else if (isInSupplierContext) {
      const ct = await getCustomerAllTransactions({...query, accountCustomerID: accountCustomerId});
      setInvoiceSearchResults(ct);
    }
  };

  const fetchVendorList = async (searchStr: string) => {
    try {
      const vl = await getVendorList({linkedSupplierID: supplierId, searchString: searchStr});
      setVendorListOptions(vl);
    } catch {}
  };

  const handleCreateClaim = async (values: ICreateClaimModalFormValues) => {
    const {accountCustomerId, txGuid} = values;
    const search = txGuid ? `txGuid=${txGuid}` : '';

    if (isInBuyerContext) {
      history.push({pathname: `/supplier/${supplierId}/claim-request/create`, search, state: values});
    } else if (isInSupplierContext && accountCustomerId) {
      const pathName = AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE?.replace(
        ':accountCustomerId',
        String(accountCustomerId),
      );
      history.push({pathname: pathName, search, state: values});
    }
  };

  const validationSchema = Yup.object({
    invoiceRefNumber: Yup.string().required('Invoice number is required.'),
    invoiceDate: Yup.string().nullable().required('Invoice date is required.'),
    custOrInvSearch: Yup.string()
      .test('accountCustomerId-check-when-supplier', 'Please select customer', (v, c) => {
        return isInSupplierContext && c?.parent?.searchCategory === 'CustomerName'
          ? !!(v && c?.parent?.accountCustomerId)
          : true;
      })
      .test('Invoice-check-when-buyer', 'Please select invoice', (v, c) => {
        return c?.parent?.searchCategory === 'Transactions' && isInSupplierContext
          ? !!(v && c?.parent?.accountCustomerId)
          : true;
      }),
    defaultAction: Yup.string().required('Default Action is required.'),
    defaultReason: Yup.string().required('Default Reason is required.'),
    comment: Yup.string().max(250, 'Max 250 char allowed'),
  });

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={handleCreateClaim}
      enableReinitialize
    >
      {({values, errors, touched, handleChange, setValues, handleBlur, handleSubmit, setFieldValue}) => (
        <Form className="h-0 w-0">
          <ARDialogTemplate
            dialogProps={{size: 'md', open: showModal, className: '!w-[600px] !min-w-[600px]'}}
            isFullScreen
            header={<p className="font-poppins text-xl font-light text-[#333333]">Create Claim</p>}
            body={
              isClaimReasonDropdowLoading ? (
                <div className="mb-12 flex h-[360px] w-full !min-w-[560px] flex-col items-start">
                  <LoadingIndicator size="md" isLoading={isClaimReasonDropdowLoading} />
                </div>
              ) : (
                <div className="mb-12 flex w-full !min-w-[560px] flex-col items-start">
                  <div className="flex !w-full flex-col items-end">
                    <ARExpandedSearchBar
                      handleSearch={() => {
                        setInvoiceSearchResults(undefined);
                        setCustomerSearchResults(undefined);
                        if (values?.searchCategory === 'CustomerName') {
                          fetchCustomers(values?.custOrInvSearch);
                        } else {
                          fetchInvoiceSearchResults(values?.custOrInvSearch, values?.accountCustomerId);
                        }
                      }}
                      isShowOptions={
                        !!customerSearchResults?.outStandingStatementSummaryList?.length ||
                        (!!invoiceSearchResults?.customerOutstandingStatements?.length &&
                          values?.searchCategory === 'Transactions')
                      }
                      id="custOrInvSearch"
                      type="text"
                      label={values?.searchCategory === 'CustomerName' ? 'Search Customer' : 'Search Invoice'}
                      name="custOrInvSearch"
                      helperText={errors?.custOrInvSearch && touched?.custOrInvSearch ? errors?.custOrInvSearch : ''}
                      error={Boolean(errors?.custOrInvSearch && touched?.custOrInvSearch)}
                      value={values?.custOrInvSearch}
                      selectedSearchCategory={values?.searchCategory}
                      onCategoryChange={searchCat => {
                        setInvoiceSearchResults(undefined);
                        setCustomerSearchResults(undefined);
                        setFieldValue('searchCategory', searchCat);
                      }}
                      searchCategoriesOptions={
                        isInSupplierContext
                          ? [
                              {label: 'Invoice', value: 'Transactions'},
                              {label: 'Customer name', value: 'CustomerName'},
                            ]
                          : [{label: 'Invoice', value: 'Transactions'}]
                      }
                      onChange={e => {
                        setValues(prev => ({
                          ...formInitialValues,
                          defaultAction: prev.defaultAction,
                          defaultReason: prev.defaultReason,
                          comment: prev.comment,
                          searchCategory: prev.searchCategory,
                          custOrInvSearch: e?.target?.value,
                        }));
                        isInSupplierContext && setBusinessName('');
                      }}
                      isSearching={
                        isLoading ||
                        (isInvoiceSearchLoadingSupp && values?.searchCategory === 'Transactions') ||
                        isInvoiceSearchLoadingBuyer
                      }
                      renderMenuItems={
                        <>
                          {values?.searchCategory === 'CustomerName'
                            ? customerSearchResults?.outStandingStatementSummaryList?.map(c => (
                                <MenuItem
                                  key={c?.accountCustomerID}
                                  onClick={() => {
                                    setFieldValue('custOrInvSearch', c?.accountCustomerName);
                                    setFieldValue('accountCustomerId', c?.accountCustomerID);
                                  }}
                                >
                                  <div className="flex w-full flex-row items-center justify-between">
                                    <p data-autoid={`ddlItem-${c?.accountCustomerName}`}>{c?.accountCustomerName}</p>
                                    <div className="ml-5 flex flex-row items-center justify-end text-[#BBBBBB]">
                                      <p className="mr-3">{c?.emailAddress}</p>
                                      {c?.isLinked ? <IconLink /> : <IconNotLinked />}
                                    </div>
                                  </div>
                                </MenuItem>
                              ))
                            : invoiceSearchResults?.customerOutstandingStatements?.map(i => (
                                <MenuItem
                                  key={i?.transID}
                                  onClick={() => {
                                    setValues(prev => ({
                                      ...prev,
                                      accountCustomerId: i?.accountCustomerID,
                                      custOrInvSearch: isInBuyerContext ? i?.refNumber : i?.accountCustomerName,
                                      invoiceDate: i?.transDate || '',
                                      salesOrderNo: i?.salesOrderRefNumber || '',
                                      invoiceRefNumber: i?.refNumber,
                                      txGuid: i?.transGUID,
                                      vendorId: i?.vendorID,
                                      vendorName: i?.vendorName ?? '',
                                      vendorRefNumber: i?.vendorRefNumber ?? '',
                                      disableCreateClaimBtn: i?.isEarlyClaimShortPayment,
                                    }));
                                    setBusinessName(i?.accountCustomerName);
                                  }}
                                >
                                  <div className="flex w-full flex-row items-center justify-between">
                                    <p data-autoid={`ddlItem-${i?.accountCustomerName}-${i?.refNumber}`}>
                                      {i?.accountCustomerName} - {i?.refNumber}
                                    </p>
                                  </div>
                                </MenuItem>
                              ))}
                        </>
                      }
                    />
                  </div>
                  <div className="mb-7 mt-10 flex w-full flex-row items-center justify-between">
                    <Typography className="font-medium text-black-800" variant="paragraph">
                      Details
                    </Typography>
                    {businessName && (
                      <Typography className="font-medium text-black-800" variant="paragraph">
                        Business name: <Typography className="inline font-normal">{businessName}</Typography>
                      </Typography>
                    )}
                  </div>
                  <div className="flex w-full flex-col gap-y-[17px]">
                    <div className="flex w-full flex-row items-center justify-between gap-x-[40px]">
                      <div className="flex w-full flex-col items-center">
                        <ARExpandedSearchBar
                          key={'vendorName'}
                          isShowOptions={!!vendorListOptions?.length}
                          isSearching={isVendorLoading}
                          value={values?.vendorName}
                          onChange={e => {
                            setFieldValue('vendorId', undefined);
                            handleChange(e);
                          }}
                          id="vendorName"
                          type="text"
                          label="Search Vendor"
                          name="vendorName"
                          onBlur={handleBlur}
                          helperText={errors?.vendorName && touched?.vendorName ? errors?.vendorName : ''}
                          error={Boolean(errors?.vendorName && touched?.vendorName)}
                          handleSearch={() => {
                            setVendorListOptions(undefined);
                            fetchVendorList(values?.vendorName);
                          }}
                          renderMenuItems={
                            <>
                              {vendorListOptions?.map(v => (
                                <MenuItem
                                  key={v?.vendorID}
                                  onClick={() => {
                                    setFieldValue('vendorName', v?.name);
                                    setFieldValue('vendorId', v?.vendorID);
                                  }}
                                >
                                  {v?.name}
                                </MenuItem>
                              ))}
                            </>
                          }
                        />
                      </div>
                      {values?.searchCategory === 'CustomerName' && isInSupplierContext ? (
                        <ARExpandedSearchBar
                          handleSearch={() => {
                            setInvoiceSearchResults(undefined);
                            fetchInvoiceSearchResults(values?.invoiceRefNumber, values?.accountCustomerId);
                          }}
                          isShowOptions={!!invoiceSearchResults?.customerOutstandingStatements}
                          id="invoiceRefNumber"
                          type="text"
                          label="Search Invoice Number"
                          name="invoiceRefNumber"
                          onBlur={handleBlur}
                          helperText={
                            errors?.invoiceRefNumber && touched?.invoiceRefNumber ? errors?.invoiceRefNumber : ''
                          }
                          error={Boolean(errors?.invoiceRefNumber && touched?.invoiceRefNumber)}
                          value={values?.invoiceRefNumber}
                          isSearching={isInvoiceSearchLoadingSupp}
                          onChange={e => {
                            setValues(prev => ({
                              ...prev,
                              txGuid: undefined,
                              vendorRefNumber: undefined,
                              disableCreateClaimBtn: false,
                              invoiceRefNumber: e?.target?.value,
                            }));
                          }}
                          renderMenuItems={
                            <>
                              {invoiceSearchResults?.customerOutstandingStatements?.map(i => (
                                <MenuItem
                                  key={i?.transGUID}
                                  onClick={() => {
                                    setValues(prev => ({
                                      ...prev,
                                      invoiceDate: i?.transDate || '',
                                      salesOrderNo: i?.salesOrderRefNumber || '',
                                      invoiceRefNumber: i?.refNumber,
                                      txGuid: i?.transGUID,
                                      vendorId: i?.vendorID,
                                      vendorName: i?.vendorName ?? '',
                                      vendorRefNumber: i?.vendorRefNumber ?? '',
                                      accountCustomerId: i?.accountCustomerID,
                                      custOrInvSearch: i?.accountCustomerName,
                                      disableCreateClaimBtn: i?.isEarlyClaimShortPayment,
                                    }));
                                    setBusinessName(i?.accountCustomerName);
                                  }}
                                >
                                  {i?.accountCustomerName} - {i?.refNumber}
                                </MenuItem>
                              ))}
                            </>
                          }
                        />
                      ) : (
                        <div className="w-full font-poppins">
                          <Input
                            id="invoiceRefNumber"
                            type="text"
                            label="Invoice no."
                            name="invoiceRefNumber"
                            value={values?.invoiceRefNumber || ''}
                            onChange={e => {
                              setValues(prev => ({
                                ...prev,
                                txGuid: undefined,
                                vendorRefNumber: undefined,
                                disableCreateClaimBtn: false,
                                invoiceRefNumber: e?.target?.value,
                              }));
                            }}
                            onBlur={handleBlur}
                            className="w-full"
                            helperText={
                              errors?.invoiceRefNumber && touched?.invoiceRefNumber ? errors?.invoiceRefNumber : ''
                            }
                            error={Boolean(errors?.invoiceRefNumber && touched?.invoiceRefNumber)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex w-full flex-row items-center justify-between gap-x-[40px]">
                      <div className="w-full font-poppins">
                        <Input
                          id="salesOrderNo"
                          name="salesOrderNo"
                          type="text"
                          label="Sales order no."
                          value={values?.salesOrderNo || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="w-full"
                        />
                      </div>
                      <SDatePicker
                        v2
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        autoOk
                        fullWidth
                        variant="inline"
                        id="invoiceDate"
                        label="Invoice date"
                        name="invoiceDate"
                        value={values?.invoiceDate || null}
                        onChange={date => {
                          setFieldValue('invoiceDate', moment(date).toISOString(), true);
                        }}
                        onBlur={handleBlur}
                        className="w-full"
                        invalidDateMessage="Please enter correct date"
                        helperText={errors?.invoiceDate && touched?.invoiceDate ? errors?.invoiceDate : undefined}
                        error={errors?.invoiceDate && touched?.invoiceDate ? true : undefined}
                        inputProps={{
                          'data-autoid': 'dtpCreateClaimRequestDate',
                        }}
                        KeyboardButtonProps={
                          {
                            'aria-label': 'change date',
                            'data-autoid': 'dtpCreateClaimRequestDateIcon',
                          } as any
                        }
                        PopoverProps={{className: '!z-[9999]'}}
                      />
                    </div>
                    <div className="flex w-full flex-row items-center justify-between gap-x-[40px]">
                      <div className="w-full">
                        <ClaimsAndReturnActionReasonDropdown
                          dataAutoId={`ddlSelectReason`}
                          className="!h-[45px] !min-w-[170px]"
                          name={'defaultReason'}
                          value={values?.defaultReason}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          reasonDropdownOptions={reasonDropdownOptions}
                          showSubReason={false}
                          claimAndReturnsV272602={true}
                          label="Reason"
                          type="reason"
                        />
                      </div>
                      <div className="w-full">
                        <ClaimsAndReturnActionReasonDropdown
                          dataAutoId={`ddlSelectAction`}
                          className="!h-[45px] !min-w-[170px]"
                          name={'defaultAction'}
                          value={values?.defaultAction}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Action"
                          type="action"
                        />
                      </div>
                    </div>
                    <div className="relative h-10 w-full min-w-[220px] font-poppins">
                      <Input
                        id="Comment"
                        type="string"
                        label="Comment"
                        name={'comment'}
                        value={values?.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={150}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            footer={
              <div className="flex h-[60px] !w-full flex-row items-center justify-between">
                <Button
                  data-autoid="btnCancel"
                  variant="outlined"
                  color="primary"
                  className="bg-white"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>

                <Button
                  data-autoid="btnCreateClaim"
                  variant="filled"
                  color="primary"
                  onClick={() =>
                    values?.disableCreateClaimBtn ? setIsShowUnableToCreateClaimDialog(true) : handleSubmit()
                  }
                >
                  Create Claim
                </Button>
              </div>
            }
            handleClose={() => handleClose()}
          />
          {isShowUnableToCreateClaimDialog && (
            <UnableToCreateClaimRequestDialog
              isOpen={isShowUnableToCreateClaimDialog}
              onClose={() => setIsShowUnableToCreateClaimDialog(false)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
